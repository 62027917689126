import React, { useContext, useEffect } from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  UserContext,
  ModalContext,
  FormContext,
  AppDataContext,
} from "../../contexts/Contexts";
import PaymentSelector from "../../components/PaymentSelector";
import { formatCurrency, getOS } from "../../helpers/utils";
import ResponseModal from "../../components/ResponseModal";
import ConfirmationModal from "../../components/ConfirmationModal";

const DataPurchase = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { formData, formDispatch } = useContext(FormContext);
  const { user } = useContext(UserContext);
  const { appData } = useContext(AppDataContext);
  document.title = "Purchase Electric-" + appData.business.name;

  const formOnChange = (e) => {
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  const calculateATM = (e) => {
    formDispatch({
      type: "SET_FORM_DATA",
      data: {
        name: "calcAmount",
        value: e.target.value * 1 + (e.target.value * 1.5) / 100,
      },
    });
  };

  !formData.image && props.history.push("/electricPricing");

  useEffect(() => {
    if (!formData.ref) {
      formDispatch({
        type: "SET_FORM_DATA",
        data: { name: "ref", value: Math.random().toString(36).slice(2) },
      });
    }
  }, []);

  const verifyReceiver = (e) => {
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Verifying Meter Number...", isLoading: true },
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + user.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("meter_number", String(formData.meter_number));
    urlencoded.append("plan_id", String(formData.electricPlan.id));
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      //redirect: "follow",
    };
    fetch(localStorage.getItem("apiURL") + "verify_meter", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          formDispatch({
            type: "SET_FORM_DATA",
            data: { name: "receiverName", value: data.data.Customer_Name },
          });
          formDispatch({
            type: "SET_FORM_DATA",
            data: { name: "receiverAddress", value: data.data.Address },
          });
          formDispatch({
            type: "SET_ERROR",
            data: "",
          });
        } else {
          formDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  const onValidSubmit = (e) => {
    formDispatch({
      type: "SET_FORM_DATA",
      data: {
        name: "confirmationModal",
        value: {
          isOpen: true,
          type: "Electric Bill Payment",
          description: formData.electricPlan.name,
          receiver: formData.meter_number,
          amount: formData.amount
            ? formData.atmPayment
              ? formData.calcAmount
              : formData.amount -
                (formData.amount * formData.electricPlan.commission) / 100
            : 0,
          image: formData.image,
        },
      },
    });
  };

  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: "confirmationModal", value: { isOpen: false, text: "" } },
    });
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Processing your transaction...", isLoading: true },
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    user.data && myHeaders.append("Authorization", "Bearer " + user.token);

    var urlencoded = new URLSearchParams();
    user.data &&
      !formData.atmPayment &&
      urlencoded.append("password", String(formData.password));
    !user.data &&
      formData.atmPayment &&
      urlencoded.append("email", String(formData.email));
    urlencoded.append("plan_id", String(formData.electricPlan.id));
    urlencoded.append("meter_number", String(formData.meter_number));
    urlencoded.append("amount", String(formData.amount));
    urlencoded.append(
      "phone_number",
      String(
        formData.phone_number ? formData.phone_number : user.data.phone_number
      )
    );
    urlencoded.append("payment_method", formData.atmPayment ? "ATM" : "WALLET");

    urlencoded.append("source", getOS());
    urlencoded.append("ref", formData.ref);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      //redirect: "follow",
    };
    fetch(localStorage.getItem("apiURL") + "electric_purchase", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        console.log(response);
        const data = JSON.parse(response);
        if (data.status === "success" && formData.atmPayment) {
          window.location = data.data.payment_url;
          return;
        }
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          if (formData.atmPayment) window.location = data.data.payment_url;
          else
            formDispatch({
              type: "SET_FORM_DATA",
              data: {
                name: "responseModal",
                value: { isOpen: true, text: data.message },
              },
            });
        } else if (
          data.message === "Token Expired" ||
          data.message === "User Not Found"
        ) {
          props.history.push("/signout");
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
          formDispatch({
            type: "SET_FORM_DATA",
            data: { name: "ref", value: Math.random().toString(36).slice(2) },
          });
        } else {
          formDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
          formDispatch({
            type: "SET_FORM_DATA",
            data: { name: "ref", value: Math.random().toString(36).slice(2) },
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  return (
    <React.Fragment>
      <ResponseModal isOpen={true} shouldGoHome={true} />
      <ConfirmationModal isOpen={false} onConfirmationClicked={handleSubmit} />

      <br />

      <Row>
        <Col xl="10" ml="10">
          <Card className="mini-stat bg-white">
            <CardBody>
              <div className="mb-2 text-center">
                <img src={formData.image} alt="logo" height="36"></img>
              </div>
              <hr style={{ borderColor: appData.business.primary_color }} />
              <p
                className="text-uppercase text-left mt-2  "
                style={{ color: appData.business.primary_color }}
              >
                Electric Meter Type:{" "}
                {formData.electricPlan && formData.electricPlan.name}
              </p>

              {formData.electricPlan && formData.electricPlan.message && (
                <Card className="mini-stat shadow text-dark border border-danger mt-0 mb-2">
                  <p className="mt-2 mb-2 pl-2">
                    <i className="mdi mdi-alert-circle-outline  text-danger mdi "></i>{" "}
                    {formData.electricPlan.message}
                  </p>
                </Card>
              )}

              {formData.error && <Alert color="danger">{formData.error}</Alert>}

              <AvForm
                className="form-horizontal "
                onValidSubmit={onValidSubmit}
                autocomplete="off"
                autoComplete="off"
              >
                {/* <AvForm className="form-horizontal " autoComplete="off" autoComplete="off" onValidSubmit={handleSubmit}> */}

                <Card className="mini-stat shadow text-dark ">
                  <CardBody>
                    <h6 className="text-uppercase text-dark mt-2 small">
                      ENTER METER NUMBER{" "}
                    </h6>
                    <Row>
                      <Col xs="9" className="mr-0 pr-0">
                        <AvField
                          name="meter_number"
                          value={formData.meter_number}
                          onChange={(e) => {
                            formOnChange(e);
                          }}
                          placeholder="Meter number"
                          autocomplete="off"
                          type="number"
                          errorMessage="Enter valid Meter Number"
                          validate={{
                            required: { value: true },
                            pattern: {
                              value: "^[0-9]+$",
                              errorMessage: "Only valid Meter number",
                            },
                          }}
                          maxLength="20"
                        />
                      </Col>
                      <Col xs="3">
                        <Button
                          style={{
                            backgroundColor: appData.business.primary_color,
                          }}
                          className="waves-effect waves-light "
                          onClick={verifyReceiver}
                        >
                          <p className="small mb-0">Verify</p>
                        </Button>
                      </Col>
                    </Row>

                    {formData.receiverName && (
                      <p className="mb-0 small">
                        <b>Customer's Name: {formData.receiverName}</b>
                      </p>
                    )}
                    {formData.receiverAddress && (
                      <p className="mb-0 small">
                        <b>Customer's Address: {formData.receiverAddress}</b>
                      </p>
                    )}
                    <h6 className="text-uppercase text-dark mt-2 small">
                      ENTER PHONE NUMBER{" "}
                    </h6>
                    <AvField
                      name="phone_number"
                      value={formData.phone_number}
                      onChange={(e) => {
                        formOnChange(e);
                      }}
                      placeholder={
                        user.data ? user.data.phone_number : "Phone number"
                      }
                      type="number"
                      errorMessage="Enter valid phone number"
                      required={user.data ? false : true}
                      minLength="11"
                      maxLength="11"
                    />
                    <h6 className="text-uppercase text-dark mt-2 small">
                      How much do you want to pay?{" "}
                    </h6>
                    <AvField
                      name="amount"
                      value={formData.amount}
                      onChange={(e) => {
                        formOnChange(e);
                        calculateATM(e);
                      }}
                      placeholder="Min ₦50, Max ₦100,000"
                      type="number"
                      autoComplete="off"
                      autocomplete="off"
                      errorMessage="Enter valid amount"
                      validate={{
                        required: { value: true },
                        pattern: {
                          value: "^[0-9]+$",
                          errorMessage: "Only valid amount",
                        },
                      }}
                    />
                    <PaymentSelector />
                  </CardBody>
                </Card>

                {!user.data ? (
                  <Link to="signIn">
                    <Button
                      style={{
                        backgroundColor: appData.business.primary_color,
                      }}
                      className="w-md waves-effect waves-light"
                    >
                      Sign In
                    </Button>
                  </Link>
                ) : (
                  <Button
                    style={{ backgroundColor: appData.business.primary_color }}
                    className="w-md waves-effect waves-light"
                    type="submit"
                    id="payButton"
                  >
                    Pay{" "}
                    {formData.electricPlan && (
                      <React.Fragment>
                        ₦
                        {formatCurrency(
                          formData.amount
                            ? formData.atmPayment
                              ? formData.calcAmount
                              : formData.amount -
                                (formData.amount *
                                  formData.electricPlan.commission) /
                                  100
                            : 0
                        )}
                      </React.Fragment>
                    )}
                  </Button>
                )}
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default withRouter(DataPurchase);
