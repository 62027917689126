const formatCurrency=(currency)=>{
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'NGN',
      });

    var currency=  formatter.format(currency).substring(3);
    
    if(currency.startsWith("N") && !currency.startsWith("NaN")){
        currency= currency.substring(1,currency.length);
        currency=' -'+currency;
    }
    if(currency.includes(".00")){
        currency= currency.substring(0,currency.length-3);
    }
    return currency;
}
 

const getOS= ()=> {
    var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = 'WEB';
  
    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Web';//Mac
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'IOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'WEB';//Windows
    } else if (/Android/.test(userAgent)) {
      os = 'ANDROID';
    } else if (!os && /Linux/.test(platform)) {
      os = 'WEB';//linus
    }
  
    return os;
  }




export {  formatCurrency, getOS }