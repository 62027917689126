/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef } from "react";
import Layout from "./components/Layout/";
import {
  withRouter,
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
  useHistory,
  useLocation,
  Link,
} from "react-router-dom";
import routes from "./routes";
import "./custom.css";
import "./App.scss";
import axios from "axios";
import AccountDeleted from "./pages/AccountDeleted/accountDeleted";
import Kyc404 from "./pages/Kyc/Kyc404";

// Get all Auth methods
import {
  UserContext,
  ModalContext,
  AppDataContext,
  FormContext,
} from "./contexts/Contexts";

function withLayout(WrappedComponent) {
  // ...and returns another component...
  return class extends React.Component {
    render() {
      return (
        <Layout>
          <WrappedComponent />
        </Layout>
      );
    }
  };
}

function App(props) {
  const { appData, dispatch } = useContext(AppDataContext);
  const { loaderDispatch } = useContext(ModalContext);
  // const { formData, formDispatch } = useContext(FormContext);

  const { user } = useContext(UserContext);

  const homeRef = useRef(null);

  /////////////////Development Data Fetch PHP file will be used to provide the application data//////////////
  // localStorage.setItem("apiURL", "https://api.datanow.ng/api/user/882285/"); //clickpay
  // localStorage.setItem("apiURL", "https://api.datanow.ng/api/user/217308/"); //dataup
  // localStorage.setItem("apiURL", "https://api.datanow.ng/api/user/937506/"); //Finbar
  // localStorage.setItem("apiURL", "http://127.0.0.1:8000/api/user/882285/"); //only use in local production

  const fetchAppData = () => {
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Loading", isLoading: true },
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    if (user) myHeaders.append("Authorization", "Bearer " + user.token);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    //get the current business URL from url for token retrival
    var url = new URL(window.location.href);
    var device_token = url.searchParams.get("device_token");
    var dataURL = localStorage.getItem("apiURL") + "data";
    if (device_token) dataURL = dataURL + "?device_token=" + device_token;

    fetch(dataURL, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        dispatch({
          type: "STORE_APP_DATA",
          appData: {
            is_mobile: appData.is_mobile,
            timestamp: new Date().getTime(),
            ...response.data,
          },
        });
      });
  };

  useEffect(() => {
    !appData.settings
      ? fetchAppData()
      : !appData.timestamp
      ? fetchAppData()
      : new Date().getTime() - appData.timestamp > 180000 && fetchAppData();

    window.onpopstate = () => {
      if (window.location.pathname !== "/dashboard") {
        window.scrollTo(0, 0);
        homeRef.current.click();
        window.scrollTo(0, 0);
      }
      return false;
    };

    var headTitle = document.querySelector("head");
    var setFavicon = document.createElement("link");
    setFavicon.setAttribute("rel", "shortcut icon");
    appData.business &&
      setFavicon.setAttribute("href", appData.business.website + "/logo.png");
    headTitle.appendChild(setFavicon);
  }, []);
  /////////////////////////////////////////////////////////

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        user.data ? (
          user.data.deletion_date ? (
            <Redirect to="/accountDeleted" />
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect to="/signin" />
        )
      }
    />
  );
  const KycRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        user.data && user.data.kyc_verified_at ? (
          <Component {...props} />
        ) : (
          <Redirect to="/kyc404" />
        )
      }
    />
  );
  if (!appData.settings) return <div></div>;
  else {
    return (
      <div>
        <React.Fragment>
          <Router>
            <Link ref={homeRef} to="/dashboard" />
            <Switch>
              {routes.map((route, idx) => {
                if (route.withLayout) {
                  if (route.ispublic) {
                    return user.data ? (
                      user.data.deletion_date ? (
                        <Route
                          path={route.path}
                          component={AccountDeleted}
                          key={1}
                        />
                      ) : (
                        <Route
                          path={route.path}
                          component={withLayout(route.component)}
                          key={idx}
                        />
                      )
                    ) : (
                      <Route
                        path={route.path}
                        component={withLayout(route.component)}
                        key={idx}
                      />
                    );
                  } else if (route.kyc) {
                    return (
                      <KycRoute
                        path={route.path}
                        component={withLayout(route.component)}
                        key={idx}
                      />
                    );
                  }
                  return (
                    <PrivateRoute
                      path={route.path}
                      component={withLayout(route.component)}
                      key={idx}
                    />
                  );
                }
                return (
                  <Route
                    path={route.path}
                    component={route.component}
                    key={idx}
                  />
                );
              })}
            </Switch>
          </Router>
        </React.Fragment>
      </div>
    );
  }
}

export default withRouter(App);
