import React, { useContext } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Form,
  FormGroup,
} from "reactstrap";
import { ModalContext, AppDataContext } from "../contexts/Contexts";
import loaderImg from "../images/loader.gif";

const Loader = (props) => {
  const { loader } = useContext(ModalContext);
  const { appData } = useContext(AppDataContext);
  return (
    <Modal
      isOpen={loader.isLoading}
      backdrop="static"
      keyboard={false}
      centered={true}
    >
      <p>
        <img
          src={loaderImg}
          width="100"
          alt="logo"
          className="img-fluid mx-auto d-block pt-5"
        />
      </p>
      {appData.business ? (
        <p
          className="text-center pb-5"
          style={{ color: appData.business.primary_color }}
        >
          {loader.text}
        </p>
      ) : (
        <p className="text-center pb-5">{loader.text}</p>
      )}
    </Modal>
  );
};

export default Loader;
