import React, { useContext, useEffect } from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  UserContext,
  ModalContext,
  FormContext,
  AppDataContext,
} from "../../contexts/Contexts";
import { formatCurrency, getOS } from "../../helpers/utils";
import ResponseModal from "../../components/ResponseModal";

const FundWallet = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { formData, formDispatch } = useContext(FormContext);
  const { user, userDispatch } = useContext(UserContext);
  const { appData } = useContext(AppDataContext);
  document.title = "Self-Fix AutoAgent Funding-" + appData.business.name;

  const formOnChange = (e) => {
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  const toggleDaytime = () => {
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: "afternoon", value: !formData.afternoon },
    });
  };

  useEffect(() => {
    if (!formData.ref) {
      formDispatch({
        type: "SET_FORM_DATA",
        data: { name: "ref", value: Math.random().toString(36).slice(2) },
      });
    }

    document.getElementById("hour").addEventListener("input", function () {
      formDispatch({
        type: "SET_FORM_DATA",
        data: {
          name: "hour",
          value:
            this.value.replace(/\D/g, "") <= 12
              ? this.value.replace(/\D/g, "") * 1
              : 12,
        },
      });
    });

    document.getElementById("minute").addEventListener("input", function () {
      formDispatch({
        type: "SET_FORM_DATA",
        data: {
          name: "minute",
          value:
            this.value.replace(/\D/g, "") <= 59
              ? this.value.replace(/\D/g, "") * 1
              : 0,
        },
      });
    });

    if (user.data.auto_agent_accounts.length < 1) {
      formDispatch({
        type: "SET_FORM_DATA",
        data: {
          name: "addBank",
          value: !formData.addBank,
        },
      });
    }
  }, []);

  const onValidSubmit = (e) => {
    e.persist();
    e.preventDefault();
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Checking for Transaction...", isLoading: true },
    });
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: "confirmationModal", value: { isOpen: false, text: "" } },
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    user.data && myHeaders.append("Authorization", "Bearer " + user.token);

    var urlencoded = new URLSearchParams();
    urlencoded.append("id", String(formData.autoAgentId));
    urlencoded.append("amount", String(formData.amount));
    urlencoded.append("date", String(formData.date));
    urlencoded.append(
      "time",
      formData.hour +
        ":" +
        formData.minute +
        (formData.afternoon ? ":00 PM" : ":00 AM")
    );
    urlencoded.append("source", getOS());
    urlencoded.append("ref", formData.ref);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      //redirect: "follow",
    };
    fetch(localStorage.getItem("apiURL") + "selffix_autoagent", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);

        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          formDispatch({
            type: "SET_FORM_DATA",
            data: {
              name: "responseModal",
              value: { isOpen: true, text: data.message },
            },
          });
        } else if (
          data.message === "Token Expired" ||
          data.message === "User Not Found"
        ) {
          props.history.push("/signout");
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
          formDispatch({
            type: "SET_FORM_DATA",
            data: { name: "ref", value: Math.random().toString(36).slice(2) },
          });
        } else {
          formDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
          formDispatch({
            type: "SET_FORM_DATA",
            data: { name: "ref", value: Math.random().toString(36).slice(2) },
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  return (
    <React.Fragment>
      <ResponseModal isOpen={true} shouldGoHome={true} />
      <Container fluid>
        <br />
        <Row>
          <Col xl="10" ml="10">
            <Card className="mini-stat bg-white shadow">
              <CardBody>
                <div className="mb-2">
                  <p
                    className=" text-left mb-0 "
                    style={{ color: appData.business.primary_color }}
                  >
                    <b className="text-uppercase">Self-Fix AutoAgent Funding</b>
                  </p>
                  <hr style={{ borderColor: appData.business.primary_color }} />
                </div>

                <AvForm
                  className="form-horizontal mb-2"
                  autoComplete="off"
                  onValidSubmit={onValidSubmit}
                >
                  {formData.error && (
                    <Alert color="danger">{formData.error}</Alert>
                  )}
                  <p>
                    <b>Select Account Transferred From</b>
                  </p>

                  <select
                    value={formData.autoAgentId}
                    name="autoAgentId"
                    required
                    onChange={formOnChange}
                    className="  form-control mb-2"
                  >
                    <option value="">--Select Registered Bank---</option>
                    {user.data.auto_agent_accounts.map((bank, index) => {
                      return (
                        <option key={bank.id} value={bank.id}>
                          {appData.banks[
                            appData.banks.findIndex(
                              (iteratedBank) =>
                                iteratedBank.id * 1 === bank.bank_id * 1
                            )
                          ].name +
                            " " +
                            bank.account_number +
                            "(" +
                            bank.account_name +
                            ")"}
                        </option>
                      );
                    })}
                  </select>
                  <h6 className="text-uppercase text-dark mt-2 small">
                    Can't find your account?
                    {user.data && (
                      <label
                        className="ml-1 p-1 text-white rounded-pill "
                        onClick={() => {
                          props.history.push("/fundWalletAuto");
                        }}
                        style={{
                          backgroundColor: appData.business.primary_color,
                        }}
                      >
                        Register it here
                      </label>
                    )}
                  </h6>

                  <AvField
                    name="amount"
                    value={formData.amount}
                    onChange={formOnChange}
                    type="number"
                    label="Amount"
                    autoComplete="off"
                    autocomplete="off"
                    errorMessage="Enter valid amount"
                    validate={{
                      required: { value: true },
                      pattern: {
                        value: "^[0-9]+$",
                        errorMessage: "Only valid amount",
                      },
                    }}
                  />

                  <AvField
                    name="date"
                    value={formData.date}
                    onChange={formOnChange}
                    type="date"
                    label="Date as seen on transaction receipt"
                    autoComplete="off"
                    autocomplete="off"
                    errorMessage="Enter valid date"
                    validate={{
                      required: { value: true },
                    }}
                  />

                  <p className="pb-0 mb-0">
                    <b>Time as seen on transaction receipt</b>
                  </p>

                  <Row>
                    <Col xs="3" className=" my-auto align-items-center ">
                      <input
                        name="hour"
                        id="hour"
                        value={formData.hour}
                        onChange={formOnChange}
                        type="text"
                        inputMode="numeric"
                        autoComplete="off"
                        className="text-center form-control   "
                        autocomplete="off"
                        style={{ fontSize: 24 }}
                        errorMessage="Enter valid hour"
                        validate={{
                          required: { value: true },
                          pattern: {
                            value: "^[0-9]{1,6}$",
                            errorMessage: "Only valid value",
                          },
                        }}
                      />
                    </Col>
                    <Row className="align-items-center  ">
                      <Col>
                        <label
                          className="mx-auto my-auto "
                          style={{ fontSize: 30 }}
                        >
                          :
                        </label>
                      </Col>
                    </Row>
                    <Col xs="3" className="my-auto align-items-center">
                      <input
                        name="minute"
                        id="minute"
                        value={formData.minute}
                        onChange={formOnChange}
                        type="text"
                        inputMode="numeric"
                        autoComplete="off"
                        className="text-center form-control  "
                        autocomplete="off"
                        style={{ fontSize: 24 }}
                        errorMessage="Enter valid minute"
                        validate={{
                          required: { value: true },
                          pattern: {
                            value: "^[0-9]{1,6}$",
                            errorMessage: "Only valid value",
                          },
                        }}
                      />
                    </Col>
                    <Col
                      xs="2"
                      className="border border-primary rounded-lg ml-2 mr-2 p-0 m-0"
                      onClick={toggleDaytime}
                    >
                      <p
                        className={
                          !formData.afternoon
                            ? "p-2 bg-primary text-center text-white  rounded-t-lg mb-0"
                            : "p-2  text-gray-400 text-center   rounded-b-lg mb-0"
                        }
                      >
                        AM
                      </p>
                      <p
                        className={
                          formData.afternoon
                            ? "p-2 bg-primary text-center text-white  rounded-t-lg mb-0"
                            : "p-2  text-gray-400 text-center   rounded-b-lg mb-0"
                        }
                      >
                        PM
                      </p>
                    </Col>
                  </Row>
                  <Button
                    type="submit"
                    style={{
                      backgroundColor: appData.business.primary_color,
                    }}
                    className="w-md waves-effect waves-light"
                  >
                    Resolve Funding
                  </Button>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(FundWallet);
