import React, { useContext } from "react";
import { useHistory, Link } from "react-router-dom";

import { Row, Col, Card, CardBody } from "reactstrap";
import { withRouter } from "react-router-dom";
import { FormContext } from "../contexts/Contexts";

//Images
import ikedc from "../images/ikedc.png";
import ekedc from "../images/ekedc.png";
import kedco from "../images/kedco.png";
import phed from "../images/phed.png";
import jed from "../images/jed.png";
import ibedc from "../images/ibedc.png";
import kaedco from "../images/kaedco.png";
import aedc from "../images/aedc.png";
import eedc from "../images/eedc.png";
import bedc from "../images/bedc.png";
import { formatCurrency } from "../helpers/utils";

import { AppDataContext } from "../contexts/Contexts";

const ElectricPlan = (props) => {
  const history = useHistory();
  const { formDispatch } = useContext(FormContext);
  const { appData } = useContext(AppDataContext);

  const onPlanSelect = (electricPlan, disco, image) => {
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: "electricPlan", value: electricPlan },
    });
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: "disco", value: disco },
    });
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: "image", value: image },
    });

    history.push("/electricPurchase");
  };

  return (
    <Link className="text-dark ">
      <Row
        style={{ cursor: "pointer" }}
        onClick={() => {
          onPlanSelect(props.values, props.disco, props.image);
        }}
      >
        <Col xl="12" md="12" sm="12" xs="12">
          <p className="text-truncate text-center">
            {" "}
            <label
              className="text-dark p-1 rounded pl-2 pr-2"
              style={{
                cursor: "pointer",
                border: "1px solid " + appData.business.primary_color,
              }}
            >
              {props.values.name}
            </label>{" "}
            <b style={{ color: appData.business.primary_color }}>
              {props.values.commission > 0 &&
                props.values.commission + "% discount"}
            </b>
          </p>
        </Col>
      </Row>
    </Link>
  );
};

const ElectricPrice = () => {
  const { appData } = useContext(AppDataContext);
  const { formData, formDispatch } = useContext(FormContext);

  const onDiscoSelect = (disco) => {
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: "disco", value: disco },
    });
    // document.getElementById('DataPrice').scrollIntoView({behavior: "smooth", block: "start", inline: "start"})
    let el = document.getElementById("electric");
    let elScrollOffset = el.getBoundingClientRect().top;
    let scrollOffset = window.pageYOffset || document.documentElement.scrollTop;
    let padding = 90;
    let target = elScrollOffset + scrollOffset - padding;
    window.scrollTo({
      top: target,
      behavior: "smooth",
    });
    window.onscroll = (e) => {
      let currentScrollOffset =
        window.pageYOffset || document.documentElement.scrollTop;
      // Scroll reach the target
      if (currentScrollOffset * 1 === target * 1) {
        el.focus();
        window.onscroll = null; // remove listener
      }
    };
  };

  const IKEDCElectricPlans = appData.electric_plans.IKEDC.map(
    (electricPlan) => (
      <ElectricPlan
        image={ikedc}
        key={electricPlan.id}
        values={electricPlan}
        disco="IKEDC"
      />
    )
  );

  const EKEDCElectricPlans = appData.electric_plans.EKEDC.map(
    (electricPlan) => (
      <ElectricPlan
        image={ekedc}
        key={electricPlan.id}
        values={electricPlan}
        disco="EKEDC"
      />
    )
  );

  const KEDCOElectricPlans = appData.electric_plans.KEDCO.map(
    (electricPlan) => (
      <ElectricPlan
        image={kedco}
        key={electricPlan.id}
        values={electricPlan}
        disco="KEDCO"
      />
    )
  );

  const PHEDElectricPlans = appData.electric_plans.PHED.map((electricPlan) => (
    <ElectricPlan
      image={phed}
      key={electricPlan.id}
      values={electricPlan}
      disco="PHED"
    />
  ));

  const JEDElectricPlans = appData.electric_plans.JED.map((electricPlan) => (
    <ElectricPlan
      image={jed}
      key={electricPlan.id}
      values={electricPlan}
      disco="JED"
    />
  ));

  const IBEDCElectricPlans = appData.electric_plans.IBEDC.map(
    (electricPlan) => (
      <ElectricPlan
        image={ibedc}
        key={electricPlan.id}
        values={electricPlan}
        disco="IBEDC"
      />
    )
  );

  const KAEDCOElectricPlans = appData.electric_plans.KAEDCO.map(
    (electricPlan) => (
      <ElectricPlan
        image={kaedco}
        key={electricPlan.id}
        values={electricPlan}
        disco="KAEDCO"
      />
    )
  );

  const AEDCElectricPlans = appData.electric_plans.AEDC.map((electricPlan) => (
    <ElectricPlan
      image={aedc}
      key={electricPlan.id}
      values={electricPlan}
      disco="AEDC"
    />
  ));

  const EEDCElectricPlans =
    appData.electric_plans.EEDC &&
    appData.electric_plans.EEDC.map((electricPlan) => (
      <ElectricPlan
        image={eedc}
        key={electricPlan.id}
        values={electricPlan}
        disco="EEDC"
      />
    ));

  const BEDCElectricPlans =
    appData.electric_plans.BEDC &&
    appData.electric_plans.BEDC.map((electricPlan) => (
      <ElectricPlan
        image={bedc}
        key={electricPlan.id}
        values={electricPlan}
        disco="BEDC"
      />
    ));
  const discoArray = [];
  if (appData.settings.electric_master) {
    Object.keys(appData.electric_plans.IKEDC).length > 0 &&
      discoArray.push({
        electricPlans: IKEDCElectricPlans,
        image: ikedc,
        disco: "IKEDC",
      });
    Object.keys(appData.electric_plans.EKEDC).length > 0 &&
      discoArray.push({
        electricPlans: EKEDCElectricPlans,
        image: ekedc,
        disco: "EKEDC",
      });
    Object.keys(appData.electric_plans.KEDCO).length > 0 &&
      discoArray.push({
        electricPlans: KEDCOElectricPlans,
        image: kedco,
        disco: "KEDCO",
      });
    Object.keys(appData.electric_plans.PHED).length > 0 &&
      discoArray.push({
        electricPlans: PHEDElectricPlans,
        image: phed,
        disco: "PHED",
      });
    Object.keys(appData.electric_plans.JED).length > 0 &&
      discoArray.push({
        electricPlans: JEDElectricPlans,
        image: jed,
        disco: "JED",
      });
    Object.keys(appData.electric_plans.IBEDC).length > 0 &&
      discoArray.push({
        electricPlans: IBEDCElectricPlans,
        image: ibedc,
        disco: "IBEDC",
      });
    Object.keys(appData.electric_plans.KAEDCO).length > 0 &&
      discoArray.push({
        electricPlans: KAEDCOElectricPlans,
        image: kaedco,
        disco: "KAEDCO",
      });
    Object.keys(appData.electric_plans.AEDC).length > 0 &&
      discoArray.push({
        electricPlans: AEDCElectricPlans,
        image: aedc,
        disco: "AEDC",
      });
    appData.electric_plans.EEDC &&
      Object.keys(appData.electric_plans.EEDC).length > 0 &&
      discoArray.push({
        electricPlans: EEDCElectricPlans,
        image: eedc,
        disco: "EEDC",
      });
    appData.electric_plans.BEDC &&
      Object.keys(appData.electric_plans.BEDC).length > 0 &&
      discoArray.push({
        electricPlans: BEDCElectricPlans,
        image: bedc,
        disco: "BEDC",
      });
  }

  return (
    appData.settings.electric_master * 1 === 0 && (
      <React.Fragment>
        {(appData.electric_plans.IKEDC ||
          appData.electric_plans.EKEDC ||
          appData.electric_plans.KEDCO ||
          appData.electric_plans.PHED ||
          appData.electric_plans.JED ||
          appData.electric_plans.IBEDC ||
          appData.electric_plans.KAEDCO ||
          appData.electric_plans.AEDC ||
          appData.electric_plans.EEDC ||
          appData.electric_plans.BEDC) && (
          <Row
            id="electric"
            className="m-1"
            style={{ scrollMargin: 90, scrollSnapMargin: 90 }}
          >
            <Col xl="10" md="10">
              <Card className="mini-stat bg-white text-primary shadow pl-3 pr-3">
                <div className="mb-3">
                  <p
                    className="text-uppercase text-left mt-2  "
                    style={{ color: appData.business.primary_color }}
                  >
                    <b>Electricity Bills Payment</b>
                  </p>
                  <hr style={{ borderColor: appData.business.primary_color }} />
                </div>

                <Row className="justify-content-center">
                  {formData.disco !== "IKEDC" &&
                    Object.keys(appData.electric_plans.IKEDC).length > 0 && (
                      <Card className="mini-stat p-2 m-2 bg-white-50 text-dark shadow">
                        <Col
                          style={{ cursor: "pointer" }}
                          xl="3"
                          xs="3"
                          className="text-center text-dark"
                          onClick={() => {
                            onDiscoSelect("IKEDC");
                          }}
                        >
                          <img src={ikedc} height="36" alt="logo"></img>
                        </Col>
                      </Card>
                    )}
                  {formData.disco !== "EKEDC" &&
                    Object.keys(appData.electric_plans.EKEDC).length > 0 && (
                      <Card className="mini-stat p-2 m-2 bg-white-50 text-dark shadow">
                        <Col
                          style={{ cursor: "pointer" }}
                          xl="3"
                          xs="3"
                          className="text-center text-dark"
                          onClick={() => {
                            onDiscoSelect("EKEDC");
                          }}
                        >
                          <img src={ekedc} height="36" alt="logo"></img>
                        </Col>
                      </Card>
                    )}
                  {formData.disco !== "KEDCO" &&
                    Object.keys(appData.electric_plans.KEDCO).length > 0 && (
                      <Card className="mini-stat p-2 m-2 bg-white-50 text-dark shadow">
                        <Col
                          style={{ cursor: "pointer" }}
                          xl="3"
                          xs="3"
                          className="text-center text-dark"
                          onClick={() => {
                            onDiscoSelect("KEDCO");
                          }}
                        >
                          <img src={kedco} height="36" alt="logo"></img>
                        </Col>
                      </Card>
                    )}
                  {formData.disco !== "PHED" &&
                    Object.keys(appData.electric_plans.PHED).length > 0 && (
                      <Card className="mini-stat p-2 m-2 bg-white-50 text-dark shadow">
                        <Col
                          style={{ cursor: "pointer" }}
                          xl="3"
                          xs="3"
                          className="text-center text-dark"
                          onClick={() => {
                            onDiscoSelect("PHED");
                          }}
                        >
                          <img src={phed} height="36" alt="logo"></img>
                        </Col>
                      </Card>
                    )}
                  {formData.disco !== "JED" &&
                    Object.keys(appData.electric_plans.JED).length > 0 && (
                      <Card className="mini-stat p-2 m-2 bg-white-50 text-dark shadow">
                        <Col
                          style={{ cursor: "pointer" }}
                          xl="3"
                          xs="3"
                          className="text-center text-dark"
                          onClick={() => {
                            onDiscoSelect("JED");
                          }}
                        >
                          <img src={jed} height="36" alt="logo"></img>
                        </Col>
                      </Card>
                    )}
                  {formData.disco !== "IBEDC" &&
                    Object.keys(appData.electric_plans.IBEDC).length > 0 && (
                      <Card className="mini-stat p-2 m-2 bg-white-50 text-dark shadow">
                        <Col
                          style={{ cursor: "pointer" }}
                          xl="3"
                          xs="3"
                          className="text-center text-dark"
                          onClick={() => {
                            onDiscoSelect("IBEDC");
                          }}
                        >
                          <img src={ibedc} height="36" alt="logo"></img>
                        </Col>
                      </Card>
                    )}
                  {formData.disco !== "KAEDCO" &&
                    Object.keys(appData.electric_plans.KAEDCO).length > 0 && (
                      <Card className="mini-stat p-2 m-2 bg-white-50 text-dark shadow">
                        <Col
                          style={{ cursor: "pointer" }}
                          xl="3"
                          xs="3"
                          className="text-center text-dark"
                          onClick={() => {
                            onDiscoSelect("KAEDCO");
                          }}
                        >
                          <img src={kaedco} height="36" alt="logo"></img>
                        </Col>
                      </Card>
                    )}
                  {formData.disco !== "AEDC" &&
                    Object.keys(appData.electric_plans.AEDC).length > 0 && (
                      <Card className="mini-stat p-2 m-2 bg-white-50 text-dark shadow">
                        <Col
                          style={{ cursor: "pointer" }}
                          xl="3"
                          xs="3"
                          className="text-center text-dark"
                          onClick={() => {
                            onDiscoSelect("AEDC");
                          }}
                        >
                          <img src={aedc} height="36" alt="logo"></img>
                        </Col>
                      </Card>
                    )}
                  {appData.electric_plans.EEDC &&
                    formData.disco !== "EEDC" &&
                    Object.keys(appData.electric_plans.EEDC).length > 0 && (
                      <Card className="mini-stat p-2 m-2 bg-white-50 text-dark shadow">
                        <Col
                          style={{ cursor: "pointer" }}
                          xl="3"
                          xs="3"
                          className="text-center text-dark"
                          onClick={() => {
                            onDiscoSelect("EEDC");
                          }}
                        >
                          <img src={eedc} height="36" alt="logo"></img>
                        </Col>
                      </Card>
                    )}
                  {appData.electric_plans.BEDC &&
                    formData.disco !== "BEDC" &&
                    Object.keys(appData.electric_plans.BEDC).length > 0 && (
                      <Card className="mini-stat p-2 m-2 bg-white-50 text-dark shadow">
                        <Col
                          style={{ cursor: "pointer" }}
                          xl="3"
                          xs="3"
                          className="text-center text-dark"
                          onClick={() => {
                            onDiscoSelect("BEDC");
                          }}
                        >
                          <img src={bedc} height="36" alt="logo"></img>
                        </Col>
                      </Card>
                    )}
                </Row>

                <Row className="justify-content-center mt-3">
                  {discoArray.map((array) => {
                    return formData.disco === array.disco ? (
                      <Col
                        xl="6"
                        md="6"
                        className="text-left text-truncate "
                        style={{ fontSize: "12px" }}
                      >
                        <div className="mb-2 text-center">
                          <img
                            src={array.image}
                            alt="logo"
                            height="44"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              onDiscoSelect("");
                            }}
                          ></img>
                        </div>
                        <Row className="pb-0 mb-0 text-center">
                          <Col
                            style={{ color: appData.business.primary_color }}
                            xl="12"
                            md="12"
                            sm="12"
                            xs="12"
                          >
                            SELECT METER TYPE
                          </Col>
                        </Row>
                        <hr className="mt-0" />
                        {array.electricPlans}
                      </Col>
                    ) : (
                      <div></div>
                    );
                  })}
                </Row>
              </Card>
            </Col>
          </Row>
        )}
      </React.Fragment>
    )
  );
};

export default withRouter(ElectricPrice);
