import React, { useContext } from "react";
import { withRouter } from "react-router-dom";

import AirtimePrice from "../../components/AirtimePrice";
import { AppDataContext } from "../../contexts/Contexts";

const DataPricing = () => {
const {appData}= useContext(AppDataContext)
  document.title='Purchase Airtime-'+ appData.business.name;

  return (
    <React.Fragment>
      <br/>
      <AirtimePrice></AirtimePrice>
    </React.Fragment>
  );
};

export default withRouter(DataPricing);
