import React, { useContext, useEffect } from "react";
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Button,
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  UserContext,
  ModalContext,
  FormContext,
  AppDataContext,
} from "../../contexts/Contexts";
import PaymentSelector from "../../components/PaymentSelector";
import { formatCurrency, getOS } from "../../helpers/utils";
import ResponseModal from "../../components/ResponseModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import ContactModal from "../../components/ContactModal";

const DataPurchase = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { formData, formDispatch } = useContext(FormContext);
  const { user } = useContext(UserContext);
  const { appData } = useContext(AppDataContext);
  document.title = "Purchase Data-" + appData.business.name;

  const formOnChange = (e) => {
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  !formData.image && props.history.push("/dataPricing");

  useEffect(() => {
    document
      .getElementById("phone_number")
      .addEventListener("input", function () {
        formDispatch({
          type: "SET_FORM_DATA",
          data: { name: "phone_number", value: this.value.replace(/\D/g, "") },
        });
      });

    if (!formData.ref) {
      formDispatch({
        type: "SET_FORM_DATA",
        data: { name: "ref", value: Math.random().toString(36).slice(2) },
      });
    }
  }, []);

  var networkPrefix = {
    MTN: [
      "0803",
      "0703",
      "0903",
      "0806",
      "0706",
      "0813",
      "0810",
      "0814",
      "0816",
      "0906",
      "0704",
      "0916",
      "0911",
      "0707",
      "0907",
      "0913",
    ],
    GLO: ["0805", "0705", "0905", "0807", "0815", "0811", "0905", "0915"],
    AIRTEL: ["0802", "0902", "0701", "0808", "0708", "0812", "0901", "0907"],
    ETISALAT: ["0809", "0909", "0817", "0818", "0908"],
  };

  const onValidSubmit = (e) => {
    formDispatch({
      type: "SET_FORM_DATA",
      data: {
        name: "confirmationModal",
        value: {
          isOpen: true,
          type: "Data Subscription",
          description: formData.dataPlan.name,
          receiver: formData.phone_number,
          amount: formData.atmPayment
            ? formData.dataPlan.atm_price
            : formData.dataPlan.wallet_price,
          image: formData.image,
        },
      },
    });
  };

  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: "confirmationModal", value: { isOpen: false, text: "" } },
    });
    for (
      let index = 0;
      index < networkPrefix[formData.network].length;
      index++
    ) {
      if (
        formData.phone_number.startsWith(networkPrefix[formData.network][index])
      ) {
        process();
        return;
      }
    }
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isModalOpen", value: true },
    });
  };

  const process = () => {
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Processing your transaction...", isLoading: true },
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    user.data && myHeaders.append("Authorization", "Bearer " + user.token);

    var urlencoded = new URLSearchParams();
    user.data &&
      !formData.atmPayment &&
      urlencoded.append("password", String(formData.password));
    !user.data &&
      formData.atmPayment &&
      urlencoded.append("email", String(formData.email));
    urlencoded.append("plan_id", String(formData.dataPlan.id));
    urlencoded.append("phone_number", String(formData.phone_number));
    urlencoded.append("payment_method", formData.atmPayment ? "ATM" : "WALLET");
    urlencoded.append("source", getOS());
    urlencoded.append("ref", formData.ref);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      //redirect: "follow",
    };
    fetch(localStorage.getItem("apiURL") + "data_purchase", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        if (data.status === "success" && formData.atmPayment) {
          window.location = data.data.payment_url;
          return;
        }
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          formDispatch({
            type: "SET_FORM_DATA",
            data: {
              name: "responseModal",
              value: { isOpen: true, text: data.message },
            },
          });
        } else if (
          data.message === "Token Expired" ||
          data.message === "User Not Found"
        ) {
          props.history.push("/signout");
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
          formDispatch({
            type: "SET_FORM_DATA",
            data: { name: "ref", value: Math.random().toString(36).slice(2) },
          });
        } else {
          formDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
          formDispatch({
            type: "SET_FORM_DATA",
            data: { name: "ref", value: Math.random().toString(36).slice(2) },
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  const getPhoneNumber = () => {
    window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ command: "getPhoneNumber" })
      );
  };

  return (
    <React.Fragment>
      <ResponseModal isOpen={true} shouldGoHome={true} />
      <ContactModal />
      <ConfirmationModal isOpen={false} onConfirmationClicked={handleSubmit} />
      <br />

      <Row>
        <Col xl="12" ml="12">
          <Card className="mini-stat bg-white">
            <CardBody>
              <div className="mb-2 text-center">
                <img src={formData.image} alt="logo" height="36"></img>
              </div>
              <hr style={{ borderColor: appData.business.primary_color }} />
              <p
                className="text-uppercase text-left mt-2  "
                style={{ color: appData.business.primary_color }}
              >
                DATA PLAN: {formData.dataPlan && formData.dataPlan.name}
              </p>

              {/* {formData.dataPlan && formData.dataPlan.message && (
                <Card className="mini-stat shadow text-dark border border-danger mt-0 mb-2">
                  <p className="mt-2 mb-2 pl-2">
                    <i className="mdi mdi-alert-circle-outline  text-danger mdi "></i>{" "}
                    {formData.dataPlan.message}
                  </p>
                </Card>
              )} */}

              {formData.error && <Alert color="danger">{formData.error}</Alert>}

              <AvForm
                className="form-horizontal "
                autoComplete="off"
                onValidSubmit={onValidSubmit}
              >
                <Card className="mini-stat shadow text-dark ">
                  <CardBody>
                    <h6 className="text-uppercase text-dark mt-2 small">
                      ENTER PHONE NUMBER
                      {user.data && (
                        <label
                          className="ml-1 p-1 text-white rounded-pill "
                          onClick={() => {
                            formDispatch({
                              type: "SET_FORM_DATA",
                              data: {
                                name: "phone_number",
                                value: user.data.phone_number,
                              },
                            });
                          }}
                          style={{
                            backgroundColor: appData.business.primary_color,
                          }}
                        >
                          Paste my number
                        </label>
                      )}
                    </h6>
                    <AvField
                      name="phone_number"
                      value={formData.phone_number}
                      onChange={(e) => {
                        formOnChange(e);
                      }}
                      placeholder="Phone number"
                      type="text"
                      id="phone_number"
                      errorMessage="Enter valid phone number"
                      required
                      pattern="[0-9]{11}"
                    />
                    {localStorage.getItem("isMobileApp") && (
                      <div className="d-flex justify-content-end ">
                        <i
                          className={
                            user.data
                              ? "mdi mdi-contacts mdi-24px   contact-button-user"
                              : "mdi mdi-contacts mdi-24px   contact-button"
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            getPhoneNumber();
                          }}
                        />
                      </div>
                    )}
                    {formData.contact_name && (
                      <p className="m-0 p-0 small">{formData.contact_name}</p>
                    )}
                    <PaymentSelector />
                    {formData.network !== "MTN" &&
                      formData.network !== "ETISALAT" && (
                        <p>
                          <b className="text-danger">
                            Warning! Data will not be received if you have an
                            outstanding airtime/data loan
                          </b>
                        </p>
                      )}
                  </CardBody>
                </Card>

                {!user.data ? (
                  <Link to="signIn">
                    <Button
                      style={{
                        backgroundColor: appData.business.primary_color,
                      }}
                      className="w-md waves-effect waves-light"
                    >
                      Sign In
                    </Button>
                  </Link>
                ) : (
                  <Button
                    style={{ backgroundColor: appData.business.primary_color }}
                    className="w-md waves-effect waves-light"
                    type="submit"
                    id="payButton"
                  >
                    Pay{" "}
                    {formData.dataPlan && (
                      <React.Fragment>
                        ₦
                        {formatCurrency(
                          formData.atmPayment
                            ? formData.dataPlan.atm_price
                            : formData.dataPlan.wallet_price
                        )}
                      </React.Fragment>
                    )}
                  </Button>
                )}
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal
        isOpen={formData.isModalOpen}
        backdrop="static"
        keyboard={false}
        centered={true}
      >
        <p className="text-danger text-center mb-0 pb-0">
          <i
            className="mdi mdi-alert-circle-outline  "
            style={{ fontSize: 120 }}
          ></i>
        </p>
        <p className="text-center">
          Confirm Phone Number! It doesn't look like <b>{formData.network}</b>{" "}
          numbers.
        </p>
        <p className="text-center">
          <b>Proceed with {formData.phone_number}?</b>
        </p>
        <p className="text-center m-0">
          <button
            className="btn btn-danger m-2"
            onClick={() => {
              formDispatch({
                type: "SET_FORM_DATA",
                data: { name: "isModalOpen", value: false },
              });
              process();
            }}
          >
            Yes
          </button>
          <button
            className="btn btn-info m-2"
            onClick={() => {
              formDispatch({
                type: "SET_FORM_DATA",
                data: { name: "isModalOpen", value: false },
              });
            }}
          >
            No
          </button>
        </p>
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(DataPurchase);
