import React, { useContext, useEffect } from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  UserContext,
  ModalContext,
  FormContext,
  AppDataContext,
} from "../../contexts/Contexts";
import { formatCurrency, getOS } from "../../helpers/utils";
import ResponseModal from "../../components/ResponseModal";

const FundWallet = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { formData, formDispatch } = useContext(FormContext);
  const { user, userDispatch } = useContext(UserContext);
  const { appData } = useContext(AppDataContext);
  document.title = "Self-Fix Funding Issues -" + appData.business.name;

  return (
    <React.Fragment>
      <ResponseModal isOpen={true} />
      <Container fluid>
        <br />
        <Row>
          <Col xl="10" ml="10">
            <Card className="mini-stat bg-white shadow">
              <CardBody>
                <div className="mb-2">
                  <p
                    className=" text-left mb-0 "
                    style={{ color: appData.business.primary_color }}
                  >
                    <b className="text-uppercase">Self-Fix Wallet Funding</b>
                  </p>
                  <hr style={{ borderColor: appData.business.primary_color }} />
                </div>

                <p
                  className="text-left mt-2  text-dark"
                  style={{ fontSize: 13 }}
                >
                  <b>
                    Did you fund your wallet and it is yet to reflect? Resolve
                    your complaint with our self-fix and save time!
                  </b>
                </p>

                <p
                  className="text-left mt-2  text-dark"
                  style={{ fontSize: 15 }}
                >
                  <b>Select the Funding Method</b>
                </p>

                <Row>
                  <Col xs="6">
                    <Card
                      className="mini-stat shadow text-dark small cursor  "
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        props.history.push("/selffixAutoAgent");
                      }}
                    >
                      <CardBody>
                        <p>
                          <i className="mdi mdi-wallet mdi-14px "></i>{" "}
                          <label style={{ fontSize: 14 }}>AutoAgent</label>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(FundWallet);
