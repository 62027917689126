import React, { useContext, useEffect } from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  UserContext,
  ModalContext,
  FormContext,
  AppDataContext,
} from "../../contexts/Contexts";
import { formatCurrency, getOS } from "../../helpers/utils";
import ResponseModal from "../../components/ResponseModal";

const FundWallet = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { formData, formDispatch } = useContext(FormContext);
  const { user, userDispatch } = useContext(UserContext);
  const { appData } = useContext(AppDataContext);
  document.title = "Fund Wallet with Autoagent-" + appData.business.name;

  const formOnChange = (e) => {
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  useEffect(() => {
    if (!formData.ref) {
      formDispatch({
        type: "SET_FORM_DATA",
        data: { name: "ref", value: Math.random().toString(36).slice(2) },
      });
    }
    if (user.data.auto_agent_accounts.length < 1) {
      formDispatch({
        type: "SET_FORM_DATA",
        data: {
          name: "addBank",
          value: !formData.addBank,
        },
      });
    }
  }, []);

  const onValidSubmit = (e) => {
    e.persist();
    e.preventDefault();
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Registering account...", isLoading: true },
    });
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: "confirmationModal", value: { isOpen: false, text: "" } },
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    user.data && myHeaders.append("Authorization", "Bearer " + user.token);

    var urlencoded = new URLSearchParams();
    urlencoded.append("account_name", String(formData.account_name));
    urlencoded.append("bank", String(formData.bank));
    urlencoded.append("account_number", String(formData.account_number));
    urlencoded.append("source", getOS());
    urlencoded.append("ref", formData.ref);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      //redirect: "follow",
    };
    fetch(localStorage.getItem("apiURL") + "add_autoagent_bank", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);

        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          userDispatch({ type: "UPDATE_USER", action: data });

          formDispatch({
            type: "SET_FORM_DATA",
            data: {
              name: "responseModal",
              value: { isOpen: true, text: data.message },
            },
          });
        } else if (
          data.message === "Token Expired" ||
          data.message === "User Not Found"
        ) {
          props.history.push("/signout");
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
          formDispatch({
            type: "SET_FORM_DATA",
            data: { name: "ref", value: Math.random().toString(36).slice(2) },
          });
        } else {
          formDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
          formDispatch({
            type: "SET_FORM_DATA",
            data: { name: "ref", value: Math.random().toString(36).slice(2) },
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  const deleteLinkedBank = (id) => {
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Unregistering account...", isLoading: true },
    });
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: "confirmationModal", value: { isOpen: false, text: "" } },
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    user.data && myHeaders.append("Authorization", "Bearer " + user.token);

    var urlencoded = new URLSearchParams();
    urlencoded.append("id", String(id));
    urlencoded.append("source", getOS());

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      //redirect: "follow",
    };
    fetch(
      localStorage.getItem("apiURL") + "delete_autoagent_bank",
      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        if (data.status === "success" && formData.atmPayment) {
          window.location = data.data.payment_url;
          return;
        }
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          userDispatch({ type: "UPDATE_USER", action: data });

          formDispatch({
            type: "SET_FORM_DATA",
            data: {
              name: "responseModal",
              value: { isOpen: true, text: data.message },
            },
          });
        } else if (
          data.message === "Token Expired" ||
          data.message === "User Not Found"
        ) {
          props.history.push("/signout");
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
          formDispatch({
            type: "SET_FORM_DATA",
            data: { name: "ref", value: Math.random().toString(36).slice(2) },
          });
        } else {
          formDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
          formDispatch({
            type: "SET_FORM_DATA",
            data: { name: "ref", value: Math.random().toString(36).slice(2) },
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  return (
    <React.Fragment>
      <ResponseModal isOpen={true} />
      <Container fluid>
        <br />
        <Row>
          <Col xl="10" ml="10">
            <Card className="mini-stat bg-white shadow">
              <CardBody>
                <div className="mb-2">
                  <p
                    className=" text-left mb-0 "
                    style={{ color: appData.business.primary_color }}
                  >
                    <b className="text-uppercase">
                      Fund Wallet with Auto Agent{" "}
                    </b>
                  </p>
                  <hr style={{ borderColor: appData.business.primary_color }} />
                </div>

                {user.data.auto_agent_accounts.length > 0 && (
                  <p
                    className="text-left mt-2  text-dark"
                    style={{ fontSize: 13 }}
                  >
                    <b>
                      Registered Bank Accounts{" "}
                      <label
                        className="ml-1 p-1 text-white small rounded-pill "
                        onClick={() => {
                          formDispatch({
                            type: "SET_FORM_DATA",
                            data: {
                              name: "addBank",
                              value: !formData.addBank,
                            },
                          });
                        }}
                        style={{
                          backgroundColor: appData.business.primary_color,
                        }}
                      >
                        {formData.addBank ? " X " : "+ Register Bank Account"}
                      </label>
                    </b>
                  </p>
                )}

                {user.data.auto_agent_accounts.length < 1 && (
                  <p className="text-dark" style={{ fontSize: 13 }}>
                    <b>
                      ⚠️ Begin by telling us the account you will be sending
                      money from so we will know it is you when we receive it!
                    </b>
                  </p>
                )}

                {formData.addBank && (
                  <AvForm
                    className="form-horizontal mb-2"
                    autoComplete="off"
                    onValidSubmit={onValidSubmit}
                  >
                    {formData.error && (
                      <Alert color="danger">{formData.error}</Alert>
                    )}
                    <p>
                      <b>Bank</b>
                    </p>
                    <select
                      className="form-control mb-2"
                      value={formData.bank}
                      name="bank"
                      required
                      onChange={formOnChange}
                    >
                      <option value="">--Select Bank---</option>
                      {appData.banks.map((bank, index) => {
                        return (
                          <option key={bank.id} value={bank.id}>
                            {bank.name}
                          </option>
                        );
                      })}
                    </select>

                    <h6 className="text-uppercase text-dark mt-2 small">
                      Account Name
                      {user.data && (
                        <label
                          className="ml-1 p-1 text-white rounded-pill "
                          onClick={() => {
                            formDispatch({
                              type: "SET_FORM_DATA",
                              data: {
                                name: "account_name",
                                value:
                                  user.data.firstname +
                                  " " +
                                  user.data.othername +
                                  " " +
                                  user.data.lastname,
                              },
                            });
                          }}
                          style={{
                            backgroundColor: appData.business.primary_color,
                          }}
                        >
                          Paste my name
                        </label>
                      )}
                    </h6>
                    <AvField
                      name="account_name"
                      value={formData.account_name}
                      onChange={(e) => {
                        formOnChange(e);
                      }}
                      placeholder="Full account name"
                      type="text"
                      errorMessage="Enter valid name"
                      required
                      key="account_name"
                    />

                    <AvField
                      name="account_number"
                      value={formData.account_number}
                      onChange={(e) => {
                        formOnChange(e);
                      }}
                      placeholder="Account number"
                      type="number"
                      errorMessage="Enter valid account number"
                      required
                      key="account_number"
                    />

                    <Button
                      type="submit"
                      style={{
                        backgroundColor: appData.business.primary_color,
                      }}
                      className="w-md waves-effect waves-light"
                    >
                      Register Bank Account
                    </Button>
                  </AvForm>
                )}

                <Row>
                  {user.data.auto_agent_accounts.map((bank) => (
                    <Col xs="6">
                      <Card className="mini-stat shadow text-dark small  ">
                        <CardBody>
                          <p>
                            Bank:{" "}
                            {
                              appData.banks[
                                appData.banks.findIndex(
                                  (iteratedBank) =>
                                    iteratedBank.id * 1 === bank.bank_id * 1
                                )
                              ].name
                            }
                            <i
                              className="mdi mdi-delete-circle-outline float-right  mdi-24px "
                              onClick={() => {
                                deleteLinkedBank(bank.id);
                              }}
                            ></i>
                          </p>
                          <p>Account Number: {bank.account_number}</p>
                          <p>Account Name: {bank.account_name}</p>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>

                {user.data.auto_agent_accounts.length > 0 && (
                  <div>
                    <p
                      className="text-uppercase text-left mt-2  text-dark"
                      style={{ fontSize: 12 }}
                    >
                      {appData.master_settings.fund_status_auto * 1 === 0 ? (
                        <b>
                          STATUS: WORKING{" "}
                          <i className="mdi mdi-18px  mdi-shield-check-outline text-success"></i>{" "}
                        </b>
                      ) : (
                        <b>
                          STATUS: Downtime{" "}
                          <i className="mdi mdi-18px  mdi-alert-decagram  text-danger"></i>{" "}
                        </b>
                      )}
                    </p>
                    <p
                      className="text-uppercase text-left mt-2  text-dark"
                      style={{ fontSize: 12 }}
                    >
                      <b>Delivery: 1 - 30 minutes </b>
                    </p>
                    <p
                      className="text-uppercase text-left mt-2  text-dark"
                      style={{ fontSize: 12 }}
                    >
                      <b>Supports: MOBILE BANKING and USSD</b>
                    </p>
                    <p
                      className="text-uppercase text-left mt-2  text-danger"
                      style={{ fontSize: 12 }}
                    >
                      <b className="text-dark">Transaction Charge:</b>{" "}
                      <b>
                        (₦51 to ₦9,999 = ₦26.75 fee) (Above ₦9,999 = ₦70 fee)
                      </b>
                    </p>
                    {appData.master_settings.fund_status_auto * 1 === 0 && (
                      <div>
                        <div>
                          <div>
                            <p className="mb-1  mt-0 text-dark">
                              Transfer into the bank account below from your
                              registered accounts so we will know it is you and
                              automatically fund your wallet.
                            </p>

                            <p className="mb-1 ml-3 mt-0 text-danger">
                              If using <b>Kuda Bank accounts</b>, you must enter
                              your{" "}
                              <b>
                                REFERENCE CODE (X2X{user.data.reference_code})
                              </b>{" "}
                              in the Remark/Description/Narration part of the
                              transfer form on your Kuda App.
                            </p>

                            <p
                              className="text-left mt-2  text-dark"
                              style={{ fontSize: 13 }}
                            >
                              <b>
                                <i className="mdi mdi-bank-transfer"></i> BANK:
                                Kuda Bank
                              </b>
                            </p>

                            <p
                              className="text-left mt-2  text-dark"
                              style={{ fontSize: 13 }}
                            >
                              <b>
                                <i className="mdi mdi-wallet-outline"></i>{" "}
                                ACCOUNT NUMBER: 3000089167
                              </b>
                            </p>

                            <p
                              className="text-left mt-2  text-dark"
                              style={{ fontSize: 13 }}
                            >
                              <b>
                                <i className="mdi mdi-account-circle"></i>{" "}
                                ACCOUNT NAME: Systems Technologies Limited
                              </b>
                            </p>
                            <p className="text-danger">
                              ⚠️ Sending from an account that is not registered
                              may lead to delay in funding.
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(FundWallet);
