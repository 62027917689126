import React, { useContext, useState, useEffect } from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { Collapse, CardHeader } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  UserContext,
  ModalContext,
  FormContext,
  AppDataContext,
} from "../../contexts/Contexts";
import PasswordInput from "../../components/PasswordInput";
import ResponseModal from "../../components/ResponseModal";

function Kyc() {
  const { loaderDispatch } = useContext(ModalContext);
  const { formData, formDispatch } = useContext(FormContext);
  const { user } = useContext(UserContext);
  const { appData } = useContext(AppDataContext);

  const formOnChange = (e) => {
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  const ninIdentity = (toggle) => {
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: "ninIdentity", value: toggle },
    });
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: "bvnIdentity", value: false },
    });
  };
  const bvnIdentity = (toggle) => {
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: "ninIdentity", value: false },
    });
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: "bvnIdentity", value: toggle },
    });
  };

  useEffect(() => {
    if (!formData.ref) {
      formDispatch({
        type: "SET_FORM_DATA",
        data: { name: "ref", value: Math.random().toString(36).slice(2) },
      });

      document
        .getElementById("identifier")
        .addEventListener("input", function () {
          formDispatch({
            type: "SET_FORM_DATA",
            data: {
              name: "identifier",
              value: this.value.replace(/\D/g, ""),
            },
          });
        });

      bvnIdentity(true); //set bvn as default verification
      formDispatch({
        type: "SET_FORM_DATA",
        data: { name: "firstname", value: user.data.firstname },
      });

      formDispatch({
        type: "SET_FORM_DATA",
        data: { name: "othername", value: user.data.othername },
      });

      formDispatch({
        type: "SET_FORM_DATA",
        data: { name: "lastname", value: user.data.lastname },
      });

      window.scrollTo(0, 0);
    }
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "verifying identity...", isLoading: true },
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    user.data && myHeaders.append("Authorization", "Bearer " + user.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("identifier", String(formData.identifier));
    urlencoded.append("kyc_type", String(formData.ninIdentity ? "NIN" : "BVN"));
    urlencoded.append("phone_number", String(formData.phone_number));
    urlencoded.append("firstname", String(formData.firstname));
    urlencoded.append("othername", String(formData.othername));
    urlencoded.append("lastname", String(formData.lastname));
    urlencoded.append("ref", String(formData.ref));
    urlencoded.append("password", String(formData.password));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      //redirect: "follow",
    };
    fetch(localStorage.getItem("apiURL") + "kyc", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        console.log(data);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        formDispatch({
          type: "SET_FORM_DATA",
          data: { name: "ref", value: Math.random().toString(36).slice(2) },
        });
        if (data.status === "success") {
          formDispatch({
            type: "SET_FORM_DATA",
            data: {
              name: "responseModal",
              value: { isOpen: true, text: data.message },
            },
          });
        } else if (data.errors) {
          formDispatch({
            type: "SET_FORM_DATA",
            data: { name: "ref", value: Math.random().toString(36).slice(2) },
          });
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
        } else {
          formDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };
  document.title = "KYC- " + appData.business.name;
  return (
    <React.Fragment>
      <ResponseModal isOpen={true} shouldGoHome={true} />

      <Container fluid style={{ minHeight: "100vh" }}>
        <h5 className="font-bold text-sm mt-4 md:text-lg">
          Verify Your Identity{" "}
          <span className="inline-flex items-center justify-center w-6 h-6 me-2 text-sm font-semibold text-blue-800 bg-blue-100 rounded-full dark:bg-gray-700 dark:text-blue-400">
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
              style={{ height: "3vh", color: appData.business.primary_color }}
            >
              <path
                fill="currentColor"
                d="m18.774 8.245-.892-.893a1.5 1.5 0 0 1-.437-1.052V5.036a2.484 2.484 0 0 0-2.48-2.48H13.7a1.5 1.5 0 0 1-1.052-.438l-.893-.892a2.484 2.484 0 0 0-3.51 0l-.893.892a1.5 1.5 0 0 1-1.052.437H5.036a2.484 2.484 0 0 0-2.48 2.481V6.3a1.5 1.5 0 0 1-.438 1.052l-.892.893a2.484 2.484 0 0 0 0 3.51l.892.893a1.5 1.5 0 0 1 .437 1.052v1.264a2.484 2.484 0 0 0 2.481 2.481H6.3a1.5 1.5 0 0 1 1.052.437l.893.892a2.484 2.484 0 0 0 3.51 0l.893-.892a1.5 1.5 0 0 1 1.052-.437h1.264a2.484 2.484 0 0 0 2.481-2.48V13.7a1.5 1.5 0 0 1 .437-1.052l.892-.893a2.484 2.484 0 0 0 0-3.51Z"
              />
              <path
                fill="#fff"
                d="M8 13a1 1 0 0 1-.707-.293l-2-2a1 1 0 1 1 1.414-1.414l1.42 1.42 5.318-3.545a1 1 0 0 1 1.11 1.664l-6 4A1 1 0 0 1 8 13Z"
              />
            </svg>
          </span>
        </h5>
        <p className="  md:text-xl mt-2 text-dark">
          Government policies require us to verify your identity with the
          BVN/NIN linked to your phone number. This will ensure we know who you
          are and keep your account safe. Dial *565*0# to retrieve your BVN or
          *346# to retrieve your NIN
        </p>
        <div
          className="w-full p-2 mt-4 rounded-lg relative "
          style={{ backgroundColor: `${appData.business.primary_color}20` }}
        >
          <Row className="flex justify-between items-center">
            <Col className="inline-flex items-center ">
              <i
                className=" mdi-18px  mdi mdi-security mt-5"
                style={{ color: appData.business.primary_color }}
              ></i>
            </Col>
            <Col xs={11}>
              <p className="text-dark p-0 m-0">
                Our KYC verification is safe and secure. It won't give us access
                to your bank accounts.
              </p>
            </Col>
          </Row>
        </div>
        <div className="grid grid-cols-4 gap-6 ">
          {formData.error && <Alert color="danger">{formData.error}</Alert>}

          <AvForm
            className="form-horizontal m-t-30"
            onValidSubmit={handleSubmit}
          >
            <AvField
              name="firstname"
              label="Firstname"
              value={formData.firstname}
              onChange={(e) => {
                formOnChange(e);
              }}
              placeholder="Enter your name"
              type="text"
              required
            />
            <AvField
              name="othername"
              label="Othername/Middlename"
              value={formData.othername}
              onChange={(e) => {
                formOnChange(e);
              }}
              placeholder="Enter your name"
              type="text"
            />
            <AvField
              name="lastname"
              label="Lastname"
              value={formData.lastname}
              onChange={(e) => {
                formOnChange(e);
              }}
              placeholder="Enter your name"
              type="text"
              required
            />

            <AvField
              name="phone_number"
              label="Phone Number"
              value={user.data.phone_number}
              onChange={(e) => {
                // formOnChange(e);
              }}
              placeholder="Enter your phone number"
              disabled
              type="number"
              required
              validate={{
                pattern: {
                  value: "^[0-9]+$",
                  errorMessage: "Enter valid phone number",
                },
              }}
            />

            <p className=" font-bold text-lg  md:text-lg ">Identity Type</p>
            <Row>
              <Col xs="6">
                <div
                  className="rounded text-center p-2"
                  onClick={() => {
                    bvnIdentity(true);
                  }}
                  style={{
                    cursor: "pointer",
                    border:
                      "1px solid " +
                      (formData.bvnIdentity
                        ? appData.business.primary_color
                        : "#D0D0D0"),
                  }}
                >
                  <p
                    className="mb-0"
                    style={{
                      color:
                        formData.bvnIdentity && appData.business.primary_color,
                    }}
                  >
                    BVN
                  </p>
                </div>
              </Col>

              <Col xs="6">
                <div
                  className="rounded text-center p-2"
                  onClick={() => {
                    ninIdentity(true);
                  }}
                  style={{
                    cursor: "pointer",
                    border:
                      "1px solid " +
                      (formData.ninIdentity
                        ? appData.business.primary_color
                        : "#D0D0D0"),
                  }}
                >
                  <p
                    className="mb-0"
                    style={{
                      color:
                        formData.ninIdentity && appData.business.primary_color,
                    }}
                  >
                    NIN
                  </p>
                </div>
              </Col>
            </Row>

            <AvField
              name="identifier"
              id="identifier"
              label={
                formData.bvnIdentity
                  ? " Bank Verification Number (BVN)"
                  : "National Identification Number (NIN)"
              }
              value={formData.identifier}
              onChange={(e) => {
                formOnChange(e);
              }}
              type="number"
              required
            />
            <label className="text-base md:text-lg font-medium ">
              <i>
                {formData.bvnIdentity
                  ? user.data.kyc_verification_tries < 2
                    ? `Price: Free, ${
                        user.data.kyc_verification_tries + 1
                      } of 2 free verification attempts`
                    : "Price: ₦10"
                  : "Price: ₦140"}
              </i>
            </label>
            <PasswordInput />
            <Row className="form-group m-t-20">
              <Col sm="6" className="text-left mb-3">
                <Button
                  style={{ backgroundColor: appData.business.primary_color }}
                  className="w-md waves-effect waves-light"
                  type="submit"
                  onClick={() => handleSubmit()}
                >
                  Verify Identity
                </Button>
              </Col>
            </Row>
          </AvForm>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default Kyc;
