import React, { useContext, useEffect } from "react";
import { Container, Row, Col, Card, CardBody, Badge } from "reactstrap";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

import DataPrice from "./DataPrice";
import AirtimePrice from "./AirtimePrice";
import CablePrice from "./CablePrice";
import ElectricPrice from "./ElectricPrice";
import { formatCurrency, getOS } from "../helpers/utils";
import { UserContext, AppDataContext, FormContext } from "../contexts/Contexts";
import BankAccountCard from "./BankAccountCard";
import playstore from "../images/playstore.png";
import applestore from "../images/applestore.png";
import ResponseModal from "./ResponseModal";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Form,
  FormGroup,
} from "reactstrap";

const DashboardMenu0 = (props) => {
  const { user, userDispatch } = useContext(UserContext);
  const { appData } = useContext(AppDataContext);
  const { formData, formDispatch } = useContext(FormContext);
  document.title = appData.business.name;

  useEffect(() => {
    !formData.hasFetechedExtraHTML && fetchExtraHTML();
  }, []);

  const fetchExtraHTML = () => {
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: "hasFetechedExtraHTML", value: true },
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    // myHeaders.append("Authorization", "Bearer " + user.token);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      //redirect: "follow",
    };
    fetch(localStorage.getItem("apiURL") + "html", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        try {
          var data = JSON.parse(response);
          return;
        } catch (e) {}
        formDispatch({
          type: "SET_FORM_DATA",
          data: { name: "extraHTML", value: response },
        });
      });
  };

  return (
    <div fluid className=" font-montserrat ">
      <Modal
        isOpen={
          appData.settings &&
          appData.settings.notification &&
          appData.settings.notification !== user.notification
        }
        centered={true}
        toggle={() => {
          userDispatch({
            type: "UPDATE_USER_NOTIFICATION",
            action: appData.settings.notification,
          });
        }}
      >
        <h1 className="text-center pt-5">
          <i
            className="mdi mdi-email-alert   text-warning"
            style={{ fontSize: 120 }}
          ></i>
        </h1>
        <h6 className="text-center text-dark  mb-3">
          {appData.settings.notification}
        </h6>
        <Row className="justify-content-center">
          <Col xs="4">
            <div
              className="rounded text-center p-2 mb-3"
              onClick={() => {
                userDispatch({
                  type: "UPDATE_USER_NOTIFICATION",
                  action: appData.settings.notification,
                });
              }}
              style={{
                cursor: "pointer",
                backgroundColor: appData.business.primary_color,
              }}
            >
              <p className="mb-0 text-white">GOT IT</p>
            </div>
          </Col>
        </Row>
      </Modal>

      {appData.settings && appData.settings.notification && (
        <marquee>
          <i className="mdi mdi-18px mmdi mdi-alert-octagram mdi-spin  text-warning mdi "></i>
          <b style={{ color: appData.business.primary_color, fontSize: 14 }}>
            {" "}
            {appData.settings.notification}
          </b>
        </marquee>
      )}

      <div className="m-3">
        {user.data ? (
          <BankAccountCard />
        ) : (
          <Row>
            <Col md="10">
              <Card
                className="mini-stat  shadow text-white"
                style={{ backgroundColor: appData.business.primary_color }}
              >
                <CardBody>
                  <h1 className="mt-0 mb-0">
                    <i className="mdi mdi-cash-100"></i>
                  </h1>
                  <Link to="signin" className="text-white">
                    <h6>
                      <u>Sign In</u>, fund your wallet {"&"} enjoy more
                      discounts!
                    </h6>
                  </Link>
                  <Link to="signup" className="text-white">
                    <p className="small mb-0 mt-0 pt-0 pb-0">
                      Don't have an account? <u>Sign Up</u>
                    </p>
                  </Link>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </div>

      <div dangerouslySetInnerHTML={{ __html: formData.extraHTML }} />
      <AirtimePrice />
      <DataPrice />
      <CablePrice />
      <ElectricPrice />
    </div>
  );
};

export default withRouter(DashboardMenu0);
