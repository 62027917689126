import React, { useContext, useEffect, useState, useRef } from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  Badge,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledTooltip,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Form,
  FormGroup,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  UserContext,
  ModalContext,
  FormContext,
  AppDataContext,
} from "../../contexts/Contexts";
import { WalletPayment } from "../../components/PaymentSelector";
import { formatCurrency } from "../../helpers/utils";
import { useReactToPrint } from "react-to-print";

const Transactions = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { formData, formDispatch } = useContext(FormContext);
  const { user } = useContext(UserContext);
  const { appData } = useContext(AppDataContext);
  document.title = "Transactions-" + appData.business.name;

  const formOnChange = (e) => {
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  const toggleFilter = () => {
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: "showFilter", value: !formData.showFilter },
    });
  };

  const navigateToPage = (page) => {
    fetchTransactions(page);
  };

  const transactionSelect = (transaction) => {
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: "transaction", value: transaction },
    });

    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: "modalOpen", value: true },
    });
  };

  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    fetchTransactions(1);
  };

  useEffect(() => {
    if (!formData.paymentLoaded) {
      fetchTransactions(1);
      formDispatch({
        type: "SET_FORM_DATA",
        data: { name: "paymentLoaded", value: true },
      });
    }
  }, []);

  const printRef = useRef();

  const handlePrint = useReactToPrint(
    window.ReactNativeWebView
      ? {
          content: () => printRef.current,
          print: (printIframe) => {
            formDispatch({
              type: "SET_FORM_DATA",
              data: { name: "printing", value: true },
            });
            const serializer = new XMLSerializer();
            const content = serializer.serializeToString(
              printIframe.contentDocument
            );
            window.ReactNativeWebView.postMessage(
              JSON.stringify({ command: "print", data: content })
            );
            setTimeout(function () {
              formDispatch({
                type: "SET_FORM_DATA",
                data: { name: "printing", value: false },
              });
            }, 5000);
          },
        }
      : {
          content: () => printRef.current,
        }
  );

  const getDataPlanName = (planId) => {
    var planName = "";
    appData.data_plans.MTN.map((plan) => {
      if (plan.id == planId) planName = plan.name;
    });
    if (planName === "")
      appData.data_plans.GLO.map((plan) => {
        if (plan.id == planId) planName = plan.name;
      });
    if (planName === "")
      appData.data_plans.AIRTEL.map((plan) => {
        if (plan.id == planId) planName = plan.name;
      });
    if (planName === "")
      appData.data_plans.ETISALAT.map((plan) => {
        if (plan.id == planId) planName = plan.name;
      });

    return planName;
  };

  const getElectricPlanName = (planId) => {
    var planName = "";
    appData.electric_plans.IKEDC.map((plan) => {
      if (plan.id == planId) planName = plan.name;
    });
    if (planName === "")
      appData.electric_plans.PHED.map((plan) => {
        if (plan.id == planId) planName = plan.name;
      });
    if (planName === "")
      appData.electric_plans.KEDCO.map((plan) => {
        if (plan.id == planId) planName = plan.name;
      });
    if (planName === "")
      appData.electric_plans.KAEDCO.map((plan) => {
        if (plan.id == planId) planName = plan.name;
      });
    if (planName === "")
      appData.electric_plans.JED.map((plan) => {
        if (plan.id == planId) planName = plan.name;
      });
    if (planName === "")
      appData.electric_plans.IBEDC.map((plan) => {
        if (plan.id == planId) planName = plan.name;
      });
    if (planName === "")
      appData.electric_plans.EKEDC.map((plan) => {
        if (plan.id == planId) planName = plan.name;
      });
    if (planName === "")
      appData.electric_plans.AEDC.map((plan) => {
        if (plan.id == planId) planName = plan.name;
      });
    if (planName === "")
      appData.electric_plans.EEDC.map((plan) => {
        if (plan.id == planId) planName = plan.name;
      });
    if (planName === "")
      appData.electric_plans.BEDC.map((plan) => {
        if (plan.id == planId) planName = plan.name;
      });

    return planName;
  };

  const getCablePlanName = (planId) => {
    var planName = "";
    if (planName === "")
      appData.cable_plans.STARTIMES.map((plan) => {
        if (plan.id == planId) planName = plan.name;
      });
    if (planName === "")
      appData.cable_plans.DSTV.map((plan) => {
        if (plan.id == planId) planName = plan.name;
      });
    if (planName === "")
      appData.cable_plans.GOTV.map((plan) => {
        if (plan.id == planId) planName = plan.name;
      });
    return planName;
  };

  const getPlanTv = (planId) => {
    var tv = "";
    appData.cable_plans.STARTIMES.map((plan) => {
      if (plan.id == planId) tv = "STARTIMES";
    });
    appData.cable_plans.DSTV.map((plan) => {
      if (plan.id == planId) tv = "DSTV";
    });
    appData.cable_plans.GOTV.map((plan) => {
      if (plan.id == planId) tv = "GOTV";
    });

    return tv;
  };

  const getPlanNetwork = (planId) => {
    var network = "";
    appData.data_plans.MTN.map((plan) => {
      if (plan.id == planId) network = "MTN";
    });
    appData.data_plans.GLO.map((plan) => {
      if (plan.id == planId) network = "GLO";
    });
    appData.data_plans.AIRTEL.map((plan) => {
      if (plan.id == planId) network = "AIRTEL";
    });
    appData.data_plans.ETISALAT.map((plan) => {
      if (plan.id == planId) network = "ETISALAT";
    });
    return network;
  };

  const getPlanDisco = (planId) => {
    var disco = "";
    appData.electric_plans.IKEDC.map((plan) => {
      if (plan.id === planId) disco = "IKEDC";
    });
    appData.electric_plans.PHED.map((plan) => {
      if (plan.id == planId) disco = "PHED";
    });
    appData.electric_plans.KEDCO.map((plan) => {
      if (plan.id == planId) disco = "KEDCO";
    });
    appData.electric_plans.KAEDCO.map((plan) => {
      if (plan.id == planId) disco = "KAEDCO";
    });
    appData.electric_plans.JED.map((plan) => {
      if (plan.id == planId) disco = "JED";
    });
    appData.electric_plans.IBEDC.map((plan) => {
      if (plan.id == planId) disco = "IBEDC";
    });
    appData.electric_plans.EKEDC.map((plan) => {
      if (plan.id == planId) disco = "EKEDC";
    });
    appData.electric_plans.AEDC.map((plan) => {
      if (plan.id == planId) disco = "AEDC";
    });
    appData.electric_plans.EEDC.map((plan) => {
      if (plan.id == planId) disco = "EEDC";
    });
    appData.electric_plans.BEDC.map((plan) => {
      if (plan.id == planId) disco = "BEDC";
    });
    return disco;
  };

  const transactionTypes = [
    "ATM Wallet Funding", //0
    "Direct Bank Funding", //1
    "Purchase Reversal", //2
    "Transfer from User", //3
    "DataNow Admin Withdrawal", //4
    "Data Purchase", //5
    "Airtime Purchase", //6
    "Transfer to user", //7
    "Cable TV Subscription", //8
    "Electricity Bill Payment", //9
    "Commission Payment", //10
    "Auto Agent Funding", //11
    "Transfer from Admin", //12
    "Referral Bonus Payment", //13
  ];

  const transactionStatus = [
    "Delivered",
    "Paying",
    "Queued ",
    "Queued ",
    "Processing",
    "Reversed",
    "Failed",
    "Cancelled",
  ];

  const Transaction = (props) => {
    return (
      <React.Fragment>
        <hr className="mt-0" />
        <Row
          className="text-truncate "
          style={{ fontSize: "12px", cursor: "pointer" }}
          onClick={() => {
            transactionSelect(props.transaction);
          }}
        >
          <Col
            xs="3"
            className="text-center justify-content-center mb-2 align-self-center"
          >
            <React.Fragment>
              {props.transaction.status * 1 === 0 && (
                <i className="mdi mdi-check mdi-24px text-success"></i>
              )}

              {(props.transaction.status * 1 === 1 ||
                props.transaction.status * 1 === 2 ||
                props.transaction.status * 1 === 3 ||
                props.transaction.status * 1 === 4) && (
                <i className="mdi mdi-autorenew mdi-24px  active mdi-spin text-warning"></i>
              )}
              {props.transaction.status * 1 === 5 && (
                <i className="mdi mdi-wallet   mdi-24px text-primary"></i>
              )}
              {(props.transaction.status * 1 === 6 ||
                props.transaction.status * 1 === 7) && (
                <i className="mdi mdi-alert-circle-outline  mdi-24px text-danger"></i>
              )}
              <p className="mb-0 mt-0   ml-0 pl-0 ">
                {transactionStatus[props.transaction.status]}
              </p>
            </React.Fragment>
          </Col>
          <Col
            md="4"
            xs="4"
            className="text-left justify-content-center align-self-center"
          >
            <p className="mt-0 mb-0 text-truncate">
              {transactionTypes[props.transaction.transaction_type]}
            </p>
            <p className="mb-0 mt-2 text-truncate">
              {props.transaction.description}
            </p>
            <p className="mb-0 mt-2 text-truncate">
              {props.transaction.additional_description
                ? props.transaction.additional_description
                : props.transaction.device_number}{" "}
            </p>
          </Col>
          <Col
            xs="5"
            className="text-left justify-content-center align-self-center"
          >
            <p
              className={
                props.transaction.transaction_type > 3
                  ? props.transaction.transaction_type < 10
                    ? "text-danger mt-0 mb-0"
                    : "text-success mt-0 mb-0"
                  : "text-success mt-0 mb-0"
              }
            >
              ₦{formatCurrency(props.transaction.amount)} /{" "}
              {props.transaction.payment_method * 1 === 0 ? "WALLET" : "ATM"}
            </p>
            <p className="mb-0 mt-2">
              ₦{formatCurrency(props.transaction.amount_before)}/₦
              {formatCurrency(props.transaction.amount_after)}
            </p>
            <p className="mb-0 mt-2">{props.transaction.created_at}</p>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  const fetchTransactions = (page) => {
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Retrieving transactions...", isLoading: true },
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + user.token);
    var parameter = "";
    parameter += formData.search ? "&search=" + formData.search : "";
    parameter += formData.transactionType
      ? "&transaction_type=" + formData.transactionType
      : "";
    parameter += formData.pageSize ? "&size=" + formData.pageSize : "";
    parameter += formData.dateFrom ? "&date_from=" + formData.dateFrom : "";
    parameter += formData.dateTo ? "&date_to=" + formData.dateTo : "";
    parameter += "&page=" + page;
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      //redirect: "follow",
    };
    fetch(
      localStorage.getItem("apiURL") + "transactions?" + parameter,
      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          const objectValues = Object.values(data.data.data);
          console.log(objectValues);
          formDispatch({
            type: "SET_FORM_DATA",
            data: { name: "transactions", value: objectValues },
          });
          formDispatch({
            type: "SET_FORM_DATA",
            data: { name: "current_page", value: data.data.current_page },
          });
          formDispatch({
            type: "SET_FORM_DATA",
            data: { name: "per_page", value: data.data.per_page },
          });
          formDispatch({
            type: "SET_FORM_DATA",
            data: { name: "last_page", value: data.data.last_page },
          });
          formDispatch({
            type: "SET_FORM_DATA",
            data: { name: "total", value: data.data.total },
          });
        } else if (
          data.message === "Token Expired" ||
          data.message === "User Not Found"
        ) {
          props.history.push("/signout");
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
        } else {
          formDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  return (
    <React.Fragment>
      <Container fluid style={{ minHeight: "100vh" }}>
        <br />
        <Row>
          <Col xl="12" ml="12" sm="12">
            {/* <Card className="mini-stat text-dark shadow">
              <CardBody> */}
            <div className="mb-2">
              <p
                className="text-uppercase text-left mb-0 "
                style={{ color: appData.business.primary_color }}
              >
                <b>Transactions</b>
              </p>
              <hr style={{ borderColor: appData.business.primary_color }} />
              <label>Select date a range to view data older than 10 days</label>
            </div>
            <Link
              onClick={(e) => {
                toggleFilter(e);
                e.preventDefault();
              }}
              className="mb-0 pb-0"
            >
              {!formData.showFilter ? (
                <p className="text-uppercase text-left mb-0 small">
                  <i className="mdi mdi-filter "></i>Show Filters{" "}
                </p>
              ) : (
                <p className="text-uppercase text-left mb-0 small">
                  <i className="mdi mdi-filter-remove "></i> Hide Filters
                </p>
              )}
            </Link>
            <AvForm className="mt-2" role="search" onValidSubmit={handleSubmit}>
              <Row>
                {formData.showFilter && (
                  <React.Fragment>
                    <Col md="3" xs="6" className="form-group mb-2">
                      <select
                        className="form-control"
                        value={formData.transactionType}
                        name="transactionType"
                        onChange={formOnChange}
                      >
                        <option value="">All Transactions</option>
                        {transactionTypes.map((transaction, index) => {
                          return <option value={index}>{transaction}</option>;
                        })}
                      </select>
                    </Col>
                    <Col md="3" xs="6" className="form-group mb-2">
                      <select
                        className="form-control"
                        value={formData.pageSize}
                        name="pageSize"
                        onChange={formOnChange}
                      >
                        <option value="">15 per page</option>
                        <option value="30">30 per page</option>
                        <option value="50">50 per page</option>
                      </select>
                    </Col>
                    <Col md="2" xs="5" className="form-group mb-2">
                      <input
                        type="date"
                        className="form-control"
                        name="dateFrom"
                        value={formData.dateFrom}
                        onChange={formOnChange}
                      />
                    </Col>
                    <label className="mt-2">To</label>
                    <Col md="2" xs="5" className="form-group mb-2">
                      <input
                        type="date"
                        className="form-control"
                        name="dateTo"
                        value={formData.dateTo}
                        onChange={formOnChange}
                      />
                    </Col>
                  </React.Fragment>
                )}

                <div className=" mb-0 ml-3 app-search ">
                  <input
                    type="text"
                    className="form-control bg-light shadow"
                    placeholder="Search.."
                    name="search"
                    value={formData.search}
                    onChange={formOnChange}
                  />
                  <button
                    style={{
                      color: appData.business.primary_color,
                      marginTop: -28,
                    }}
                    type="submit"
                  >
                    <i className="mdi mdi-magnify  mdi-24px"></i>
                  </button>
                </div>
              </Row>
            </AvForm>

            {formData.transactions && (
              <React.Fragment>
                <p className="text-uppercase text-left mb-0 small">
                  Showing (
                  {formData.per_page * formData.current_page -
                    formData.per_page +
                    1}{" "}
                  to{" "}
                  {formData.current_page * formData.per_page -
                    formData.per_page +
                    formData.transactions.length}{" "}
                  ) of {formData.total} Transactions
                </p>

                {formData.transactions.map((transaction) => {
                  return (
                    <Transaction
                      key={transaction.id}
                      transaction={transaction}
                    />
                  );
                })}
              </React.Fragment>
            )}

            {formData.transactions && (
              <React.Fragment>
                <hr className="mt-0" />
                <p className="text-uppercase text-left mb-0 small">
                  Showing page {formData.current_page} of {formData.last_page}
                </p>
                <Row className="ml-1 mt-2 ">
                  <Pagination className="pagination-sm">
                    <PaginationItem
                      disabled={formData.current_page > 1 ? false : true}
                    >
                      <PaginationLink
                        first
                        onClick={() => {
                          navigateToPage(formData.current_page - 1);
                        }}
                      />
                    </PaginationItem>
                    {formData.current_page - 5 > 0 && (
                      <PaginationItem
                        onClick={() => {
                          navigateToPage(formData.current_page - 5);
                        }}
                      >
                        <PaginationLink>
                          {formData.current_page - 5}
                        </PaginationLink>
                      </PaginationItem>
                    )}

                    <PaginationItem active>
                      <PaginationLink>{formData.current_page}</PaginationLink>
                    </PaginationItem>

                    {formData.last_page >= formData.current_page + 5 && (
                      <PaginationItem
                        onClick={() => {
                          navigateToPage(formData.current_page + 5);
                        }}
                      >
                        <PaginationLink>
                          {formData.current_page + 5}
                        </PaginationLink>
                      </PaginationItem>
                    )}

                    <PaginationItem
                      disabled={
                        formData.current_page === formData.last_page
                          ? true
                          : false
                      }
                    >
                      <PaginationLink
                        onClick={() => {
                          navigateToPage(formData.current_page + 1);
                        }}
                        last
                      />
                    </PaginationItem>
                  </Pagination>
                </Row>
              </React.Fragment>
            )}
            {/* 
              </CardBody>
            </Card> */}
          </Col>
        </Row>
      </Container>

      {formData.transaction && (
        <Modal isOpen={formData.modalOpen} centered={true}>
          {/* toggle={modalOpen} */}
          <ModalBody>
            <div
              className="ml-3 mt-3 text-dark "
              ref={printRef}
              style={{ maxWidth: 400 }}
            >
              <button
                type="button"
                className="close"
                onClick={() => {
                  formDispatch({
                    type: "SET_FORM_DATA",
                    data: { name: "modalOpen", value: false },
                  });
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p
                className="text-uppercase text-left mb-0 "
                style={{ color: appData.business.primary_color }}
              >
                <span>
                  <img
                    src={appData.business.website + "/logo.png"}
                    alt="logo"
                    height="36"
                  />
                </span>
                <b>{appData.business.name} Transaction details</b>
              </p>

              <hr style={{ borderColor: appData.business.primary_color }} />

              <Row>
                <Col xs="6">
                  <b>TRANSACTION TYPE</b>
                </Col>
                <Col xs="6">
                  <p className="m-0 p-0">
                    {transactionTypes[formData.transaction.transaction_type]}
                  </p>
                </Col>
              </Row>

              {formData.transaction.transaction_type * 1 === 5 ||
              formData.transaction.transaction_type * 1 === 6 ||
              formData.transaction.transaction_type * 1 === 8 ||
              formData.transaction.transaction_type * 1 === 9 ? (
                <div>
                  <Row className="mt-2 mb-3" style={{ fontSize: 14 }}>
                    <Col xs="6">
                      <b>
                        {formData.transaction.transaction_type * 1 === 5 &&
                          "DATA PLAN"}
                        {formData.transaction.transaction_type * 1 === 6 &&
                          "AIRTIME VALUE"}
                        {formData.transaction.transaction_type * 1 === 8 &&
                          "CABLE PLAN"}
                        {formData.transaction.transaction_type * 1 === 9 &&
                          "DISCO"}
                      </b>
                    </Col>
                    <Col xs="6">
                      <p className="m-0 p-0">
                        {formData.transaction.transaction_type * 1 === 5 &&
                          getPlanNetwork(formData.transaction.plan_id)}
                        {formData.transaction.transaction_type * 1 === 6 &&
                          JSON.parse(formData.transaction.metadata)["network"]}
                        {formData.transaction.transaction_type * 1 === 8 &&
                          getPlanTv(formData.transaction.plan_id)}
                        {formData.transaction.transaction_type * 1 === 9 &&
                          getPlanDisco(formData.transaction.plan_id)}
                        &nbsp;
                        {formData.transaction.transaction_type * 1 === 5 &&
                          getDataPlanName(formData.transaction.plan_id)}
                        {formData.transaction.transaction_type * 1 === 6 &&
                          "₦" +
                            formatCurrency(
                              JSON.parse(formData.transaction.metadata)[
                                "airtime_value"
                              ]
                            )}
                        {formData.transaction.transaction_type * 1 === 8 &&
                          getCablePlanName(formData.transaction.plan_id)}
                        {formData.transaction.transaction_type * 1 === 9 &&
                          getElectricPlanName(formData.transaction.plan_id)}
                      </p>
                    </Col>
                  </Row>

                  <Row className="mt-2 mb-3" style={{ fontSize: 14 }}>
                    <Col xs="6">
                      <b>
                        {formData.transaction.transaction_type * 1 === 5 &&
                          "PHONE NUMBER"}
                        {formData.transaction.transaction_type * 1 === 6 &&
                          "PHONE NUMBER"}
                        {formData.transaction.transaction_type * 1 === 8 &&
                          "SMARTCARD NUMBER"}
                        {formData.transaction.transaction_type * 1 === 9 &&
                          "METER NUMBER"}
                      </b>
                    </Col>
                    <Col xs="6">
                      <p className="m-0 p-0">
                        {formData.transaction.device_number}
                      </p>
                    </Col>
                  </Row>
                  {formData.transaction.transaction_type * 1 === 9 && (
                    <React.Fragment>
                      <Row className="mt-2 mb-3" style={{ fontSize: 14 }}>
                        <Col xs="6">
                          <b>METER TYPE</b>
                        </Col>
                        <Col xs="6">
                          <p className="m-0 p-0">
                            {getElectricPlanName(formData.transaction.plan_id)}
                          </p>
                        </Col>
                      </Row>
                      <Row className="mt-2 mb-3" style={{ fontSize: 14 }}>
                        <Col xs="6">
                          <b>CUSTOMER NAME</b>
                        </Col>
                        <Col xs="6">
                          <p className="m-0 p-0">
                            {
                              JSON.parse(formData.transaction.metadata)[
                                "customer_name"
                              ]
                            }
                          </p>
                        </Col>
                      </Row>
                      <Row className="mt-2 mb-3" style={{ fontSize: 14 }}>
                        <Col xs="6">
                          <b>CUSTOMER ADDRESS</b>
                        </Col>
                        <Col xs="6">
                          <p className="m-0 p-0">
                            {
                              JSON.parse(formData.transaction.metadata)[
                                "customer_address"
                              ]
                            }
                          </p>
                        </Col>
                      </Row>
                      <Row className="mt-2 mb-3" style={{ fontSize: 14 }}>
                        <Col xs="6">
                          <b>TOKEN</b>
                        </Col>
                        <Col xs="6">
                          <p className="m-0 p-0 text-wrap">
                            <b style={{ wordBreak: "break-all" }}>
                              {
                                JSON.parse(formData.transaction.metadata)[
                                  "token"
                                ]
                              }
                            </b>
                          </p>
                        </Col>
                      </Row>
                      <Row className="mt-2 mb-3" style={{ fontSize: 14 }}>
                        <Col xs="6">
                          <b>UNITS</b>
                        </Col>
                        <Col xs="6">
                          <p className="m-0 p-0">
                            {JSON.parse(formData.transaction.metadata)["units"]}{" "}
                          </p>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}

                  <Row className="mt-2 mb-3" style={{ fontSize: 14 }}>
                    <Col xs="6">
                      <b>PAYMENT METHOD</b>
                    </Col>
                    <Col xs="6">
                      <p className="m-0 p-0">
                        {formData.transaction.payment_method === "0"
                          ? "ATM"
                          : "WALLET"}
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-3" style={{ fontSize: 14 }}>
                    <Col xs="6">
                      <b>AMOUNT</b>
                    </Col>
                    <Col xs="6">
                      <p className="m-0 p-0">
                        ₦
                        {formatCurrency(
                          formData.transaction.transaction_type * 1 === 9
                            ? JSON.parse(formData.transaction.metadata)[
                                "token_value"
                              ]
                            : formData.transaction.amount
                        )}
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-3" style={{ fontSize: 14 }}>
                    <Col xs="6">
                      <b>DATE</b>
                    </Col>
                    <Col xs="6">
                      <p className="m-0 p-0">
                        {formData.transaction.created_at}
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-3" style={{ fontSize: 14 }}>
                    <Col xs="6">
                      <b>TRANSACTION ID</b>
                    </Col>
                    <Col xs="6">
                      <p className="m-0 p-0">{formData.transaction.id}</p>
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-3" style={{ fontSize: 14 }}>
                    <Col xs="6">
                      <b>STATUS</b>
                    </Col>
                    <Col xs="6">
                      <p className="m-0 p-0">
                        {" "}
                        {transactionStatus[formData.transaction.status]}
                      </p>
                    </Col>
                  </Row>
                </div>
              ) : (
                <div>
                  <Row className="mt-2 mb-3">
                    <Col xs="6">
                      <b>AMOUNT</b>
                    </Col>
                    <Col
                      xs="6"
                      className={
                        formData.transaction.transaction_type > 3
                          ? "text-danger"
                          : "text-success"
                      }
                    >
                      <p className="m-0 p-0">
                        ₦{formatCurrency(formData.transaction.amount)}
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-3">
                    <Col xs="6">
                      <b>PAYER</b>
                    </Col>
                    <Col xs="6">
                      <p className="m-0 p-0">{formData.transaction.payer}</p>
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-3">
                    <Col xs="6">
                      <b>RECEIVER</b>
                    </Col>
                    <Col xs="6">
                      <p className="m-0 p-0">Data Purchase</p>
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-3">
                    <Col xs="6">
                      <b>INITIAL BALANCE</b>
                    </Col>
                    <Col xs="6">
                      <p className="m-0 p-0">
                        ₦{formatCurrency(formData.transaction.amount_before)}
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-3">
                    <Col xs="6">
                      <b>BALANCE AFTER</b>
                    </Col>
                    <Col xs="6">
                      <p className="m-0 p-0">
                        ₦{formatCurrency(formData.transaction.amount_after)}
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-3">
                    <Col xs="6">
                      <b>DATE</b>
                    </Col>
                    <Col xs="6">
                      <p className="m-0 p-0">
                        {formData.transaction.created_at}
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-3">
                    <Col xs="6">
                      <b>STATUS</b>
                    </Col>
                    <Col xs="6">
                      <p className="m-0 p-0">
                        {" "}
                        {formData.transaction.status === 0
                          ? "Successful"
                          : "Pending"}
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-3">
                    <Col xs="12">
                      <b>DESCRIPTION</b>
                    </Col>
                    <Col xs="12">
                      <p className="m-0 p-0">
                        {formData.transaction.description}
                      </p>
                    </Col>
                  </Row>
                </div>
              )}
            </div>
            <p className="text-center m-0">
              <button
                className="m-2 btn text-white "
                onClick={handlePrint}
                style={{ background: appData.business.primary_color }}
              >
                {formData.printing ? (
                  <div className="flex items-center justify-center">
                    <i className="mdi mdi-loading   mdi-spin  mdi-18px  " />
                  </div>
                ) : window.ReactNativeWebView ? (
                  "Share Receipt"
                ) : (
                  "Print"
                )}
              </button>
            </p>
          </ModalBody>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default withRouter(Transactions);
