import React, { useContext, useEffect } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { withRouter } from "react-router-dom";

import { formatCurrency, getOS } from "../../helpers/utils";
import {
  UserContext,
  AppDataContext,
  FormContext,
} from "../../contexts/Contexts";
import BankAccountCard from "../../components/BankAccountCard";
import DashboardMenu0 from "../../components/DashboardMenu0";
import DashboardMenu1 from "../../components/DashboardMenu1";
import playstore from "../../images/playstore.png";
import applestore from "../../images/applestore.png";
import ResponseModal from "../../components/ResponseModal";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Form,
  FormGroup,
} from "reactstrap";

const Dashboard = (props) => {
  const { user, userDispatch } = useContext(UserContext);
  const { appData } = useContext(AppDataContext);
  const { formData, formDispatch } = useContext(FormContext);
  document.title = appData.business.name;

  return (
    <Container className="m-0 p-0">
      <br />
      <Modal
        isOpen={
          appData.settings &&
          appData.settings.notification &&
          appData.settings.notification !== user.notification
        }
        centered={true}
        toggle={() => {
          userDispatch({
            type: "UPDATE_USER_NOTIFICATION",
            action: appData.settings.notification,
          });
        }}
      >
        <h1 className="text-center pt-5">
          <i
            className="mdi mdi-email-alert   text-warning"
            style={{ fontSize: 120 }}
          ></i>
        </h1>
        <h6 className="text-center text-dark  pb-5">
          {appData.settings.notification}
        </h6>
      </Modal>

      {window.localStorage.getItem("isMobileApp") == null && (
        <React.Fragment>
          <div className="ml-3">
            {getOS() === "ANDROID" && appData.settings.playstore_link && (
              <a target="_" href={appData.settings.playstore_link}>
                <p>
                  <i className="mdi mdi-download " />
                  Download <b>{appData.business.name}</b>
                  <img src={playstore} height="46" alt="logo"></img>
                </p>
              </a>
            )}
            {getOS() === "IOS" && appData.settings.applestore_link && (
              <a target="_" href={appData.settings.applestore_link}>
                <p>
                  <i className="mdi mdi-download" />
                  Download <b>{appData.business.name}</b>
                  <img src={applestore} height="46" alt="logo"></img>
                </p>
              </a>
            )}
          </div>
        </React.Fragment>
      )}

      {appData.business.template * 1 === 0 ? (
        <DashboardMenu0 />
      ) : (
        <DashboardMenu1 />
      )}
    </Container>
  );
};

export default withRouter(Dashboard);
