import React, { useContext, useState, useEffect } from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Form, FormGroup } from 'reactstrap'; import loader from '../images/loader.gif';
import { ModalContext, AppDataContext, FormContext } from '../contexts/Contexts';
import loaderImg from '../images/loader.gif'
import { Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import { formatCurrency } from "../helpers/utils";

const ConfirmationModal = (props) => {
  const history = useHistory();

  const { formData, formDispatch } = useContext(FormContext);
  const { appData } = useContext(AppDataContext);

  const clearModal = () => {
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: 'confirmationModal', value: { isOpen: false, transactionType: '', transactionDescription:'',
    transactionReceiver: '', transactionAmount:'', transactionImage:'' } },
    }); 
  }
  useEffect(() => {

    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: 'confirmationModal', value: { isOpen: false, type: '', description:'',
    receiver: '', amount:'', image:'' } },
    });

  }, [])
  return (
    <div>
      {formData.confirmationModal &&
        <Modal
          isOpen={formData.confirmationModal.isOpen} centered={true} >
        <div className="p-3 text-dark">
          <p className="text-center"  style={{ color: appData.business.primary_color }}>REVIEW</p>
          <p className="text-center "><img  src={formData.confirmationModal.image} alt="logo" height="36"></img></p>
          <h6 className="text-center text-uppercase small">{formData.confirmationModal.type}</h6>
          <p >Item: {formData.confirmationModal.description}</p>
          <p >Receiver:  {formData.confirmationModal.receiver}</p>
          <p >Amount: ₦{formatCurrency(formData.confirmationModal.amount)}</p>

          <Row className="pb-3">
            <Col xs="6">
              <div className="rounded text-center p-2" onClick={() => { clearModal() }}
                style={{ cursor: 'pointer', border: '1px solid ' + appData.business.primary_color }}
              >
                <p className="mb-0"
                  style={{ color: appData.business.primary_color }}>CANCEL</p>
              </div>
            </Col>

            <Col xs="6">
              <div className="rounded text-center p-2" onClick={props.onConfirmationClicked}
                style={{ cursor: 'pointer', backgroundColor: appData.business.primary_color }}
              >
                <p className="mb-0 text-white">PAY</p>
              </div>
            </Col>
          </Row>
          </div>
        </Modal>
      }

    </div>
  );
}

export default ConfirmationModal