import React, {useContext} from "react";
import { withRouter } from "react-router-dom";

import DataPrice from "../../components/DataPrice";
import { AppDataContext } from "../../contexts/Contexts";

const DataPricing = () => {
  const {appData}= useContext(AppDataContext)
  document.title='Purchase Data-'+ appData.business.name;

  return (
    <React.Fragment>
      <br/>
      <DataPrice></DataPrice>
    </React.Fragment>
  );
};

export default withRouter(DataPricing);
