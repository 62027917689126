import React, { useState, useContext } from "react";
import { Alert, Button, Col, Row, Card } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  UserContext,
  ModalContext,
  FormContext,
  AppDataContext,
} from "../../contexts/Contexts";
import PasswordInput from "../../components/PasswordInput";

const ResetPassword = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { user, userDispatch } = useContext(UserContext);
  const { formData, formDispatch } = useContext(FormContext);
  const { appData } = useContext(AppDataContext);

  document.title = "Reset Password-" + appData.business.name;

  user.data && props.history.push("/");

  const formOnChange = (e) => {
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };
  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    if (formData.password != formData.password1) {
      formDispatch({
        type: "SET_ERROR",
        data: "passwords do not match",
      });
      return;
    }

    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "submitting request...", isLoading: true },
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    var urlencoded = new URLSearchParams();
    urlencoded.append("identifier", String(formData.identifier));
    urlencoded.append("password", String(formData.password));
    urlencoded.append("token", String(formData.token));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      //redirect: "follow",
    };
    fetch(localStorage.getItem("apiURL") + "reset_password", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        if (data.status === "success") {
          userDispatch({ type: "STORE_USER_DATA", user: data });
          props.history.push("/");
        } else {
          formDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>

      <div className="wrapper-page">
        <Card className="overflow-hidden account-card mx-3 shadow">
          <div
            className=" p-4 text-white text-center position-relative"
            style={{ backgroundColor: appData.business.primary_color }}
          >
            <h4 className="font-20 m-b-5 mb-4">{appData.business.name}</h4>
            <Link to="/" className="logo logo-admin">
              <img
                src={appData.business.website + "/logo.png"}
                height="36"
                alt="logo"
              />
            </Link>
          </div>
          <div className="account-card-content">
            {formData.success && (
              <Alert color="success">{formData.success}</Alert>
            )}
            {formData.error && <Alert color="danger">{formData.error}</Alert>}
            <AvForm
              className="form-horizontal m-t-30"
              onValidSubmit={handleSubmit}
            >
              <AvField
                name="identifier"
                label="Email or Phone Number"
                value={formData.identifier}
                onChange={(e) => {
                  formOnChange(e);
                }}
                placeholder="Enter Email or Phone Number"
                type="text"
                required
              />

              <AvField
                name="token"
                label="Token"
                value={formData.token}
                onChange={(e) => {
                  formOnChange(e);
                }}
                placeholder="Token"
                type="number"
                required
                validate={{
                  pattern: {
                    value: "^[0-9]+$",
                    errorMessage: "Enter a valid token",
                  },
                }}
              />
              <PasswordInput />

              <input
                className="border border-gray p-2 w-100 "
                name="password1"
                type="password"
                onChange={(e) => {
                  formOnChange(e);
                }}
                value={formData.password1}
                placeholder="Re-type Password"
                errorMessage="Re enter password"
                required
              />
              <Row className="form-group m-t-20">
                <Col sm="6"></Col>
                <Col sm="6" className="text-right">
                  <Button
                    style={{ backgroundColor: appData.business.primary_color }}
                    className="w-md waves-effect waves-light"
                    type="submit"
                  >
                    Reset Password
                  </Button>
                </Col>
              </Row>
            </AvForm>
            <div className="m-t-40 text-center">
              <p>
                Remember your password? &nbsp;
                <Link
                  to="/signin"
                  className="font-500 "
                  style={{ color: appData.business.primary_color }}
                >
                  SignIn now
                </Link>
              </p>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ResetPassword);
