import React, { useContext } from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  UserContext,
  ModalContext,
  FormContext,
  AppDataContext,
} from "../../contexts/Contexts";
import { formatCurrency, getOS } from "../../helpers/utils";
import BankAccountCard from "../../components/BankAccountCard";
import ResponseModal from "../../components/ResponseModal";

const FundWallet = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { formData, formDispatch } = useContext(FormContext);
  const { user, userDispatch } = useContext(UserContext);
  const { appData } = useContext(AppDataContext);
  document.title = "Fund Wallet with Bank-" + appData.business.name;

  const getAdditionalBanks = (e) => {
    e.persist();
    e.preventDefault();
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Creating additional accounts...", isLoading: true },
    });
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: "confirmationModal", value: { isOpen: false, text: "" } },
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    user.data && myHeaders.append("Authorization", "Bearer " + user.token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      // body: urlencoded,
      //redirect: "follow",
    };
    fetch(localStorage.getItem("apiURL") + "all_monnify_banks", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        if (data.status === "success" && formData.atmPayment) {
          window.location = data.data.payment_url;
          return;
        }
        // loaderDispatch({
        //   type: "SET_LOADER",
        //   data: { text: "", isLoading: false },
        // });
        console.log(data);
        if (data.status === "success") {
          refreshUser();
        } else if (
          data.message === "Token Expired" ||
          data.message === "User Not Found"
        ) {
          props.history.push("/signout");
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
          formDispatch({
            type: "SET_FORM_DATA",
            data: { name: "ref", value: Math.random().toString(36).slice(2) },
          });
        } else {
          formDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
          formDispatch({
            type: "SET_FORM_DATA",
            data: { name: "ref", value: Math.random().toString(36).slice(2) },
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  const refreshUser = () => {
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Updating account info...", isLoading: true },
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + user.token);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      //redirect: "follow",
    };
    fetch(
      localStorage
        .getItem("apiURL")
        .substr(0, localStorage.getItem("apiURL").length - 1),
      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        console.log(data);
        if (data.status === "success") {
          userDispatch({ type: "UPDATE_USER", action: data });
          loaderDispatch({
            type: "SET_LOADER",
            data: { text: "", isLoading: false },
          });
          formDispatch({
            type: "SET_FORM_DATA",
            data: {
              name: "responseModal",
              value: { isOpen: true, text: "Monnify Banks Updated!" },
            },
          });
        } else if (
          data.message === "Token Expired" ||
          data.message === "User Not Found"
        ) {
          props.history.push("/signout");
        }
      });
  };

  return (
    <React.Fragment>
      <Container fluid>
        <ResponseModal isOpen={true} />

        <br />
        <Row>
          <Col xl="10" ml="10">
            <Card className="mini-stat bg-white shadow">
              <CardBody>
                <div className="mb-2">
                  <p
                    className="text-uppercase text-left mb-0 "
                    style={{ color: appData.business.primary_color }}
                  >
                    <b>Fund Wallet with Bank Transfer</b>
                  </p>
                  <hr style={{ borderColor: appData.business.primary_color }} />
                </div>

                <p
                  className="text-uppercase text-left mt-2  text-dark"
                  style={{ fontSize: 12 }}
                >
                  {appData.master_settings.fund_status_bank * 1 === 0 ? (
                    <b>
                      STATUS: WORKING{" "}
                      <i className="mdi mdi-18px  mdi-shield-check-outline text-success"></i>{" "}
                    </b>
                  ) : (
                    <b>
                      STATUS: Downtime{" "}
                      <i className="mdi mdi-18px  mdi-alert-decagram  text-danger"></i>{" "}
                    </b>
                  )}
                </p>
                <p
                  className="text-uppercase text-left mt-2  text-dark"
                  style={{ fontSize: 12 }}
                >
                  <b>Delivery: 1 - 10 minutes </b>
                </p>
                <p
                  className="text-uppercase text-left mt-2  text-dark"
                  style={{ fontSize: 12 }}
                >
                  <b>
                    Supports: USSD, MOBILE BANKING APP, INTERNET BANKING APP,
                    POS
                  </b>
                </p>

                <p
                  className="text-uppercase text-left mt-2  text-danger"
                  style={{ fontSize: 12 }}
                >
                  <b>Transaction Fee: ₦53.75</b>
                </p>

                {appData.master_settings.fund_status_bank * 1 === 0 && (
                  <div>
                    {(!user.data.moniepoint_account_number ||
                      !user.data.sterling_account_number ||
                      !user.data.gt_account_number ||
                      !user.data.fidelity_account_number) && (
                      <Button
                        type="submit"
                        style={{
                          backgroundColor: appData.business.primary_color,
                        }}
                        className="w-md waves-effect waves-light"
                        onClick={getAdditionalBanks}
                      >
                        Get Additional Bank Accounts
                      </Button>
                    )}
                    <hr />

                    <p className="mb-1  mt-0 text-dark">
                      Transfer into your dedicated bank account for auto wallet
                      funding
                    </p>
                    <Row>
                      {user.data.moniepoint_account_number && (
                        <Col md="4" sm="6" xs="6">
                          <Card className="mini-stat shadow text-dark small  ">
                            <CardBody>
                              <p>Bank: Moniepoint Bank</p>
                              <p>
                                Account Number:{" "}
                                {user.data.moniepoint_account_number}{" "}
                                <i
                                  className=" mdi mdi-content-copy  "
                                  style={{
                                    color: appData.business.primary_color,
                                  }}
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      user.data.moniepoint_account_number
                                    );
                                    alert(
                                      user.data.moniepoint_account_number +
                                        " copied!"
                                    );
                                  }}
                                />
                              </p>
                              <p>
                                Account Name: DN-{appData.business.name}{" "}
                                {`${user.data.firstname} ${user.data.othername} ${user.data.lastname}`}
                              </p>
                            </CardBody>
                          </Card>
                        </Col>
                      )}
                      {user.data.sterling_account_number && (
                        <Col md="4" sm="6" xs="6">
                          <Card className="mini-stat shadow text-dark small  ">
                            <CardBody>
                              <p>Bank: Sterling Bank</p>
                              <p>
                                Account Number:{" "}
                                {user.data.sterling_account_number}{" "}
                                <i
                                  className=" mdi mdi-content-copy  "
                                  style={{
                                    color: appData.business.primary_color,
                                  }}
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      user.data.sterling_account_number
                                    );
                                    alert(
                                      user.data.sterling_account_number +
                                        " copied!"
                                    );
                                  }}
                                />
                              </p>
                              <p>
                                Account Name: DN-{appData.business.name}{" "}
                                {`${user.data.firstname} ${user.data.othername} ${user.data.lastname}`}
                              </p>
                            </CardBody>
                          </Card>
                        </Col>
                      )}
                      {user.data.gt_account_number && (
                        <Col md="4" sm="6" xs="6">
                          <Card className="mini-stat shadow text-dark small  ">
                            <CardBody>
                              <p>Bank: GTBank</p>
                              <p>
                                Account Number: {user.data.gt_account_number}{" "}
                                <i
                                  className=" mdi mdi-content-copy  "
                                  style={{
                                    color: appData.business.primary_color,
                                  }}
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      user.data.gt_account_number
                                    );
                                    alert(
                                      user.data.gt_account_number + " copied!"
                                    );
                                  }}
                                />
                              </p>
                              <p>
                                Account Name: DN-{appData.business.name}{" "}
                                {`${user.data.firstname} ${user.data.othername} ${user.data.lastname}`}
                              </p>
                            </CardBody>
                          </Card>
                        </Col>
                      )}
                      {user.data.fidelity_account_number && (
                        <Col md="4" sm="6" xs="6">
                          <Card className="mini-stat shadow text-dark small  ">
                            <CardBody>
                              <p>Bank: Fidelity Bank</p>
                              <p>
                                Account Number:{" "}
                                {user.data.fidelity_account_number}{" "}
                                <i
                                  className=" mdi mdi-content-copy  "
                                  style={{
                                    color: appData.business.primary_color,
                                  }}
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      user.data.fidelity_account_number
                                    );
                                    alert(
                                      user.data.fidelity_account_number +
                                        " copied!"
                                    );
                                  }}
                                />
                              </p>
                              <p>
                                Account Name: DN-{appData.business.name}{" "}
                                {`${user.data.firstname} ${user.data.othername} ${user.data.lastname}`}
                              </p>
                            </CardBody>
                          </Card>
                        </Col>
                      )}
                      {user.data.monnify_account_number && (
                        <Col md="4" sm="6" xs="6">
                          <Card className="mini-stat shadow text-dark small  ">
                            <CardBody>
                              <p>Bank: Wema Bank</p>
                              <p>
                                Account Number:{" "}
                                {user.data.monnify_account_number}{" "}
                                <i
                                  className=" mdi mdi-content-copy  "
                                  style={{
                                    color: appData.business.primary_color,
                                  }}
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      user.data.monnify_account_number
                                    );
                                    alert(
                                      user.data.monnify_account_number +
                                        " copied!"
                                    );
                                  }}
                                />
                              </p>
                              <p>
                                Account Name: DN-{appData.business.name}{" "}
                                {`${user.data.firstname} ${user.data.othername} ${user.data.lastname}`}
                              </p>
                            </CardBody>
                          </Card>
                        </Col>
                      )}
                    </Row>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(FundWallet);
