import React, {useContext} from "react";
import { withRouter } from "react-router-dom";

import CablePrice from "../../components/CablePrice";
import { AppDataContext } from "../../contexts/Contexts";

const DataPricing = () => {
  const {appData}= useContext(AppDataContext)
  document.title='Purchase Cable-'+ appData.business.name;

  return (
    <React.Fragment>
      <br/>
      <CablePrice/>
    </React.Fragment>
  );
};

export default withRouter(DataPricing);
