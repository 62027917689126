import React, { useContext } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { withRouter } from "react-router-dom";

import { AppDataContext } from "../../contexts/Contexts";
import { formatCurrency, getOS } from "../../helpers/utils";

const Payments = () => {
  const { appData } = useContext(AppDataContext);
  document.title = "Contact-" + appData.business.name;

  return (
    <React.Fragment>
      <Container fluid>
        <br />
        <Row>
          <Col xl="10" ml="10" sm="12">
            <Card className="mini-stat text-dark shadow">
              <CardBody>
                <div className="mb-2">
                  <p
                    className="text-uppercase text-left mb-0 "
                    style={{ color: appData.business.primary_color }}
                  >
                    <b>Contact Us</b>
                  </p>
                  <hr style={{ borderColor: appData.business.primary_color }} />
                </div>
                <div className="m-5 text-center">
                  <h3
                    className="pt-3"
                    style={{ color: appData.business.primary_color }}
                  >
                    <i className="mdi mdi-map-marker "></i>
                  </h3>
                  <h6>{appData.business.address}</h6>
                </div>
                <p className="text-center">{appData.business.description}</p>

                <p className="mb-0 mt-0">
                  Click to send us a message on whatsapp, call or email
                </p>

                {appData.settings.contact_whatsapp_number && (
                  <p>
                    <i
                      class="mdi mdi-whatsapp  mdi-24px "
                      style={{ color: "green" }}
                    />

                    <a
                      className="text-dark"
                      target="_"
                      href={
                        getOS() === "WEB"
                          ? "https://web.whatsapp.com/send?phone=234" +
                            appData.settings.contact_whatsapp_number +
                            "&text=Hello!"
                          : "https://api.whatsapp.com/send?phone=234" +
                            appData.settings.contact_whatsapp_number +
                            "&text=Hello!"
                      }
                    >
                      {" "}
                      {appData.settings.contact_whatsapp_number}
                    </a>
                  </p>
                )}

                {appData.settings.contact_call_number1 && (
                  <p>
                    <i
                      class="mdi mdi-phone  mdi-24px "
                      style={{ color: "blue" }}
                    ></i>
                    <a
                      className="text-dark"
                      href={"tel:" + appData.settings.contact_call_number1}
                    >
                      {" "}
                      {appData.settings.contact_call_number1}
                    </a>
                  </p>
                )}
                {appData.settings.contact_call_number2 && (
                  <p>
                    <i
                      class="mdi mdi-phone  mdi-24px "
                      style={{ color: "blue" }}
                    ></i>
                    <a
                      className="text-dark"
                      href={"tel:" + appData.settings.contact_call_number2}
                    >
                      {" "}
                      {appData.settings.contact_call_number2}
                    </a>
                  </p>
                )}

                {appData.settings.contact_email_address && (
                  <p>
                    <i
                      className="mdi mdi-email  mdi-24px   "
                      style={{ color: "red" }}
                    />
                    <a
                      className="text-dark"
                      href={"Mailto:" + appData.settings.contact_email_address}
                    >
                      {appData.settings.contact_email_address}
                    </a>
                  </p>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(Payments);
