import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import {
    Row,
    Col,
    Card,
    CardBody,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import {
    FormContext,
} from "../contexts/Contexts";

//Images
import mtn from "../images/mtn.png";
import glo from "../images/glo.png";
import airtel from "../images/airtel.png";
import etisalat from "../images/etisalat.png";

import { AppDataContext } from "../contexts/Contexts";


const DataPrice = (props) => {
    const { appData } = useContext(AppDataContext);
    const history = useHistory();
    const { formDispatch } = useContext(FormContext);
    
    
    const onPlanSelect = (network, image,atmPrice, walletPrice,message) => {
        formDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'network', value: network },
            
        });
        formDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'image', value: image },
        });
        formDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'atmPrice', value: atmPrice },
            
        });
        formDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'walletPrice', value: walletPrice },
            
        });
        formDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'message', value: message },
            
        });
        
        history.push('/airtimePurchase')
    }
    
    return (
        <React.Fragment>
        {(appData.airtime_plans.MTN||appData.airtime_plans.GLO||
        appData.airtime_plans.AIRTEL||appData.airtime_plans.ETISALAT) && (
            <Row id='AirtimePrice'  className="m-1" style={{scrollMargin:90, scrollSnapMargin:90}}>
            <Col xl="10" md="10" >
           <Card className="mini-stat bg-white text-primary shadow pl-3 pr-3">
        <div className="mb-2">
        <p className="text-uppercase text-left mt-2  " style={{color:appData.business.primary_color}}><b>Airtime Purchase </b></p>
        <hr style={{borderColor:appData.business.primary_color}} />
        </div>
        <Row className="justify-content-center">
        {appData.airtime_plans.MTN && 
         <Col  style={{cursor:'pointer'}} xl="3" xs="3" className="text-center text-dark"  onClick={() => {appData.master_settings.airtime_master.MTN.status*1===0 &&   
            onPlanSelect('MTN',mtn,appData.airtime_plans.MTN.atm_price,appData.airtime_plans.MTN.wallet_price,appData.airtime_plans.MTN.message) }}>
        {appData.master_settings.airtime_master.MTN.status*1===1 && <i  style={{ top:'0%',bottom:'50%', position:'absolute'}} className='mdi mdi-alert-circle-outline  mdi-24px text-danger'/>}
        <img className="shadow p-2 m-2" src={mtn}  height="52" alt="logo"></img>
        {appData.airtime_plans.MTN.wallet_price < 100 && (<p className="small">{100-appData.airtime_plans.MTN.wallet_price}% discount</p>)}
        </Col>
        }
        {appData.airtime_plans.GLO &&
        <Col  style={{cursor:'pointer'}} xl="3" xs="3" className="text-center  text-dark" onClick={() => { onPlanSelect('GLO',glo,appData.airtime_plans.GLO.atm_price,appData.airtime_plans.GLO.wallet_price,appData.airtime_plans.GLO.message) }}>
        <img className="shadow p-2 m-2" src={glo}  height="52" alt="logo"></img>
        {appData.airtime_plans.GLO.wallet_price < 100 && (<p className="small">{100-appData.airtime_plans.GLO.wallet_price}% discount</p>)}
        </Col>
        }
        {appData.airtime_plans.AIRTEL &&
        <Col  style={{cursor:'pointer'}} xl="3" xs="3" className="text-center  text-dark" onClick={() => { onPlanSelect('AIRTEL',airtel,appData.airtime_plans.AIRTEL.atm_price,appData.airtime_plans.AIRTEL.wallet_price,appData.airtime_plans.AIRTEL.message) }}>
        <img className="shadow p-2 m-2" src={airtel}  height="52" alt="logo"></img> 
        {appData.airtime_plans.AIRTEL.wallet_price < 100 && (<p className="small">{100-appData.airtime_plans.AIRTEL.wallet_price}% discount</p>)}
        </Col> 
        }
       {appData.airtime_plans.ETISALAT &&
        <Col   style={{cursor:'pointer'}} xl="3" xs="3" className="text-center  text-dark" onClick={() => { onPlanSelect('ETISALAT',etisalat,appData.airtime_plans.ETISALAT.atm_price,appData.airtime_plans.ETISALAT.wallet_price,appData.airtime_plans.ETISALAT.message) }}>
        <img className="shadow p-2 m-2" src={etisalat}  height="52" alt="logo"></img>
        {appData.airtime_plans.ETISALAT.wallet_price < 100 && (<p className="small">{100-appData.airtime_plans.ETISALAT.wallet_price}% discount</p>)}
        </Col>
        }
        </Row>
        </Card>
        </Col>
        </Row>)
        }
        </React.Fragment>
        );
    };
    
    export default withRouter(DataPrice);
    