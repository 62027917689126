import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Row, Col, Card, CardBody } from "reactstrap";
import { withRouter } from "react-router-dom";
import { FormContext } from "../contexts/Contexts";
import { AppDataContext } from "../contexts/Contexts";
import { AvField } from "availity-reactstrap-validation";

const PasswordInput = (props) => {
  const { formData, formDispatch } = useContext(FormContext);
  const [passwordVisible, setPasswordVisibility] = useState(false);
  function togglePasswordVisibility() {
    setPasswordVisibility(!passwordVisible);
  }
  const formOnChange = (e) => {
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  // Function  to enroll tip! check for device capability before enrollment
  const isBiometricSupported = () => {
    window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ command: "isBiometricSupported" })
      );
  };

  //function to check for enrollment
  const isBiometricEnrolled = () => {
    const enrollmentStatus = window.localStorage.getItem("biometricEnrolled");
    if (enrollmentStatus == null) {
      return false;
    }
    return true;
  };

  // Function  for authentication
  const getBiometricAuth = () => {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ command: "retrivePassword" })
    );
  };

  useEffect(() => {
    isBiometricSupported();
  }, []);

  return (
    <div className="wrapper-page  flex  ml-0" style={{ display: "flex" }}>
      <input
        className="border border-gray p-2 "
        name="password"
        style={{ flexGrow: "100" }}
        label={props.label ? props.label : "Password"}
        value={formData.password}
        id="password"
        errorMessage={
          props.errorMessage ? props.errorMessage : "Password required"
        }
        onChange={(e) => {
          formOnChange(e);
        }}
        autocomplete={props.autocomplete ? props.autocomplete : "password"}
        placeholder={props.placeholder ? props.placeholder : "Enter Password"}
        type={passwordVisible ? "text" : "password"}
        required
      />
      {isBiometricEnrolled() && (
        <i
          className={
            formData.biometricType === "Fingerprint"
              ? "mdi mdi-fingerprint ml-1 pt-1 pb-1 pr-2 pl-2  mdi-18px  border rounded-lg "
              : "mdi mdi-face-recognition  ml-1 pt-1 pb-1 pr-2 pl-2  mdi-18px  border rounded-lg "
          }
          onClick={(e) => {
            e.preventDefault();
            getBiometricAuth();
          }}
        />
      )}

      <div className="d-flex justify-content-end ">
        {isBiometricEnrolled() ? (
          <i
            className={
              passwordVisible
                ? "far  password-toggl-id fa-eye-slash"
                : "far  password-toggle-id fa-eye"
            }
            id="togglePassword"
            onClick={togglePasswordVisibility}
          />
        ) : (
          <i
            className={
              passwordVisible
                ? "far  password-toggle fa-eye-slash"
                : "far  password-toggle fa-eye"
            }
            id="togglePassword"
            onClick={togglePasswordVisibility}
          />
        )}
      </div>
    </div>
  );
};

export default withRouter(PasswordInput);
