import React from 'react'
import {
    Alert,
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Modal,
    ModalBody,
  } from "reactstrap";
import { useHistory, withRouter } from "react-router-dom";
 
import { AvForm, AvField } from "availity-reactstrap-validation";
import ResponseModal from "../../components/ResponseModal";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/UserContext";
import { FormContext } from "../../contexts/FormContext";
import { ModalContext } from "../../contexts/ModalContext";
 import { AppDataContext } from "../../contexts/AppDataContext";
const Beneficiaries = (props) => {
    const { user } = useContext(UserContext);
    const { loaderDispatch } = useContext(ModalContext);
    const [openForm, setOpenForm] = useState(false);
    const { formData, formDispatch } = useContext(FormContext);
    const [focused, setFocused] = useState(false);
    const { appData } = useContext(AppDataContext);
    const [currentBeneficiary, setCurrentBeneficiary] = useState({});
    const [editForm, setEditForm] = useState(false);
    const [loading, setloading] = useState(false);
    const [loadingBenef, setLoadingBenef] = useState(false);
    const [delRoling, setdelRoling] = useState({ deleting: false, id: 0 });
    const history = useHistory();

    const retrieveBeneficiary = () => {
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "Retrieving beneficiaries...", isLoading: true },
        });
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + user.token);
    
        var requestOptions = {
          method: "GET",
          headers: myHeaders,
        };
        fetch(localStorage.getItem("apiURL") + "beneficiary", requestOptions)
          .then((response) => (response = response.text()))
          .then((response) => {
            const data = JSON.parse(response);
            loaderDispatch({
              type: "SET_LOADER",
              data: { text: "", isLoading: false },
            });
    
            formDispatch({
              type: "SET_FORM_DATA",
              data: { name: "benefeciary", value: data.data },
            });
          })
          .catch((error) => {
            formDispatch({
              type: "SET_FORM_DATA",
              data: {
                name: "Alert",
                value: {
                  isOpen: true,
                  message: "unable to connect to server",
                  type: "error",
                },
              },
            });
            loaderDispatch({
              type: "SET_LOADER",
              data: { text: "", isLoading: false },
            });
          });
      };
      useEffect(() => {
        if(!formData.network ) {
        retrieveBeneficiary();
        formDispatch({
            type: "SET_FORM_DATA",
          data: { 
          name: "network", 
          value: true },
        });
    }
      }, []);
   
    const formOnChange = (e) => {
        formDispatch({
          type: "SET_FORM_DATA",
          data: { name: e.target.name, value: e.target.value },
        });
      };

      const handleFocus = (e) => {
        setFocused(true);
      };
      const editBeneficiaries = (e) => {
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "Edit beneficiaries...", isLoading: true },
        });
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Accept", "application/json");
        user.data && myHeaders.append("Authorization", "Bearer " + user.token);
    
        var urlencoded = new URLSearchParams();
    
      
 
    var urlencoded = new URLSearchParams();

    urlencoded.append("user_id", String(user.data.user_id));
    urlencoded.append("name", String(currentBeneficiary.name));
    urlencoded.append("number", String(currentBeneficiary.number));
     urlencoded.append("provider", String(currentBeneficiary.provider));
    urlencoded.append(
      "beneficiary_type",
      String(currentBeneficiary.beneficiary_type),
    );
    
        var requestOptions = {
          method: "PATCH",
          headers: myHeaders,
          body: urlencoded,
        };
    
        fetch(
          localStorage.getItem("apiURL") + "beneficiary/" + currentBeneficiary.id,
          requestOptions
        )
          .then((response) => (response = response.text()))
          .then((response) => {
            const data = JSON.parse(response);
            loaderDispatch({
              type: "SET_LOADER",
              data: { text: "", isLoading: false },
            });
    
            if (data.status === "success") {
              setEditForm(!editForm);
              retrieveBeneficiary();
              // toast(data.message);
              loaderDispatch({
                type: "SET_LOADER",
                data: { text: "", isLoading: false },
              });
            } else if (
              data.message === "Token Expired" ||
              data.message === "User Not Found"
            ) {
              history.push("/signin");
            } else if (data.errors) {
              let errorString = "";
              const objectValues = Object.values(data.errors);
              objectValues.map((error) => {
                errorString = errorString + error + ", ";
              });
    
              formDispatch({
                type: "SET_FORM_DATA",
                data: {
                  name: "Alert",
                  value: { isOpen: true, message: errorString, type: "error" },
                },
              });
              loaderDispatch({
                type: "SET_LOADER",
                data: { text: "", isLoading: false },
              });
            } else {
              formDispatch({
                type: "SET_FORM_DATA",
                data: {
                  name: "Alert",
                  value: { isOpen: true, message: data.message, type: "error" },
                },
              });
            }
          })
          .catch((error) => {
            formDispatch({
              type: "SET_FORM_DATA",
              data: {
                name: "Alert",
                value: {
                  isOpen: true,
                  message: "unable to connect to server",
                  type: "error",
                },
              },
            });
            loaderDispatch({
              type: "SET_LOADER",
              data: { text: "", isLoading: false },
            });
          });
      };
      const TransactionType = ["PhoneNum", "Cable", "Electric"];   
      const elecrticityProvider = [ "IKEDC", "EKEDC",  "KEDCO",  "PHED", "JED", "IBEDC","KAEDCO", "AEDC",];
      const airtimeData = ["MTN", "AIRTEL", "GLO", "ETISALAT"];
      const cable = ["DSTV", "GOTV", "STARTIMES"];
      function handleEditInputChange(e) {
        setCurrentBeneficiary({
          ...currentBeneficiary,
          name: e.target.value,
        });
      }
      function handleEditnumberChange(e) {
        setCurrentBeneficiary({
          ...currentBeneficiary,
          number: e.target.value,
        });
      }
      function handleEditNetworkChange(e) {
        setCurrentBeneficiary({
          ...currentBeneficiary,
          provider: e.target.value,
        });
      }
      function handleEditProviderChange(e) {
        setCurrentBeneficiary({
          ...currentBeneficiary,
          beneficiary_type: e.target.value,
        });
      }
      function handleEditClick(benef) {
        setCurrentBeneficiary({ ...benef });
      }
    
      const onValidSubmit = (e) => {
        e.preventDefault();
        e.persist();
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "Adding beneficiary...", isLoading: true },
        });
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Accept", "application/json");
        user.data && myHeaders.append("Authorization", "Bearer " + user.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("user_id", String(user.id));
        urlencoded.append("name", String(formData.beneficiary_name));
        urlencoded.append("number", String(formData.value_reciever_number));
        urlencoded.append("provider", String(formData.network));
        urlencoded.append("beneficiary_type", String(formData.providerType));

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: urlencoded,
        };
        fetch(localStorage.getItem("apiURL") + "beneficiary", requestOptions)
        .then((response) => (response = response.text()))
        .then((response) => {
          const data = JSON.parse(response);
          loaderDispatch({
            type: "SET_LOADER",
            data: { text: "", isLoading: false },
          });
          if (data.status === "success") {
            setOpenForm(!openForm);
            setloading(false);
            retrieveBeneficiary();
          } else if (
            data.message === "Token Expired" ||
            data.message === "User Not Found"
          ) {
            history.push("/signin");
          } else if (data.errors) {
            let errorString = "";
            const objectValues = Object.values(data.errors);
            objectValues.map((error) => {
              errorString = errorString + error + ", ";
            });
            formDispatch({
              type: "SET_ERROR",
              data: errorString,
            });
            loaderDispatch({
              type: "SET_LOADER",
              data: { text: "", isLoading: false },
            });
          } else {
            formDispatch({
              type: "INPUTVALUES",
              data: {
                name: "Alert",
                value: { isOpen: true, message: data.message, type: "error" },
              },
            });
          }
        })
        .catch((error) => {
          formDispatch({
            type: "INPUTVALUES",
            data: {
              name: "Alert",
              value: {
                isOpen: true,
                message: "unable to connect to server",
                type: "error",
              },
            },
          });
          loaderDispatch({
            type: "SET_LOADER",
            data: { text: "", isLoading: false },
          });
        });
      };
    
      const deleteBeneficiary  = (id, name, number, network, beneficiary_type) => {
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "Deleting beneficiary...", isLoading: true },
        });
        formDispatch({
          type: "SET_FORM_DATA",
          data: { name: "confirmationModal", value: { isOpen: false, text: "" } },
        });
    
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Accept", "application/json");
        user.data && myHeaders.append("Authorization", "Bearer " + user.token);
        var urlencoded = new URLSearchParams();

        urlencoded.append("user_id", String(user.data.user_id));
        urlencoded.append("name", String(name));
        urlencoded.append("number", String(number));
        urlencoded.append("provider", String(network));
        urlencoded.append("beneficiary_type", String(beneficiary_type));
    
        var requestOptions = {
            method: "DELETE",
          headers: myHeaders,
          body: urlencoded,
          //redirect: "follow",
        };
       
    fetch(localStorage.getItem("apiURL") + "beneficiary/" + id, 
    requestOptions)
        
          .then((response) => (response = response.text()))
          .then((response) => {
            const data = JSON.parse(response);
            loaderDispatch({
                type: "SET_LOADER",
                data: { text: "Deleting beneficiary...", isLoading: false },
              });
              formDispatch({
                type: "SET_FORM_DATA",
                data: {
                  name: "responseModal",
                  value: { isOpen: true, text: data.message },
                },
              });
            console.log(data);
            if (data.status === "success") {
                retrieveBeneficiary();
                loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "Deleting beneficiary...", isLoading: false },
                  });
              formDispatch({
                type: "SET_FORM_DATA",
                data: {
                  name: "responseModal",
                  value: { isOpen: false, text: data.message },
                },
              });
            } else if (
              data.message === "Token Expired" ||
              data.message === "User Not Found"
            ) {
              props.history.push("/signout");
            } else if (data.errors) {
              let errorString = "";
              const objectValues = Object.values(data.errors);
              objectValues.map((error) => {
                errorString = errorString + error + ", ";
              });
              formDispatch({
                type: "SET_ERROR",
                data: errorString,
              });
              formDispatch({
                type: "SET_FORM_DATA",
                data: { name: "ref", value: Math.random().toString(36).slice(2) },
              });
              loaderDispatch({
                type: "SET_LOADER",
                data: { text: "Deleting beneficiary...", isLoading: false },
              });
            } else {
              formDispatch({
                type: "SET_ERROR",
                data: data.message,
              });
              formDispatch({
                type: "SET_FORM_DATA",
                data: { name: "ref", value: Math.random().toString(36).slice(2) },
              });
            }
          })
          .catch((error) => {
            console.log("error", error);
            formDispatch({
              type: "SET_ERROR",
              data: "unable to connect to server",
            });
            loaderDispatch({
              type: "SET_LOADER",
              data: { text: "", isLoading: false },
            });
          });
      };
  return (
    <React.Fragment>
      <ResponseModal isOpen={true} shouldGoHome={false} />
  
      <Container fluid>
        <br />

        <Row>
          <Col xl="10" ml="10">
            {formData.benefeciary && (
              <>
                  {formData.benefeciary.map((benef, index) => (
                  <>
                    <details className="mb-4">
                      <summary className="d-flex justify-content-between rounded mb-2 mini-stat bg-white shadow w-100 h-20 px-4  d-flex p-2 text-dark">
                        <div>
                          <b className=" text-uppercase px-4  d-flex p-2 text-dark">
                            {benef.name}
                          </b>
                        </div>
                        <div className="row">
                          <b className=" text-uppercase align-items-center px-4  d-flex p-2 text-dark">
                            {/* {benef.number} */}
                          </b>
                        </div>

                        <div className="d-flex ">
                          <div className="mr-4">
                            <i
                              className="mdi mdi-account-edit float-right  mdi-24px"
                              type="p-4"
                              onClick={() => {
                                setEditForm(!editForm);
                                formDispatch({
                                  type: "SET_FORM_DATA",
                                  data: {
                                    name: "editBeneficiary",
                                     value: !editForm.addBeneficiary,
 
                                  },
                                });
                                handleEditClick(benef);
                              }}
                            >
                              {" "}
                            </i>
                          </div>

                          <div className="">
                            <i
                              className="mdi mdi-delete-circle-outline float-right  mdi-24px "
                              type="p-4"
                              onClick={() =>
                                deleteBeneficiary(
                                  benef.id,
                                  benef.name,
                                  benef.number,
                                  benef.provider,
                                  benef.beneficiary_type
                                )
                              }
                            ></i>
                          </div>
                        </div>
                      </summary>

                      <div>
                        {(benef.provider === "MTN" ||
                          benef.provider === "AIRTEL" ||
                          benef.provider === "GLO" ||
                          benef.provider === "ETISALAT") && (
                          <Row>
                            <Col xs="6">
                              <Button
                                onClick={() => {
                                  formDispatch({
                                    type: "SET_FORM_DATA",
                                    data: {
                                      name: "fromBeneficiaries",
                                      value: true,
                                    },
                                  });
                                  formDispatch({
                                    type: "SET_FORM_DATA",
                                    data: {
                                      name: "phone_number",
                                      value: benef.number,
                                    },
                                  });
                                  formDispatch({
                                    type: "SET_FORM_DATA",
                                    data: {
                                      name: "network",
                                      value: benef.provider,
                                    },
                                  });

                                  props.history.push("/dataPricing");
                                }}
                                type="submit"
                                style={{
                                  backgroundColor:
                                    appData.business.primary_color,
                                }}
                                className="btn-block w-md waves-effect waves-light"
                              >
                                Buy Data
                              </Button>
                            </Col>
                              {/* Buy data and airtime */}
                            <Col xs="6">
                              <Button
                                onClick={() => {
                                  formDispatch({
                                    type: "SET_FORM_DATA",
                                    data: {
                                      name: "fromBeneficiaries",
                                      value: true,
                                    },
                                  });
                                  formDispatch({
                                    type: "SET_FORM_DATA",
                                    data: {
                                      name: "phone_number",
                                      value: benef.number,
                                    },
                                  });
                                  formDispatch({
                                    type: "SET_FORM_DATA",
                                    data: {
                                      name: "network",
                                      value: benef.provider,
                                    },
                                  });
                                  props.history.push("/airtimePurchase");
                                }}
                                type="submit"
                                style={{
                                  backgroundColor:
                                    appData.business.primary_color,
                                }}
                                className="btn-block w-md waves-effect waves-light"
                              >
                                Buy Airtime
                              </Button>
                            </Col>
                          </Row>
                        )}
                        {(benef.provider === "DSTV" ||
                          benef.provider === "GOTV" ||
                          benef.provider === "STARTIMES") && (
                          <Col xs="6">
                            <Button
                              onClick={() => {
                                formDispatch({
                                  type: "SET_FORM_DATA",
                                  data: {
                                    name: "fromBeneficiaries",
                                    value: true,
                                  },
                                });
                                formDispatch({
                                  type: "SET_FORM_DATA",
                                  data: {
                                    name: "smartcard_number",
                                    value: benef.number,
                                  },
                                });
                                formDispatch({
                                  type: "SET_FORM_DATA",
                                  data: {
                                    name: "network",
                                    value: benef.provider,
                                  },
                                });
                                props.history.push("/cablePricing");
                              }}
                              type="submit"
                              style={{
                                backgroundColor: appData.business.primary_color,
                              }}
                              className="btn btn-success btn-block"
                            >
                              Buy Cable Subscriptions
                            </Button>
                          </Col>
                        )}

                        {(benef.provider === "IKEDC" ||
                          benef.provider === "EKEDC" ||
                          benef.provider === "KEDCO" ||
                          benef.provider === "KEDCO" ||
                          benef.provider === "PHED" ||
                          benef.provider === "JED" ||
                          benef.provider === "IBEDC" ||
                          benef.provider === "KAEDCO" ||
                          benef.provider === "AEDC") && (
                          <Col xs="6">
                            <Button
                              onClick={() => {
                                formDispatch({
                                  type: "SET_FORM_DATA",
                                  data: {
                                    name: "fromBeneficiaries",
                                    value: true,
                                  },
                                });
                                formDispatch({
                                  type: "SET_FORM_DATA",
                                  data: {
                                    name: "meter_number",
                                    value: benef.number,
                                  },
                                });
                                formDispatch({
                                  type: "SET_FORM_DATA",
                                  data: {
                                    name: "network",
                                    value: benef.provider,
                                  },
                                });
                                props.history.push("/electricPricing");
                              }}
                              type="submit"
                              style={{
                                backgroundColor: appData.business.primary_color,
                              }}
                              className="d-flex justify-content-center btn-block w-md waves-effect waves-light "
                            >
                              Buy Electricity Subscriptions
                            </Button>
                          </Col>
                        )}
                      </div>
                    </details>
                  </>
                ))}
              </>
            )}
            {/* add more beneficiary */}
            <>
              {!openForm && (
                <Button
                  type="submit"
                  onClick={() => {
                    setOpenForm(!openForm);
                     setEditForm(false);
 
                    formDispatch({
                      type: "SET_FORM_DATA",
                      data: {
                        name: "addBeneficiary",
                         value: !openForm.addBeneficiary,
 
                      },
                    });
                  }}
                  style={{
                    backgroundColor: appData.business.primary_color,
                  }}
                  className="w-md waves-effect waves-light"
                >
                    {formData.addBank ? " X " : "+ Add Beneficiary"}
 
                </Button>
              )}
            </>

            {openForm && (
              <Modal isOpen={formData.addBeneficiary} centered={true}>
                <Card className=" mb-2 mini-stat bg-white shadow">
                  <CardBody>
                    <AvForm
                      className="form-horizontal mb-2"
                      autoComplete="off"
                      onValidSubmit={onValidSubmit}
                    >
                      {formData.error && (
                        <Alert color="danger">{formData.error}</Alert>
                      )}
                      <div className="mb-2 d-flex justify-content-between">
                        <div>
                          <p
                            className=" text-left mb-0 "
                            style={{ color: appData.business.primary_color }}
                          >
                            <b className="text-uppercase">Add beneficiary </b>
                          </p>
                        </div>

                        <div>
                          <Button
                            onClick={() => {
                              setOpenForm(!openForm);
                            }}
                          >
                            X{" "}
                          </Button>
                        </div>
                      </div>
                      <div>
                        <hr
                          style={{
                            borderColor: appData.business.primary_color,
                          }}
                        />
                        <AvField
                          type="text"
                          className="mt-2 "
                          required
                          placeholder="Name"
                          name="beneficiary_name"
                          value={formData.beneficiary_name}
                          onChange={(e) => {
                            formOnChange(e);
                          }}
                        />
                        <AvField
                          type="text"
                          className=""
                          placeholder="Beneficiary number"
                          name="value_reciever_number"
                          autoComplete="off"
                          value={formData.value_reciever_number}
                          inputmode="numeric"
                          focused={focused.toString()}
                          required
                          onBlur={handleFocus}
                          onChange={(e) => {
                            formOnChange(e);
                          }}
                        />
                        <select
                          value={formData.providerType}
                          name="providerType"
                          onChange={(e) => {
                            formOnChange(e);
                          }}
                          required
                          className="form-control mb-2"
                        >
                          <option value="">Beneficiary Type</option>
                          {TransactionType.map((transaction, index) => {
                            return (
                              <option value={transaction}>{transaction}</option>
                            );
                          })}
                        </select>
                        <select
                          value={formData.network}
                          name="network"
                          onChange={(e) => {
                            formOnChange(e);
                          }}
                          required
                          className="form-control mb-2"
                        >
                          <option value="">Service Provider</option>
                          {formData.providerType === "Electric"
                            ? elecrticityProvider.map((transaction, index) => {
                                return (
                                  <option value={transaction}>
                                    {transaction}
                                  </option>
                                );
                              })
                            : ""}{" "}
                          {formData.providerType === "PhoneNum"
                            ? airtimeData.map((transaction, index) => {
                                return (
                                  <option value={transaction}>
                                    {transaction}
                                  </option>
                                );
                              })
                            : ""}
                          {formData.providerType === "Cable"
                            ? cable.map((transaction, index) => {
                                return (
                                  <option value={transaction}>
                                    {transaction}
                                  </option>
                                );
                              })
                            : ""}
                        </select>{" "}
                        <Button
                          type="submit"
                          style={{
                            backgroundColor: appData.business.primary_color,
                          }}
                          className="w-md waves-effect waves-light"
                        >
                          Add
                        </Button>
                      </div>
 
                      {/* <div className="d-flex justify-content-between p-2 pb-3">
            <div>
            <Col xs="6">
           


                <Button className="w-md waves-effect waves-light" 
               type="submit"
               style={{
                 backgroundColor: appData.business.primary_color,
               }}
              >
                <p className="mb-0 text-white">ADD</p>
              </Button>
              
            </Col>
            </div>
            <div>            
              <Col xs="6">
            <Button className="rounded text-center p-2 " 
                style={{ backgroundColor: "white",  width: '110px', height: '38px' }}
                
              >
                <p className="mb-0"
                  style={{ color: appData.business.primary_color }} 
                  onClick={() => {
                    setOpenForm(!OpenForm); }}>
            
                    CANCEL
                    </p>
                  </Button>
            </Col>
            </div>

          </div> */}
                     </AvForm>
                  </CardBody>
                </Card>
              </Modal>
            )}
            {editForm && (
              <Modal isOpen={formData.editBeneficiary} centered={true}>
                <Card className=" mb-2 mini-stat bg-white shadow">
                  <CardBody>
                    <AvForm
                      className="form-horizontal mb-2"
                      autoComplete="off"
                      onSubmit={editBeneficiaries}
                    >
                      {formData.error && (
                        <Alert color="danger">{formData.error}</Alert>
                      )}
                      <div className="mb-2 d-flex justify-content-between">
                        <div>
                          <p
                            className=" text-left mb-0 "
                            style={{ color: appData.business.primary_color }}
                          >
                            <b className="text-uppercase">Edit beneficiary </b>
                          </p>
                        </div>

                        <div>
                          <Button
                            onClick={() => {
                              setEditForm(!editForm);
                            }}
                          >
                            X{" "}
                          </Button>
                        </div>
                      </div>
                      <div>
                        <hr
                          style={{
                            borderColor: appData.business.primary_color,
                          }}
                        />
                        <AvField
                          type="text"
                          className="mt-2 "
                          required
                          placeholder="Name"
                          name="beneficiary_name"
                          value={currentBeneficiary.name}
                          onChange={(e) => {
                            handleEditInputChange(e);
                          }}
                        />
                        <AvField
                          type="text"
                          className=""
                          placeholder="Beneficiary number"
                          name="value_reciever_number"
                          autoComplete="off"
                          value={currentBeneficiary.number}
                          inputmode="numeric"
                          focused={focused.toString()}
                          required
                          onBlur={handleFocus}
                          onChange={(e) => {
                            handleEditnumberChange(e);
                          }}
                        />
                        <select
                           value={currentBeneficiary.beneficiary_type}
 
                          name="providerType"
                          onChange={(e) => {
                            handleEditProviderChange(e);
                          }}
                          required
                          className="form-control mb-2"
                        >
                          <option value="">Beneficiary Type</option>
                          {TransactionType.map((transaction, index) => {
                            return (
                              <option value={transaction}>{transaction}</option>
                            );
                          })}
                        </select>
                        <select
                           value={currentBeneficiary.provider}
 
                          name="network"
                          onChange={(e) => {
                            handleEditNetworkChange(e);
                          }}
                          required
                          className="form-control mb-2"
                        >
                          <option value="">Service Provider</option>
                          {formData.providerType === "Electric"
                            ? elecrticityProvider.map((transaction, index) => {
                                return (
                                  <option value={transaction}>
                                    {transaction}
                                  </option>
                                );
                              })
                            : ""}{" "}
                          {currentBeneficiary.beneficiary_type === "PhoneNum"
                            ? airtimeData.map((transaction, index) => {
                                return (
                                  <option value={transaction}>
                                    {transaction}
                                  </option>
                                );
                              })
                            : ""}
                          {currentBeneficiary.beneficiary_type === "Cable"
                            ? cable.map((transaction, index) => {
                                return (
                                  <option value={transaction}>
                                    {transaction}
                                  </option>
                                );
                              })
                            : ""}
                        </select>{" "}
                        <Button
                          type="submit"
                          style={{
                            backgroundColor: appData.business.primary_color,
                          }}
                          className="w-md waves-effect waves-light"
                        >
                          Update
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Modal>
            )}
          </Col>
        </Row>

        {/* <Button>
      <img
                  src={plus}
                  alt="Add"
                  className="relative h-4 w-4 md:h-8 md:w-8  m-auto "
                />
                <h1 className='relative h-4 w-4 md:h-8 md:w-8  m-auto '>Popup</h1>
      </Button> */}
       </Container>
    </React.Fragment>
  );
};

export default withRouter(Beneficiaries);