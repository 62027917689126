import React, { useContext } from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  UserContext,
  ModalContext,
  FormContext,
  AppDataContext,
} from "../../contexts/Contexts";
import { formatCurrency } from "../../helpers/utils";
import BankAccountCard from "../../components/BankAccountCard";
import mtn from "../../images/mtn.png";
import glo from "../../images/glo.png";
import airtel from "../../images/airtel.png";
import etisalat from "../../images/etisalat.png";

const BalanceCodes = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { formData, formDispatch } = useContext(FormContext);
  const { user } = useContext(UserContext);
  const { appData } = useContext(AppDataContext);
  document.title = "Balance Codes- " + appData.business.name;

  return (
    <React.Fragment>
      <Container fluid>
        <br />
        <Row>
          <Col xl="12" ml="12">
            <Card className="mini-stat bg-white shadow">
              <CardBody>
                <div className="mb-2">
                  <p
                    className="text-uppercase text-left mb-0 "
                    style={{ color: appData.business.primary_color }}
                  >
                    <b>Balance Checking Codes</b>
                  </p>
                  <hr style={{ borderColor: appData.business.primary_color }} />
                </div>

                <Row className="rounded shadow p-2">
                  <Col md="1" xs="3">
                    <p className="text-center">
                      <img
                        className="shadow"
                        src={mtn}
                        height="48"
                        alt="logo"
                      ></img>
                    </p>
                  </Col>
                  <Col xs="9">
                    <p className="m-0 p-0">
                      <b> Normal Data and CG: *323*1#</b>
                    </p>
                    <p className="m-0 p-0">
                      <b>SME Data: *323*3#</b>
                    </p>
                    <p className="m-0 p-0">
                      <b>Airtime: *310#</b>
                    </p>
                  </Col>
                </Row>

                <Row className="rounded shadow p-2 mt-2">
                  <Col md="1" xs="3">
                    <p className="text-center">
                      <img
                        className="shadow"
                        src={airtel}
                        height="48"
                        alt="logo"
                      ></img>
                    </p>
                  </Col>
                  <Col xs="9">
                    <p className="m-0 p-0">
                      <b>Normal Data *323#</b>
                    </p>
                    <p className="m-0 p-0">
                      <b>Airtime: *310#</b>
                    </p>
                  </Col>
                </Row>

                <Row className="rounded shadow p-2 mt-2">
                  <Col md="1" xs="3">
                    <p className="text-center">
                      <img
                        className="shadow"
                        src={glo}
                        height="48"
                        alt="logo"
                      ></img>
                    </p>
                  </Col>
                  <Col xs="9">
                    <p className="m-0 p-0">
                      <b>Normal Data *323#</b>
                    </p>
                    <p className="m-0 p-0">
                      <b>Airtime: *310#</b>
                    </p>
                  </Col>
                </Row>

                <Row className="rounded shadow p-2 mt-2">
                  <Col md="1" xs="3">
                    <p className="text-center">
                      <img
                        className="shadow"
                        src={etisalat}
                        height="48"
                        alt="logo"
                      ></img>
                    </p>
                  </Col>
                  <Col xs="9">
                    <p className="m-0 p-0">
                      <b>Normal Data *323#</b>
                    </p>
                    <p className="m-0 p-0">
                      <b>Airtime: *310#</b>
                    </p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(BalanceCodes);
