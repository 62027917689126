import React, { useContext, useState, useEffect } from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { Collapse, CardHeader } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import {
  UserContext,
  ModalContext,
  FormContext,
  AppDataContext,
} from "../../contexts/Contexts";
function Kyc404() {
  const { appData } = useContext(AppDataContext);
  return (
    <React.Fragment>
      <Container fluid style={{ minHeight: "100vh" }}>
        {" "}
        <br />
        <div className="mb-2">
          <p
            className="text-uppercase text-left mb-0 "
            style={{ color: appData.business.primary_color }}
          >
            <b>KYC 404</b>
          </p>
          <hr style={{ borderColor: appData.business.primary_color }} />
        </div>
        <Row className="mt-16">
          <Col className="d-flex justify-content-center">
            {/* <Image src={wrong} className="w-24 h-24" alt="img" /> */}
            <i
              className="fa fa-exclamation-triangle"
              aria-hidden="true"
              style={{ fontSize: "84px", color: "red", marginBottom: "40px" }}
            ></i>
          </Col>
        </Row>
        <Row className="p-1 mt-10">
          <Col>
            <p className="font-medium text-sm md:text-xl bg-danger rounded p-1 text-white text-center">
              The Feature you are trying to access requires you to verify your
              account before gaining access to it!
            </p>
          </Col>
        </Row>
        <Row className="mt-10">
          <Col>
            <Link
              to="kyc"
              className="text-center items-center justify-items-center justify-contents-center"
            >
              <p className=" text-center ">
                CBN policies mandate us to verify your identity to keep your
                account safe and ensure you enjoy all features on{" "}
                {appData.business.name}.
              </p>
              <p className="text-center">
                {" "}
                <Button
                  className="btn rounded-pill text-center mt-3"
                  style={{ background: appData.business.primary_color }}
                >
                  Get Started ➔
                </Button>
              </p>
            </Link>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Kyc404;
