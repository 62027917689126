import React, { useContext , useState,useEffect} from "react";
import {
    Alert,
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Button,
} from "reactstrap";
import {  Collapse,CardHeader} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { UserContext, ModalContext, FormContext ,AppDataContext} from "../../contexts/Contexts";


const BalanceCodes = (props) => {
    const { loaderDispatch } = useContext(ModalContext);
    const { formData, formDispatch } = useContext(FormContext);
    const { user } = useContext(UserContext);
    const {appData}= useContext(AppDataContext)
    document.title='Balance Codes- '+ appData.business.name;
   
    const Faq=(props)=>{
      const [open,setOpen]=useState(false)

      return (
          <Col xs="12" className='p-0'>
          <Card className="mb-2  rounded m-0 p-0">
          <div  className="faq collapsed shadow border ml-0 mr-0 ">
             <CardHeader className="text-dark" id="headingOne"   onClick={() => setOpen(!open)} style={{cursor:'pointer'}}>
                  <h6 className="m-0 faq-question">
                      <i className={open? "mdi mdi-arrow-up-drop-circle " : "mdi mdi-arrow-down-drop-circle "} 
                    ></i> {props.question}</h6>
              </CardHeader>
              </div>
              <Collapse isOpen={open}>
              <CardBody>
      <p className=" mb-0 faq-ans"> {props.answer}</p>
              </CardBody>
              </Collapse>
      </Card>
      </Col>
   
      )
  }

  useEffect(() => {
    if(!formData.faqLoaded ) {
      fetchFAQS();
       formDispatch({
         type: "SET_FORM_DATA",
         data: { name: 'faqLoaded', value: true },
       });
     }
   }, []);


   
  const fetchFAQS = () => {
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Retrieving FAQs...", isLoading: true },
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + user.token);
      var requestOptions = {
      method: "GET",
      headers: myHeaders,
      //redirect: "follow",
    };
    fetch(
      localStorage.getItem("apiURL") + "faq",
      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          formDispatch({
            type: "SET_FORM_DATA",
            data: { name: "faqs", value: data.data },
          });
        } 
        else if (data.message === "Token Expired"  || data.message === "User Not Found"){
          props.history.push('/signout');    
      }
        else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
        } else {
          formDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };


        return (
            <React.Fragment>
      <Container fluid style={{minHeight:'100vh'}}>
            <br/>
              <div className="mb-2">
            <p className="text-uppercase text-left mb-0 " style={{color:appData.business.primary_color}}><b>FAQs</b></p>
            <hr style={{borderColor:appData.business.primary_color}} />
            </div>
           
            {formData.faqs && (
                  <React.Fragment>
                     {formData.faqs.map((faq) => {
                      return <Faq key={faq.id} question={faq.question} answer={faq.answer} />;
                    })}
                  </React.Fragment>
                )
                }
             </Container>
            </React.Fragment>
            );
        };
        
        export default withRouter(BalanceCodes);
        