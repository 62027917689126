import React, { useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";

//Metismenu for menu
import MetisMenu from "metismenujs";

// Redux Call
import Sidenav from "./SideNav";

import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { AppDataContext } from "../../contexts/Contexts";

const Sidebar = (props) => {
  const { appData } = useContext(AppDataContext);

  useEffect(() => {
    new MetisMenu("#menu", {});
    var matchingMenuItem = null;
    var ul = document.getElementById("menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, []);

  useEffect(() => {
    if (props.is_large_state === true) {
      new MetisMenu("#menu", {});
    }

    const width = window.innerWidth;
    width < 768 && document.body.classList.toggle("enlarged", true);
  });

  const activateParentDropdown = (item) => {
    item.classList.add("mm-active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active"); // li
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");
        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("active");
          }
        }
      }
      return false;
    }
  };
  return (
    <React.Fragment>
      <div
        className="left side-menu "
        style={{ backgroundColor: appData.business.secondary_color }}
      >
        {props.is_large_state ? (
          <Sidenav />
        ) : (
          <PerfectScrollbar>
            <Sidenav />
          </PerfectScrollbar>
        )}

        <div className="clearfix"></div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Sidebar);
