import React, { useContext } from "react";
import {
    Alert,
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Button,
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { AvForm, AvField } from "availity-reactstrap-validation";
import { UserContext, ModalContext, FormContext ,AppDataContext} from "../../contexts/Contexts";
import { formatCurrency } from "../../helpers/utils";
import BankAccountCard from "../../components/BankAccountCard";

const FundWallet = (props) => {
    const { loaderDispatch } = useContext(ModalContext);
    const { formData, formDispatch } = useContext(FormContext);
    const { user } = useContext(UserContext);
    const {appData}= useContext(AppDataContext)
    document.title='Fund Wallet with ATM-'+ appData.business.name;
    
    const formOnChange=(e)=>{
        formDispatch({
            type: "SET_FORM_DATA",
            data: {name:e.target.name,value:e.target.value,}
        });

        var charge=(e.target.value*1.4)/100;
        var vat=(charge*7)/100;

        var balance =e.target.value-charge-vat;
        formDispatch({
            type: "SET_FORM_DATA",
            data: {name:'charge',value:charge+vat,}
        });
        formDispatch({
            type: "SET_FORM_DATA",
            data: {name:'balance',value:balance,}
        });

        }

   
    
    const handleSubmit = (e) => {
        e.persist();
        e.preventDefault();
        loaderDispatch({
            type: "SET_LOADER",
            data: { text: "connecting to server...", isLoading: true },
        });
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + user.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("amount", String(formData.amount));
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            //redirect: "follow",
        };
        fetch(
            localStorage.getItem('apiURL')+"fund_wallet",
            requestOptions
            )
            .then((response) => (response = response.text()))
            .then((response) => {
                const data = JSON.parse(response);
                if(data.status === "success"){
                    window.location = data.data.payment_url
                    return;
                }
                loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                });
                console.log(data);
                if (data.status === "success") {
                    window.location = data.data.payment_url
                } 
                else if (data.message === "Token Expired"  || data.message === "User Not Found"){
                    props.history.push('/signout');    
                }
                else {
                    formDispatch({
                        type: "SET_ERROR",
                        data: data.message,
                    });
                }
            })
            .catch((error) => {
                console.log("error", error);
                formDispatch({
                    type: "SET_ERROR",
                    data: "unable to connect to server",
                });
                loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                });
            });
        };
        
        return (
            <React.Fragment>
            <Container fluid>
            <br/>
            <Row>
            <Col xl="10" ml="10" >
            <Card className="mini-stat bg-white shadow">
            <CardBody>
            <div className="mb-2">
            <p className="text-uppercase text-left mb-0 " style={{color:appData.business.primary_color}}><b>Fund Wallet with ATM</b></p>
            <hr style={{borderColor:appData.business.primary_color}} />
            </div>
            <p className="text-uppercase text-left mt-2  text-dark" style={{fontSize:12}}>
            {appData.master_settings.fund_status_atm*1 === 0 ? <b>STATUS: WORKING <i className="mdi mdi-18px  mdi-shield-check-outline text-success"></i> </b> :<b>STATUS: Downtime <i className="mdi mdi-18px  mdi-alert-decagram  text-danger"></i> </b>}
            </p>
            <p className="text-uppercase text-left mt-2  text-dark" style={{fontSize:12}}>
            <b>Delivery: 1 - 3 minutes </b>
            </p>
            <p className="text-uppercase text-left mt-2  text-dark" style={{fontSize:12}}>
            <b>Supports: VISA, MASTERCARD, VERVE </b>
            </p>
            <p className="text-uppercase text-left mt-2  text-danger" style={{fontSize:12}}>
            <b>Transaction Fee: 1.4%</b>
            </p>
            
            {formData.error && <Alert color="danger">{formData.error}</Alert>}
            
            {appData.master_settings.fund_status_atm*1 === 0 && (
            <AvForm  onValidSubmit={handleSubmit}>
            <Card className="mini-stat shadow text-dark">
            <CardBody>
            <h6 className="text-uppercase text-dark mt-2 small" >How much do you want to pay?</h6>
            <AvField
            name="amount"
            label=""
            value={formData.amount}
            onChange={(e)=>{formOnChange(e)}}
            placeholder={"Enter amount-Min ₦"+formatCurrency(appData.settings.minimum_atm_funding)}
            type="number"
            errorMessage="Enter valid amount"
            validate={{
                required: { value: true },
                pattern: {
                    value: "^[0-9]+$",
                    errorMessage: "Only valid amount",
                },
            }}
            maxLength="11"

            />
            <p className="mb-0 small">TRANSACTION FEE: {formData.balance &&(<b className="text-danger">₦{formatCurrency(formData.charge)}</b>)}</p>
            <p className="mb-0 small">{formData.balance &&(<b>₦{formatCurrency(formData.balance)} WILL BE PAID INTO YOUR WALLET</b>)}</p>
            
            </CardBody>
            </Card>
            <Button
            style={{backgroundColor:appData.business.primary_color}}
            className="w-md waves-effect waves-light"
            type="submit"
            > 
            Pay {formData.amount && (<React.Fragment>₦{formatCurrency(formData.amount)}</React.Fragment>)}
            </Button>
            </AvForm>
            )}
            </CardBody>
            </Card>
            </Col>
            </Row>
            </Container>
            </React.Fragment>
            );
        };
        
        export default withRouter(FundWallet);
        