import Dashboard from "./pages/Dashboard/dashboard";
import FundWalletATM from "./pages/FundWallet/fundWalletATM";
import FundWalletAuto from "./pages/FundWallet/fundWalletAuto";
import FundWalletBank from "./pages/FundWallet/fundWalletBank";

import SignIn from "./pages/Auth/SignIn";
import SignOut from "./pages/Auth/SignOut";
import SignUp from "./pages/Auth/SignUp";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import ResetPassword from "./pages/Auth/ResetPassword";
import DataPurchase from "./pages/DataPurchase/DataPurchase";
import DataPricing from "./pages/DataPurchase/DataPricing";
import AirtimePurchase from "./pages/AirtimePurchase/AirtimePurchase";
import Beneficiaries from "./pages/Beneficiaries/Beneficiaries";
import AirtimePricing from "./pages/AirtimePurchase/AirtimePricing";
import TransferFund from "./pages/TransferFund/TransferFund";
import Transactions from "./pages/Transactions/Transactions";
import Profile from "./pages/Profile/Profile";
import CablePurchase from "./pages/CablePurchase/CablePurchase";
import ElectricPurchase from "./pages/ElectricPurchase/ElectricPurchase";
import CablePricing from "./pages/CablePurchase/CablePricing";
import ElectricPricing from "./pages/ElectricPurchase/ElectricPricing";
import Contact from "./pages/Contact/Contact";
import BalanceCodes from "./pages/Dashboard/BalanceCodes";
import Faq from "./pages/FAQ/Faq";
import AccountDeleted from "./pages/AccountDeleted/accountDeleted";
import Kyc from "./pages/Kyc/Kyc";
import Kyc404 from "./pages/Kyc/Kyc404";
import PhoneVerification from "./pages/PhoneVerification/PhoneVerification";
import selfFix from "./pages/FundWallet/selfFix";
import selffixAutoAgent from "./pages/FundWallet/selffixAutoAgent";

const routes = [
  // public Routes
  { path: "/signin", component: SignIn, ispublic: true },
  { path: "/signout", component: SignOut, ispublic: true },
  { path: "/signup", component: SignUp, ispublic: true },
  { path: "/forgotPassword", component: ForgotPassword, ispublic: true },
  { path: "/resetPassword", component: ResetPassword, ispublic: true },

  // // Dashboard
  {
    path: "/dashboard",
    component: Dashboard,
    ispublic: true,
    withLayout: true,
  },
  { path: "/fundWalletATM", component: FundWalletATM, withLayout: true },
  { path: "/fundWalletAuto", component: FundWalletAuto, withLayout: true },
  {
    path: "/fundWalletBank",
    component: FundWalletBank,
    withLayout: true,
    kyc: true,
  },
  {
    path: "/transferFund",
    component: TransferFund,
    withLayout: true,
    kyc: true,
  },
  { path: "/transactions", component: Transactions, withLayout: true },
  { path: "/profile", component: Profile, withLayout: true },
  {
    path: "/dataPurchase",
    component: DataPurchase,
    ispublic: true,
    withLayout: true,
  },
  {
    path: "/dataPricing",
    component: DataPricing,
    ispublic: true,
    withLayout: true,
  },
  {
    path: "/airtimePurchase",
    component: AirtimePurchase,
    ispublic: true,
    withLayout: true,
  },
  {
    path: "/beneficiaries",
    component: Beneficiaries,
    withLayout: true,
  },
  {
    path: "/airtimePricing",
    component: AirtimePricing,
    ispublic: true,
    withLayout: true,
  },
  {
    path: "/cablePricing",
    component: CablePricing,
    ispublic: true,
    withLayout: true,
  },
  {
    path: "/electricPricing",
    component: ElectricPricing,
    ispublic: true,
    withLayout: true,
  },
  {
    path: "/cablePurchase",
    component: CablePurchase,
    ispublic: true,
    withLayout: true,
  },
  {
    path: "/accountDeleted",
    component: AccountDeleted,
    ispublic: true,
    withLayout: true,
  },
  {
    path: "/kyc",
    component: Kyc,
    ispublic: true,
    withLayout: true,
  },
  {
    path: "/kyc404",
    component: Kyc404,
    ispublic: true,
    withLayout: true,
  },

  {
    path: "/selffix",
    component: selfFix,
    ispublic: true,
    withLayout: true,
  },
  {
    path: "/selffixAutoAgent",
    component: selffixAutoAgent,
    ispublic: true,
    withLayout: true,
  },

  {
    path: "/verifyPhone",
    component: PhoneVerification,
    ispublic: true,
    withLayout: true,
  },
  {
    path: "/electricPurchase",
    component: ElectricPurchase,
    ispublic: true,
    withLayout: true,
  },

  { path: "/contact", component: Contact, ispublic: true, withLayout: true },

  { path: "/faq", component: Faq, ispublic: true, withLayout: true },
  {
    path: "/balanceCodes",
    component: BalanceCodes,
    withLayout: true,
    ispublic: true,
  },
  { path: "/", component: Dashboard, ispublic: true, withLayout: true },
];

export default routes;
