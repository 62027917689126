import React, { useContext, useEffect } from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  UserContext,
  ModalContext,
  FormContext,
  AppDataContext,
} from "../../contexts/Contexts";
import { WalletPayment } from "../../components/PaymentSelector";
import ResponseModal from "../../components/ResponseModal";
import { formatCurrency } from "../../helpers/utils";

const TransferFund = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { formData, formDispatch } = useContext(FormContext);
  const { user } = useContext(UserContext);
  const { appData } = useContext(AppDataContext);
  document.title = "Transfer Funds-" + appData.business.name;

  const formOnChange = (e) => {
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  useEffect(() => {
    document.getElementById("receiver").addEventListener("input", function () {
      formDispatch({
        type: "SET_FORM_DATA",
        data: { name: "receiver", value: this.value.replace(/\s/g, "") },
      });
    });

    if (!formData.ref) {
      formDispatch({
        type: "SET_FORM_DATA",
        data: { name: "ref", value: Math.random().toString(36).slice(2) },
      });
    }
  }, []);

  const verifyReceiver = (e) => {
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "verifying receiver...", isLoading: true },
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + user.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("receiver", String(formData.receiver));
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      //redirect: "follow",
    };
    fetch(localStorage.getItem("apiURL") + "verify_user", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          formDispatch({
            type: "SET_FORM_DATA",
            data: { name: "receiverName", value: data.data.name },
          });
        } else if (
          data.message === "Token Expired" ||
          data.message === "User Not Found"
        ) {
          props.history.push("/signout");
        } else {
          formDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "connecting to server...", isLoading: true },
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + user.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("receiver", String(formData.receiver));
    urlencoded.append("description", String(formData.description));
    urlencoded.append("amount", String(formData.amount));
    urlencoded.append("password", String(formData.password));
    urlencoded.append("ref", formData.ref);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      //redirect: "follow",
    };
    fetch(localStorage.getItem("apiURL") + "wallet_transfer", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          formDispatch({
            type: "SET_FORM_DATA",
            data: {
              name: "responseModal",
              value: { isOpen: true, text: data.message },
            },
          });
        } else if (
          data.message === "Token Expired" ||
          data.message === "User Not Found"
        ) {
          props.history.push("/signout");
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
          formDispatch({
            type: "SET_FORM_DATA",
            data: { name: "ref", value: Math.random().toString(36).slice(2) },
          });
        } else {
          formDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
          formDispatch({
            type: "SET_FORM_DATA",
            data: { name: "ref", value: Math.random().toString(36).slice(2) },
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  return (
    <React.Fragment>
      <Container fluid>
        <br />
        <ResponseModal isOpen={true} />
        <Row>
          <Col xl="10" ml="10" sm="12">
            <Card className="mini-stat shadow text-dark">
              <CardBody>
                <div className="mb-2">
                  <p
                    className="text-uppercase text-left mb-0 "
                    style={{ color: appData.business.primary_color }}
                  >
                    <b>Transfer to User</b>
                  </p>
                  <hr style={{ borderColor: appData.business.primary_color }} />
                </div>
                {formData.error && (
                  <Alert color="danger">{formData.error}</Alert>
                )}

                <AvForm
                  className="form-horizontal m-t-30"
                  onValidSubmit={handleSubmit}
                >
                  <Card className="mini-stat shadow text-dark">
                    <CardBody>
                      <h6 className="text-uppercase text-dark mt-2 small">
                        Enter Receiver's Phone number or Email
                      </h6>
                      <Row>
                        <Col xs="9" className="mr-0 pr-0">
                          <AvField
                            name="receiver"
                            value={formData.receiver}
                            onChange={(e) => {
                              formOnChange(e);
                            }}
                            placeholder="Receiver"
                            type="text"
                            errorMessage="Enter an email or phone number"
                            required="true"
                          />
                        </Col>
                        <Col xs="3">
                          <Button
                            style={{
                              backgroundColor: appData.business.primary_color,
                            }}
                            className="waves-effect waves-light "
                            onClick={verifyReceiver}
                          >
                            <p className="small mb-0">Verify</p>
                          </Button>
                        </Col>
                      </Row>

                      {formData.receiverName && (
                        <p className="mb-0">
                          Receiver's Name: {formData.receiverName}
                        </p>
                      )}

                      <h6 className="text-uppercase text-dark small">
                        HOW MUCH DO YOU WANT TO Transfer?
                      </h6>
                      <AvField
                        name="amount"
                        value={formData.amount}
                        onChange={(e) => {
                          formOnChange(e);
                        }}
                        placeholder="Amount"
                        type="number"
                        errorMessage="Enter valid amount"
                        validate={{
                          required: { value: true },
                          pattern: {
                            value: "^[0-9]+$",
                            errorMessage: "Only valid amount",
                          },
                        }}
                      />

                      <h6 className="text-uppercase text-dark small">
                        Description
                      </h6>

                      <AvField
                        name="description"
                        value={formData.description}
                        onChange={(e) => {
                          formOnChange(e);
                        }}
                        placeholder="Max 50 characters"
                        type="text"
                      />
                    </CardBody>
                  </Card>
                  <WalletPayment />
                  <Button
                    style={{ backgroundColor: appData.business.primary_color }}
                    className="w-md waves-effect waves-light"
                    type="submit"
                    id="payButton"
                  >
                    Transfer{" "}
                    {formData.amount && (
                      <React.Fragment>
                        ₦{formatCurrency(formData.amount)}
                      </React.Fragment>
                    )}
                  </Button>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(TransferFund);
