import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import AppDataContextProvider from "./contexts/AppDataContext";
import ModalContextProvider from "./contexts/ModalContext";
import UserContextProvider from "./contexts/UserContext";
import FormContextProvider from "./contexts/FormContext";
import Loader from "./components/Loader";

const app = (
  <BrowserRouter>
    <UserContextProvider>
      <AppDataContextProvider>
        <FormContextProvider>
          <ModalContextProvider>
            <App />
            <Loader />
          </ModalContextProvider>
        </FormContextProvider>
      </AppDataContextProvider>
    </UserContextProvider>
  </BrowserRouter>
);

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
