import React, { useContext, useEffect } from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  UserContext,
  ModalContext,
  FormContext,
  AppDataContext,
} from "../../contexts/Contexts";
import PaymentSelector from "../../components/PaymentSelector";
import { formatCurrency, getOS } from "../../helpers/utils";
import ResponseModal from "../../components/ResponseModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import ContactModal from "../../components/ContactModal";

const AirtimePurchase = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { formData, formDispatch } = useContext(FormContext);
  const { user } = useContext(UserContext);
  const { appData } = useContext(AppDataContext);
  document.title = "Purchase Airtime-" + appData.business.name;

  const formOnChange = (e) => {
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  !formData.image && props.history.push("/airtimePricing");

  useEffect(() => {
    document
      .getElementById("phone_number")
      .addEventListener("input", function () {
        formDispatch({
          type: "SET_FORM_DATA",
          data: { name: "phone_number", value: this.value.replace(/\D/g, "") },
        });
      });

    if (!formData.ref) {
      formDispatch({
        type: "SET_FORM_DATA",
        data: { name: "ref", value: Math.random().toString(36).slice(2) },
      });
    }
  }, []);

  const onValidSubmit = (e) => {
    formDispatch({
      type: "SET_FORM_DATA",
      data: {
        name: "confirmationModal",
        value: {
          isOpen: true,
          type: "Airtime Recharge",
          description: formData.network,
          receiver: formData.phone_number,
          amount: formData.amount,
          image: formData.image,
        },
      },
    });
  };

  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: "confirmationModal", value: { isOpen: false, text: "" } },
    });
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Processing your transaction...", isLoading: true },
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    user.data && myHeaders.append("Authorization", "Bearer " + user.token);

    var urlencoded = new URLSearchParams();
    user.data &&
      !formData.atmPayment &&
      urlencoded.append("password", String(formData.password));
    !user.data &&
      formData.atmPayment &&
      urlencoded.append("email", String(formData.email));
    urlencoded.append("network", String(formData.network));
    urlencoded.append("phone_number", String(formData.phone_number));
    urlencoded.append("amount", String(formData.amount));
    urlencoded.append("payment_method", formData.atmPayment ? "ATM" : "WALLET");
    urlencoded.append("source", getOS());
    urlencoded.append("ref", formData.ref);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      //redirect: "follow",
    };
    fetch(
      // formData.atmPayment ? localStorage.getItem('apiURL')+"atm_airtime_purchase" :
      localStorage.getItem("apiURL") + "airtime_purchase",
      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        if (data.status === "success" && formData.atmPayment) {
          window.location = data.data.payment_url;
          return;
        }
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          if (formData.atmPayment) window.location = data.data.payment_url;
          else
            formDispatch({
              type: "SET_FORM_DATA",
              data: {
                name: "responseModal",
                value: { isOpen: true, text: data.message },
              },
            });
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
          formDispatch({
            type: "SET_FORM_DATA",
            data: { name: "ref", value: Math.random().toString(36).slice(2) },
          });
        } else if (data.message === "User Not Found") {
          props.history.push("/signout");
        } else {
          formDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
          formDispatch({
            type: "SET_FORM_DATA",
            data: { name: "ref", value: Math.random().toString(36).slice(2) },
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  const getPhoneNumber = () => {
    window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ command: "getPhoneNumber" })
      );
  };

  return (
    <React.Fragment>
      <ResponseModal isOpen={true} shouldGoHome={true} />
      <ContactModal />

      <ConfirmationModal isOpen={false} onConfirmationClicked={handleSubmit} />
      <br />

      <Row>
        <Col xl="10" ml="10">
          <Card className="mini-stat bg-white text-primary">
            <CardBody>
              <div className="mb-2 text-center">
                <img src={formData.image} alt="logo" height="36"></img>
              </div>
              <hr style={{ borderColor: appData.business.primary_color }} />
              <p
                className="text-uppercase text-left mt-2  "
                style={{ color: appData.business.primary_color }}
              >
                NETWORK: {formData.network && formData.network}
              </p>

              {formData.message && (
                <Card className="mini-stat shadow text-dark border border-danger mt-0 mb-2">
                  <p className="mt-2 mb-2 pl-2">
                    <i className="mdi mdi-alert-circle-outline  text-danger mdi "></i>
                    {formData.message}
                  </p>
                </Card>
              )}
              {formData.error && <Alert color="danger">{formData.error}</Alert>}

              <AvForm
                className="form-horizontal "
                onValidSubmit={onValidSubmit}
              >
                {/* <AvForm className="form-horizontal " onValidSubmit={handleSubmit}> */}

                <Card className="mini-stat shadow text-dark">
                  <CardBody>
                    <h6 className="text-uppercase text-dark mt-2 small">
                      ENTER PHONE NUMBER
                      {user.data && (
                        <label
                          className="ml-1 p-1 text-white rounded-pill "
                          onClick={() => {
                            formDispatch({
                              type: "SET_FORM_DATA",
                              data: {
                                name: "phone_number",
                                value: user.data.phone_number,
                              },
                            });
                          }}
                          style={{
                            backgroundColor: appData.business.primary_color,
                          }}
                        >
                          Paste my number
                        </label>
                      )}
                    </h6>
                    <div>
                      <AvField
                        name="phone_number"
                        value={formData.phone_number}
                        onChange={(e) => {
                          formOnChange(e);
                        }}
                        autocomplete="off"
                        placeholder="Phone number"
                        type="text"
                        errorMessage="Enter valid phone number"
                        required
                        pattern="[0-9]{11}"
                        id="phone_number"
                      />
                      {localStorage.getItem("isMobileApp") && (
                        <div className="d-flex justify-content-end ">
                          <i
                            className={
                              user.data
                                ? "mdi mdi-contacts mdi-24px   contact-button-user"
                                : "mdi mdi-contacts mdi-24px   contact-button"
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              getPhoneNumber();
                            }}
                          />
                        </div>
                      )}
                    </div>
                    {formData.contact_name && (
                      <p className="m-0 p-0 small">{formData.contact_name}</p>
                    )}
                  </CardBody>
                </Card>

                <Card className="mini-stat shadow text-dark">
                  <CardBody>
                    <h6 className="text-uppercase text-dark mt-2 small">
                      How much do you want to recharge?{" "}
                    </h6>
                    <AvField
                      name="amount"
                      value={formData.amount}
                      onChange={(e) => {
                        formOnChange(e);
                      }}
                      placeholder="Min ₦50, Max ₦10,000"
                      type="number"
                      errorMessage="Enter valid amount"
                      validate={{
                        required: { value: true },
                        pattern: {
                          value: "^[0-9]+$",
                          errorMessage: "Only valid amount",
                        },
                      }}
                      maxLength="6"
                    />
                    {formData.atmPrice < 100 && (
                      <p className="mb-0 small">
                        ATM DISCOUNT: {100 - formData.atmPrice}%
                      </p>
                    )}
                    {formData.walletPrice < 100 && (
                      <p className="mb-0 small">
                        WALLET DISCOUNT: {100 - formData.walletPrice}%
                      </p>
                    )}

                    <PaymentSelector />
                  </CardBody>
                </Card>
                {!user.data ? (
                  <Link to="signIn">
                    <Button
                      style={{
                        backgroundColor: appData.business.primary_color,
                      }}
                      className="w-md waves-effect waves-light"
                    >
                      Sign In
                    </Button>
                  </Link>
                ) : (
                  <Button
                    style={{ backgroundColor: appData.business.primary_color }}
                    className="w-md waves-effect waves-light"
                    type="submit"
                    id="payButton"
                  >
                    Pay{" "}
                    {formData.amount && (
                      <React.Fragment>
                        ₦
                        {formatCurrency(
                          formData.atmPayment
                            ? (formData.atmPrice * formData.amount) / 100
                            : (formData.walletPrice * formData.amount) / 100
                        )}
                      </React.Fragment>
                    )}
                  </Button>
                )}
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default withRouter(AirtimePurchase);
