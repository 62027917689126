import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext  } from '../../contexts/Contexts';

const SideNav = () =>{
 
         return (
            <React.Fragment>
                <div id="sidebar-menu" >
                    <ul className="metismenu " id="menu">
                        <li>
                            <Link to="/dashboard" className="waves-effect text-light ">
                                <i className="ti-home"></i><span> Dashboard </span>
                            </Link>
                        </li> 
                        <li>
                            <Link to="/balanceCodes" className="waves-effect text-light">
                                <i className="mdi mdi-cellphone-basic  "></i><span> Balance Codes </span></Link>
                        </li>
                        <li>
                            <Link to="/dataPricing" className="waves-effect text-light"><i className="mdi mdi-cellphone-iphone "></i><span> Buy Data </span></Link>
                        </li>
                        <li>
                            <Link to="/airtimePricing" className="waves-effect text-light"><i className="mdi mdi-phone-in-talk "></i><span> Buy Airtime </span></Link>
                        </li>
                        <li>
                             <Link to="/beneficiaries" className="waves-effect text-light"><i className="mdi mdi-human-male-female"></i><span>Beneficiaries</span></Link>
 
                        </li>
                        <li>
                            <Link to="/cablePricing" className="waves-effect text-light"><i className="mdi mdi-television-classic "></i><span> Cable TV subscriptions </span></Link>
                        </li>
                        <li>
                            <Link to="/electricPricing" className="waves-effect text-light"><i className="mdi mdi-lightbulb-on"></i><span> Pay Electricity Bills </span></Link>
                        </li>
                        <li>
                            <Link to="/transferFund" className="waves-effect text-light"><i className="mdi mdi-bank-transfer"></i><span> User to User Transfer </span></Link>
                        </li>
                        <li>
                            <Link to="/transactions" className="waves-effect text-light"><i className="mdi mdi-view-sequential"></i><span> Transactions </span></Link>
                        </li>
                        {/* <li>
                             <Link to="/transactions" className="waves-effect text-light"><i className="mdi mdi-wallet "></i><span> My Wallet </span></Link> 
                        </li> */}
                        <li>
                            <Link to="/profile" className="waves-effect text-light"><i className="mdi mdi-account-circle"></i><span> My Profile </span></Link>
                        </li>
                        <li>
                            <Link to="/faq" className="waves-effect text-light"><i className=" mdi  mdi-comment-question-outline  "></i><span> Frequently Asked Questions</span></Link>
                        </li>
                        <li>
                            <Link to="/contact" className="waves-effect text-light"><i className="mdi  mdi-headset "></i><span> Contact Us </span></Link>
                        </li>
                    </ul>
                </div>

            </React.Fragment>
        );
    }
 


export default SideNav;