import React, { useContext, useEffect } from "react";
import { Col, Row, Modal } from "reactstrap";
import { AppDataContext, FormContext } from "../contexts/Contexts";
import { useHistory } from "react-router-dom";

const ResponseModal = (props) => {
  const history = useHistory();
  const { formData, formDispatch } = useContext(FormContext);
  const { appData } = useContext(AppDataContext);

  const clearModal = (shouldGoHome = true) => {
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: "responseModal", value: { isOpen: false, text: "" } },
    });
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isUserRefreshed", value: false },
    });
    props.shouldGoHome && history.push("/");
  };

  useEffect(() => {
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: "responseModal", value: { isOpen: false, text: "" } },
    });
  }, []);

  // Function  to enroll tip! check for device capability before enrollment
  const enrollBiometric = () => {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ command: "enrollBiometric", data: formData.password })
    );
  };

  //function to check for enrollment
  const isBiometricEnrolled = () => {
    const enrollmentStatus = window.localStorage.getItem("biometricEnrolled");
    if (enrollmentStatus == null) {
      return false;
    }
    return true;
  };

  return (
    <div>
      {formData.responseModal && (
        <Modal
          isOpen={formData.responseModal.isOpen}
          centered={true}
          toggle={() => {
            clearModal(props.shouldGoHome ? props.shouldGoHome : false);
          }}
        >
          <h1 className="text-center pt-5">
            <i
              className="mdi mdi-check-all  text-success"
              style={{ fontSize: 120 }}
            ></i>
          </h1>
          <p className="text-center text-dark  pb-0 mb-0">
            {formData.responseModal.text}
          </p>
          <p className="text-center text-dark-light  pb-2">
            {formData.responseModal.additionalText}
          </p>
          <Row className="justify-content-center">
            <Col xs="4">
              <div
                className="rounded text-center p-2 mb-4"
                onClick={() => {
                  clearModal(props.shouldGoHome ? props.shouldGoHome : false);
                }}
                style={{
                  cursor: "pointer",
                  backgroundColor: appData.business.primary_color,
                }}
              >
                <p className="mb-0 text-white">GOT IT</p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            {/* display biometric option */}
            {window.localStorage.getItem("isMobileApp") &&
              !isBiometricEnrolled() &&
              (formData.biometricType === "Fingerprint" ||
                formData.biometricType === "FaceId") && (
                <div
                  onClick={(e) => {
                    enrollBiometric();
                  }}
                  className="justify-content-center text-center"
                >
                  <i
                    className={
                      formData.biometricType === "Fingerprint"
                        ? "mdi mdi-fingerprint ml-1 p-1  mt-3 mdi-18px   "
                        : "mdi mdi-face-recognition ml-1 p-1 mt-3 mdi-18px   "
                    }
                  />
                  <p
                    className="text-gray-700   pb-3  "
                    style={{ textDecoration: "underline" }}
                  >
                    {"Enable " + formData.biometricType + " Payment"}
                  </p>
                </div>
              )}
          </Row>
        </Modal>
      )}
    </div>
  );
};
export default ResponseModal;
