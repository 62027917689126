import React, { useContext, useEffect } from "react";

import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { UserContext, FormContext, AppDataContext } from "../contexts/Contexts";

//Images
import { formatCurrency } from "../helpers/utils";

import { AvField } from "availity-reactstrap-validation";
import PasswordInput from "./PasswordInput";

const WalletPayment = () => {
  const { formData, formDispatch } = useContext(FormContext);
  const { user } = useContext(UserContext);
  const { appData } = useContext(AppDataContext);
  const formOnChange = (e) => {
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  if (user.data) {
    return (
      <div>
        <h6 className="small" style={{ color: appData.business.primary_color }}>
          WALLET BALANCE:
        </h6>
        <h6>₦ {formatCurrency(user.data.wallet_balance)}</h6>
        <h6 className="text-uppercase text-dark mt-2 small">
          ENTER YOUR PASSWORD
        </h6>
        <input name="dummyinput" type="text" style={{ display: "none" }} />
        <input name="dummyinput2" type="password" style={{ display: "none" }} />

        <PasswordInput
          errorMessage="Password required"
          required="true"
          autocomplete="new-password"
          placeholder="password"
        />
      </div>
    );
  } else
    return (
      <div>
        <h6 className="text-uppercase text-dark mt-2 small">
          You need to sign in to pay with wallet
        </h6>
      </div>
    );
};

const AtmPayment = () => {
  const { formData, formDispatch } = useContext(FormContext);
  const { user } = useContext(UserContext);
  const formOnChange = (e) => {
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };
  if (user.data) {
    return <div></div>;
  } else {
    return (
      <>
        <p className="text-uppercase text-left mt-2 small ">
          You need to sign in to pay with your ATM
        </p>
      </>
    );
  }
};

const PaymentSelector = () => {
  const { formData, formDispatch } = useContext(FormContext);
  const { user } = useContext(UserContext);
  const { appData } = useContext(AppDataContext);

  const paymentToggle = (toggle) => {
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: "atmPayment", value: toggle },
    });
  };

  useEffect(() => {
    user.data ? paymentToggle(false) : paymentToggle(true);
  }, []);
  return (
    <React.Fragment>
      <p className="text-uppercase text-left mt-2 small ">
        SELECT YOUR PAYMENT METHOD
      </p>
      <Row>
        <Col xs="6">
          <div
            className="rounded text-center p-2"
            onClick={() => {
              paymentToggle(false);
            }}
            style={{
              cursor: "pointer",
              border:
                "1px solid " +
                (!formData.atmPayment
                  ? appData.business.primary_color
                  : "#D0D0D0"),
            }}
          >
            <p
              className="mb-0"
              style={{
                color: !formData.atmPayment && appData.business.primary_color,
              }}
            >
              WALLET
            </p>
          </div>
        </Col>

        <Col xs="6">
          <div
            className="rounded text-center p-2"
            onClick={() => {
              paymentToggle(true);
            }}
            style={{
              cursor: "pointer",
              border:
                "1px solid " +
                (formData.atmPayment
                  ? appData.business.primary_color
                  : "#D0D0D0"),
            }}
          >
            <p
              className="mb-0"
              style={{
                color: formData.atmPayment && appData.business.primary_color,
              }}
            >
              ATM
            </p>
          </div>
        </Col>
      </Row>

      <div>{!formData.atmPayment ? <WalletPayment /> : <AtmPayment />}</div>
    </React.Fragment>
  );
};

export default withRouter(PaymentSelector);
export { WalletPayment };
