import React, { useContext, useEffect } from "react";

import { FormContext } from "../contexts/Contexts";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { AppDataContext } from "../contexts/Contexts";

export default function ConfirmationModel(props) {
  const { formData, formDispatch } = useContext(FormContext);
  const { appData } = useContext(AppDataContext);

  const toggle = () => {
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: "contacts", value: false },
    });
  };
  return (
    <>
      {formData.contacts && (
        <Modal
          isOpen={formData.contacts}
          backdrop="static"
          keyboard={false}
          centered={true}
          className="mt-5 mb-5 ml-2 mr-2"
        >
          <div>
            <div
              className={
                "justify-center items-center flex overflow-x-hidden  fixed inset-0 z-50 outline-none focus:outline-none w-full"
              }
            >
              <div className={"relative w-full my-6 mx-auto max-w-sm  px-4 "}>
                <div className="   overflow-hidden rounded-2xl   ">
                  <h5
                    className="text-center p-2 "
                    style={{ color: appData.business.primary_color }}
                  >
                    SELECT CONTACT
                  </h5>
                  <div className="p-1">
                    <input
                      className=" rounded-l  mb-2   border  bg-white    "
                      type="text"
                      name="search"
                      placeholder="search"
                      style={{ width: "100%" }}
                      value={formData.search}
                      onChange={(e) => {
                        window.ReactNativeWebView &&
                          window.ReactNativeWebView.postMessage(
                            JSON.stringify({
                              command: "getPhoneNumber",
                              data: e.target.value,
                            })
                          );
                      }}
                    />
                    <div style={{ maxHeight: "50vh", overflowY: "auto" }}>
                      {formData.contacts &&
                        JSON.parse(formData.contacts).map((contact) => {
                          return (
                            <div
                              onClick={() => {
                                formDispatch({
                                  type: "SET_FORM_DATA",
                                  data: {
                                    name: "phone_number",
                                    value: contact.number,
                                  },
                                });

                                formDispatch({
                                  type: "SET_FORM_DATA",
                                  data: {
                                    name: "contact_name",
                                    value: contact.name,
                                  },
                                });
                                formDispatch({
                                  type: "SET_FORM_DATA",
                                  data: {
                                    name: "contacts",
                                    value: null,
                                  },
                                });
                              }}
                            >
                              <p className="m-0 mt-2 ">
                                <b>{contact.name}</b>
                              </p>
                              <p
                                className="pt-1 m-0  "
                                style={{ fontSize: 11 }}
                              >
                                {contact.number}
                              </p>
                              <hr
                                style={{
                                  height: "1px",
                                  border: "none",
                                  backgroundColor: "gray",
                                  padding: 0,
                                  margin: 0,
                                }}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="  border-0   text-center text-xs  justify-center items-center p-2 text-white "
                style={{
                  width: "100%",
                  background: appData.business.primary_color,
                }}
                onClick={(e) => {
                  formDispatch({
                    type: "SET_FORM_DATA",
                    data: {
                      name: "contacts",
                      value: null,
                    },
                  });
                }}
              >
                Close
              </button>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black dark:bg-white"></div>
          </div>
        </Modal>
      )}
    </>
  );
}
