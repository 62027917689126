import React, { useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";

import Topbar from "./Topbar";
import Sidebar from "./Sidebar";
import FootBar from "./FootBar";
import Footer from "./Footer";
import {
  UserContext,
  ModalContext,
  FormContext,
  AppDataContext,
} from "../../contexts/Contexts";
import { useSwipeable, Swipeable } from "react-swipeable";

// render if Auth Layout
const Layout = (props) => {
  document.body.classList.remove("bg-primary");
  const { formData, formDispatch } = useContext(FormContext);
  const { appData } = useContext(AppDataContext);

  const unlisten = props.history.listen((location, action) => {
    if (
      formData.currentPage !== window.location.pathname &&
      window.location.pathname !== "/dataPurchase" &&
      window.location.pathname !== "/dataPricing" &&
      window.location.pathname !== "/airtimePurchase" &&
      window.location.pathname !== "/airtimePricing" &&
      window.location.pathname !== "/cablePurchase" &&
      window.location.pathname !== "/cablePricing" &&
      window.location.pathname !== "/electricPurchase" &&
      window.location.pathname !== "/electricPricing" &&
      window.location.pathname !== "/airtimeToCashPurchase" &&
      window.location.pathname !== "/ResetPassword"
    ) {
      formDispatch({
        type: "CLEAR_FORM_DATA",
      });
      formDispatch({
        type: "SET_FORM_DATA",
        data: { name: "currentPage", value: window.location.pathname },
      });
      window.scrollTo(0, 0);
      const width = window.innerWidth;
      width < 768 && document.body.classList.toggle("enlarged", true);
    }

    //  console.log("on route change");
  });

  const processMessage = (message) => {
    if (!JSON.stringify(message.data).includes("command")) return;
    const msg = JSON.parse(message.data);
    if (msg.command === "ping") {
      localStorage.setItem("isMobileApp", true);
    } else if (msg.command === "biometricType") {
      return formDispatch({
        type: "SET_FORM_DATA",
        data: {
          name: "biometricType",
          value: msg.data,
        },
      });
    }
    if (msg.command === "enrolledBiometric") {
      localStorage.setItem("biometricEnrolled", formData.biometricType);
      return formDispatch({
        type: "SET_FORM_DATA",
        data: {
          name: "biometricEnrolled",
          value: true,
        },
      });
    } else if (msg.command === "password") {
      formDispatch({
        type: "SET_FORM_DATA",
        data: {
          name: "password",
          value: msg.data,
        },
      });
      window.document.getElementById("payButton").click();
    } else if (msg.command === "contact") {
      formDispatch({
        type: "SET_FORM_DATA",
        data: {
          name: "contacts",
          value: msg.data,
        },
      });
    }
  };

  useEffect(() => {
    // // Function receiving Data from React Native use both window and document event listener for iOS and Android to work
    if (!formData.listenerAdded) {
      formDispatch({
        type: "SET_FORM_DATA",
        data: {
          name: "listenerAdded",
          value: true,
        },
      });

      window.addEventListener("message", (message) => {
        processMessage(message);
      });
      document.addEventListener("message", (message) => {
        processMessage(message);
      });
      //check if it is mobile App
      window.ReactNativeWebView &&
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ command: "ping" })
        );
    }
  }, []);

  return (
    <React.Fragment>
      <Swipeable
        delta="50"
        trackMouse={true}
        onSwipedRight={(e) => {
          document.body.classList.toggle("enlarged", false);
        }}
        onSwipedLeft={(e) => {
          document.body.classList.toggle("enlarged", true);
        }}
      >
        <div id="wrapper">
          <Topbar />
          <Sidebar />
          <div className="content-page">
            <div
              className="content"
              onClick={() => {
                window.innerWidth < 768 &&
                  document.body.classList.toggle("enlarged", true);
              }}
            >
              {props.children}
            </div>
            <Footer />
          </div>
          <FootBar />
        </div>
      </Swipeable>
    </React.Fragment>
  );
};

export default withRouter(Layout);
