import React, { useContext, useState, useEffect } from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { Collapse, CardHeader } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  UserContext,
  ModalContext,
  FormContext,
  AppDataContext,
} from "../../contexts/Contexts";
import PasswordInput from "../../components/PasswordInput";
import ResponseModal from "../../components/ResponseModal";

function Kyc() {
  const { loaderDispatch } = useContext(ModalContext);
  const { formData, formDispatch } = useContext(FormContext);
  const { user } = useContext(UserContext);
  const { appData } = useContext(AppDataContext);

  const formOnChange = (e) => {
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  useEffect(() => {
    if (!formData.ref) {
      formDispatch({
        type: "SET_FORM_DATA",
        data: { name: "ref", value: Math.random().toString(36).slice(2) },
      });

      document.getElementById("otp").addEventListener("input", function () {
        formDispatch({
          type: "SET_FORM_DATA",
          data: {
            name: "otp",
            value: this.value.replace(/\D/g, ""),
          },
        });
      });

      window.scrollTo(0, 0);
    }
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "verifying phone number...", isLoading: true },
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    user.data && myHeaders.append("Authorization", "Bearer " + user.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("otp", String(formData.otp));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      //redirect: "follow",
    };
    fetch(localStorage.getItem("apiURL") + "verify_phone", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        console.log(data);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        formDispatch({
          type: "SET_FORM_DATA",
          data: { name: "ref", value: Math.random().toString(36).slice(2) },
        });
        if (data.status === "success") {
          formDispatch({
            type: "SET_FORM_DATA",
            data: {
              name: "responseModal",
              value: { isOpen: true, text: data.message },
            },
          });
        } else if (data.errors) {
          formDispatch({
            type: "SET_FORM_DATA",
            data: { name: "ref", value: Math.random().toString(36).slice(2) },
          });
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
        } else {
          formDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  const getOTP = (e) => {
    e.preventDefault();
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Requesting for OTP...", isLoading: true },
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    user.data && myHeaders.append("Authorization", "Bearer " + user.token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    fetch(localStorage.getItem("apiURL") + "otp", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        console.log(data);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        formDispatch({
          type: "SET_FORM_DATA",
          data: { name: "ref", value: Math.random().toString(36).slice(2) },
        });
        if (data.status === "success") {
          formDispatch({
            type: "SET_FORM_DATA",
            data: { name: "otpRetrieved", value: true },
          });
          formDispatch({
            type: "SET_ERROR",
            data: "",
          });
        } else if (data.errors) {
          formDispatch({
            type: "SET_FORM_DATA",
            data: { name: "ref", value: Math.random().toString(36).slice(2) },
          });
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
        } else {
          formDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };
  document.title = "Phone Number Verification- " + appData.business.name;
  return (
    <React.Fragment>
      <ResponseModal isOpen={true} shouldGoHome={true} />

      <Container fluid style={{ minHeight: "100vh" }}>
        <h5 className="font-bold text-sm mt-4 md:text-lg">
          Verify Your Phone Number{" "}
          <span className="inline-flex items-center justify-center w-6 h-6 me-2 text-sm font-semibold text-blue-800 bg-blue-100 rounded-full dark:bg-gray-700 dark:text-blue-400">
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
              style={{ height: "3vh", color: appData.business.primary_color }}
            >
              <path
                fill="currentColor"
                d="m18.774 8.245-.892-.893a1.5 1.5 0 0 1-.437-1.052V5.036a2.484 2.484 0 0 0-2.48-2.48H13.7a1.5 1.5 0 0 1-1.052-.438l-.893-.892a2.484 2.484 0 0 0-3.51 0l-.893.892a1.5 1.5 0 0 1-1.052.437H5.036a2.484 2.484 0 0 0-2.48 2.481V6.3a1.5 1.5 0 0 1-.438 1.052l-.892.893a2.484 2.484 0 0 0 0 3.51l.892.893a1.5 1.5 0 0 1 .437 1.052v1.264a2.484 2.484 0 0 0 2.481 2.481H6.3a1.5 1.5 0 0 1 1.052.437l.893.892a2.484 2.484 0 0 0 3.51 0l.893-.892a1.5 1.5 0 0 1 1.052-.437h1.264a2.484 2.484 0 0 0 2.481-2.48V13.7a1.5 1.5 0 0 1 .437-1.052l.892-.893a2.484 2.484 0 0 0 0-3.51Z"
              />
              <path
                fill="#fff"
                d="M8 13a1 1 0 0 1-.707-.293l-2-2a1 1 0 1 1 1.414-1.414l1.42 1.42 5.318-3.545a1 1 0 0 1 1.11 1.664l-6 4A1 1 0 0 1 8 13Z"
              />
            </svg>
          </span>
        </h5>
        <p className="  md:text-xl mt-2 text-dark">
          We need to verify your phone number. This will help us keep your
          account safe and secure. Wrong number? You can change your phone
          number on the profile menu. You need to verify your phone number
          before 1/3/2024
        </p>
        <div className="grid grid-cols-4 gap-6 ">
          {formData.error && <Alert color="danger">{formData.error}</Alert>}
          <AvForm
            className="form-horizontal m-t-30"
            onValidSubmit={handleSubmit}
          >
            <AvField
              name="phone_number"
              label="Phone Number"
              value={user.data.phone_number}
              onChange={(e) => {
                // formOnChange(e);
              }}
              placeholder="Enter your phone number"
              disabled
              type="number"
              required
              validate={{
                pattern: {
                  value: "^[0-9]+$",
                  errorMessage: "Enter valid phone number",
                },
              }}
            />

            <Row>
              <Col xs="8" className="mr-0 pr-0">
                <AvField
                  name="otp"
                  value={formData.otp}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="OTP"
                  autoComplete="off"
                  type="number"
                  errorMessage="Enter valid OTP"
                  validate={{
                    required: { value: true },
                    pattern: {
                      value: "^[0-9]+$",
                      errorMessage: "Enter valid OTP",
                    },
                  }}
                  maxLength="20"
                />
              </Col>
              <Col xs="4">
                <Button
                  style={{
                    backgroundColor: appData.business.primary_color,
                  }}
                  className="waves-effect waves-light "
                  onClick={(e) => {
                    getOTP(e);
                  }}
                >
                  <p className="small mb-0">
                    {formData.otpRetrieved ? `OTP Sent!` : `Get OTP`}
                  </p>
                </Button>
              </Col>
            </Row>

            <Row className="form-group m-t-20">
              <Col sm="6" className="text-left mb-3">
                <Button
                  style={{ backgroundColor: appData.business.primary_color }}
                  className="w-md waves-effect waves-light"
                  type="submit"
                  onClick={() => handleSubmit()}
                >
                  Verify Phone Number
                </Button>
              </Col>
            </Row>
          </AvForm>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default Kyc;
