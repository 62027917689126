import React, { createContext, useReducer, useEffect } from 'react';
import { modalReducer } from '../reducers/ModalReducer';

export const ModalContext = createContext();

const ModalContextProvider = (props) => {
  const [loader, loaderDispatch] = useReducer(modalReducer, {text:"" , isLoading:false})
 
  return (
    <ModalContext.Provider value={{ loader, loaderDispatch }}>
      {props.children}
    </ModalContext.Provider>
  );
}
 
export default ModalContextProvider;