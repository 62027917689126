import React, { useContext } from "react";
import { useHistory, Link } from "react-router-dom";

import {
    Row,
    Col,
    Card,
    CardBody,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import {
    FormContext,
} from "../contexts/Contexts";

//Images
import dstv from "../images/dstv.png";
import gotv from "../images/gotv.png";
import startimes from "../images/startimes.png";
import { formatCurrency } from "../helpers/utils";

import { AppDataContext } from "../contexts/Contexts";


const CablePlan = (props) => {
    const history = useHistory();
    const { formDispatch } = useContext(FormContext);

    const onPlanSelect = (cablePlan, tv, image) => {
        formDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'cablePlan', value: cablePlan },

        });
        formDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'tv', value: tv },

        });
        formDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'image', value: image },
        });

        history.push('/cablePurchase')
    }

    return (

        <Link className="text-dark "><Row style={{ cursor: 'pointer' }} onClick={() => { onPlanSelect(props.values, props.tv, props.image) }}>
            <Col xl="6" md="6" sm="6" xs="6">
                <p className="text-truncate"> {props.values.name}</p>
            </Col>
            <Col xl="3" md="3" sm="3" xs="3">
                <p > ₦{formatCurrency(props.values.wallet_price)}</p>
            </Col>
            <Col xl="3" md="3" sm="3" xs="3">
                <p> ₦{formatCurrency(props.values.atm_price)}</p>
            </Col>
        </Row>
        </Link>
    );
};

const CablePrice = () => {
    const { appData } = useContext(AppDataContext);
    const { formData,formDispatch } = useContext(FormContext);

    
    const onTvSelect = (tv) => {
        formDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'tv', value: tv },
            
        });
        // document.getElementById('CablePrice').scrollIntoView({behavior: "smooth", block: "start"}) 
        let el = document.getElementById('cable')
        let elScrollOffset = el.getBoundingClientRect().top
        let scrollOffset = window.pageYOffset || document.documentElement.scrollTop
        let padding = 90
        let target = elScrollOffset + scrollOffset - padding
        window.scrollTo({
          top: target,
          behavior: 'smooth'
        })
        window.onscroll = e => {
          let currentScrollOffset = window.pageYOffset || document.documentElement.scrollTop
          // Scroll reach the target
          if (currentScrollOffset*1 === target*1) {              
        el.focus()
            window.onscroll = null // remove listener
           
          }
        }
      
    }

    const DSTVCablePlans = appData.cable_plans.DSTV.map((cablePlan) => (
        <CablePlan image={dstv} key={cablePlan.id} values={cablePlan} tv="DSTV" />
    ));
    const GOTVCablePlans = appData.cable_plans.GOTV.map((cablePlan) => (
        <CablePlan image={gotv} key={cablePlan.id} values={cablePlan} tv="GOTV" />
    ));
    const STARTIMESCablePlans = appData.cable_plans.STARTIMES.map((cablePlan) => (
        <CablePlan image={startimes} key={cablePlan.id} values={cablePlan} tv="STARTIMES" />
    ));
     
    const tvArray = [];
    if(appData.settings.cable_master*1 ===0) {
    Object.keys(appData.cable_plans.DSTV).length>0 && tvArray.push( { cablePlans: DSTVCablePlans, image: dstv, tv:'DSTV' })
    Object.keys(appData.cable_plans.GOTV).length>0  && tvArray.push( { cablePlans: GOTVCablePlans, image: gotv, tv:'GOTV' })
    Object.keys(appData.cable_plans.STARTIMES).length>0  && tvArray.push( { cablePlans: STARTIMESCablePlans, image: startimes , tv:'STARTIMES' })
    }

    return (
        appData.settings.cable_master*1 ===0 && (
        <React.Fragment>
              {(appData.cable_plans.DSTV||appData.cable_plans.GOTV||
        appData.cable_plans.STARTIMES) && (
            <Row id='cable' className="m-1" style={{scrollMargin:90, scrollSnapMargin:90}}>
            <Col xl="10" md="10">
                <Card className="mini-stat bg-white text-primary shadow pl-3 pr-3">
                            <div className="mb-3">
                                <p className="text-uppercase text-left mt-2  " style={{ color: appData.business.primary_color }}><b>Cable TV Subscription</b></p>
                                <hr style={{ borderColor: appData.business.primary_color }} />

                            </div>

                            <Row className="justify-content-center">
                            {Object.keys(appData.cable_plans.DSTV).length>0 && formData.tv!=='DSTV' &&
                            (
                                <Card className="mini-stat p-2 m-2 bg-white-50 text-dark shadow">
                                <Col  style={{cursor:'pointer'}} xl="3" xs="3" className="text-center text-dark"  onClick={() => { onTvSelect('DSTV') }}>
                            <img src={dstv}  height="36" alt="logo"></img></Col>
                            </Card>
                                )
                            }
                            {Object.keys(appData.cable_plans.GOTV).length>0 && formData.tv!=='GOTV' &&
                            (
                                <Card className="mini-stat p-2 m-2 bg-white-50 text-dark shadow">
                                <Col  style={{cursor:'pointer'}} xl="3" xs="3" className="text-center text-dark"  onClick={() => { onTvSelect('GOTV') }}>
                            <img src={gotv}  height="36" alt="logo"></img></Col>
                            </Card>

                            )}
                            {Object.keys(appData.cable_plans.STARTIMES).length>0 &&  formData.tv!=='STARTIMES' &&   
                            (
                                <Card className="mini-stat p-2 m-2 bg-white-50 text-dark shadow">
                                <Col  style={{cursor:'pointer'}} xl="3" xs="3" className="text-center text-dark"  onClick={() => { onTvSelect('STARTIMES') }}>
                            <img src={startimes}  height="36" alt="logo"></img></Col>
                            </Card>

                            )}
                            </Row>

                            <Row className="justify-content-center mt-3">
                                {tvArray.map((array) => {
                                    return (
                                        formData.tv===array.tv ?
                                    (  <Col xl="6" md="6" className="text-left text-truncate " style={{ fontSize: '12px' }}>
                                          
                                                    <div className="mb-2 text-center">
                                                        <img src={array.image} alt="logo" height="44" style={{cursor:'pointer'}} onClick={() => { onTvSelect('')}}></img>
                                                    </div>
                                                    <Row className="pb-0 mb-0 ">
                                                        <Col xl="6" md="6" sm="6" xs="6" style={{ color: appData.business.primary_color }}>
                                                            CABLE PLAN
                                                        </Col>
                                                        <Col xl="3" md="3" sm="3" xs="3" style={{ color: appData.business.primary_color }}>
                                                            WALLET
                                                        </Col>
                                                        <Col xl="3" md="3" sm="3" xs="3" style={{ color: appData.business.primary_color }}>
                                                            ATM
                                                        </Col>
                                                    </Row>
                                                    <hr className="mt-0" />
                                                    {array.cablePlans}
                                              
                                        </Col>
                                   )
                                   :
                                   (<div></div>)
                                   );
                                })}
                            </Row>
                    </Card>
                </Col>
            </Row>)
            }
        </React.Fragment>)
    );
};

export default withRouter(CablePrice);
