import React, { createContext, useReducer } from 'react';
import { formReducer } from '../reducers/FormReducer';

export const FormContext = createContext();

const FormContextProvider = (props) => {
  const [formData, formDispatch] = useReducer(formReducer, {})

  return (
    <FormContext.Provider value={{ formData, formDispatch }}>
      {props.children}
    </FormContext.Provider>
  );
}
 
export default FormContextProvider;