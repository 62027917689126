import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Row, Col, Card, CardBody } from "reactstrap";
import { withRouter } from "react-router-dom";
import { FormContext } from "../contexts/Contexts";
import { formatCurrency, getOS } from "../helpers/utils";

import { UserContext, AppDataContext } from "../contexts/Contexts";
import fundWalletATM from "../pages/FundWallet/fundWalletATM";
import ReactCardFlip from "react-card-flip";
import chip from "../images/chip.png";
import ResponseModal from "../components/ResponseModal";
import Sliders from "./Slider";

const DataPrice = (props) => {
  const { user, userDispatch } = useContext(UserContext);
  const { appData, dispatch } = useContext(AppDataContext);
  const { formData, formDispatch } = useContext(FormContext);
  const [showBalance, setShowBalance] = useState(true);

  useEffect(() => {
    !formData.isUserRefreshed &&
      !formData.isUserRefreshing &&
      user.data &&
      refreshUser();
  }, []);

  const fundWallet = () => {
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isFlipped", value: !formData.isFlipped },
    });
  };

  const getAppData = () => {
    fetch(localStorage.getItem("apiURL") + "data")
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: "STORE_APP_DATA",
          appData: {
            is_mobile: appData.is_mobile,
            timestamp: new Date().getTime(),
            ...response.data,
          },
        });
      });
  };

  const refreshUser = () => {
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isUserRefreshed", value: false },
    });

    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isUserRefreshing", value: true },
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + user.token);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      //redirect: "follow",
    };
    fetch(
      localStorage
        .getItem("apiURL")
        .substr(0, localStorage.getItem("apiURL").length - 1),
      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        console.log(data);
        if (data.status === "success") {
          console.log(data);
          userDispatch({ type: "UPDATE_USER", action: data });
        } else if (
          data.message === "Token Expired" ||
          data.message === "User Not Found"
        ) {
          props.history.push("/signout");
        }

        formDispatch({
          type: "SET_FORM_DATA",
          data: { name: "isUserRefreshed", value: true },
        });
      });

    //  getAppData();
    if (!appData.settings) getAppData();
    else if (!appData.timestamp) getAppData();
    else if (new Date().getTime() - appData.timestamp > 180000) getAppData();
  };

  const showBalanceToggle = () => {
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: "balanceToggle", value: true },
    });
  };

  useEffect(() => {
    window.localStorage.setItem("Show_My_Balance", JSON.stringify(showBalance));
  }, [showBalance]);

  return (
    <div className="font-montserrat ">
      <ResponseModal isOpen={true} />
      <ReactCardFlip
        isFlipped={formData.isFlipped}
        flipDirection="horizontal"
        flipSpeedBackToFront="0.8"
        flipSpeedFrontToBack="0.8"
      >
        <div style={{ maxWidth: "400px" }}>
          <div className="mb-2">
            <img
              src={appData.business.website + "/logo.png"}
              alt="logo"
              className="float-right mr-3 mt-2"
              style={{ height: "50px", width: "50px" }}
            />
            <div
              className="mini-stat  shadow text-white rounded-lg"
              style={{ background: appData.business.primary_color }}
            >
              <div
                className="mini-stat   text-white rounded-lg pl-3 pr-3 pt-1 pb-1"
                style={{
                  background:
                    "linear-gradient(230deg, rgba(0,0,0,0.4) , rgba(120,120,120,0.2), rgba(255,255,255,0) )",
                }}
              >
                <h6
                  className=" text-uppercase font-montserrat  "
                  style={{ fontSize: 14, fontWeight: "bolder" }}
                >
                  {showBalance ? "Wallet " : "Phone Number "}
                  <label
                    className=" inline "
                    onClick={() => {
                      setShowBalance(!showBalance);
                    }}
                  >
                    {showBalance ? (
                      <i className="mdi mdi-eye-off-outline mdi-18px  " />
                    ) : (
                      <i className="mdi mdi-eye-outline mdi-18px " />
                    )}
                  </label>
                </h6>
                <h5 className="font-montserrat m-0 p-0">
                  <label className=" m-0 p-0">
                    {showBalance
                      ? `₦ ${formatCurrency(user.data.wallet_balance)}`
                      : user.data.phone_number}
                  </label>
                  {formData.isUserRefreshed ? (
                    <i
                      className="mdi mdi-autorenew mdi-18px ml-1 m-0 p-0"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        refreshUser();
                      }}
                    ></i>
                  ) : (
                    <i className="mdi mdi-autorenew mdi-18px ml-1 mdi-spin m-0 p-0"></i>
                  )}{" "}
                </h5>
                <p className="p-0 m-0 pt-1 ">
                  <img src={chip} width={45} />
                  <label
                    className=" ml-2 pl-2 pr-2  pt-1 pb-1  rounded-pill "
                    style={{
                      background: "rgba(255, 255, 255, 0.2)",
                      fontWeight: "bold",
                      fontSize: 12,
                    }}
                    onClick={fundWallet}
                  >
                    + Fund Wallet
                  </label>
                  <label
                    className=" ml-2 pl-1 pr-1 pt-1 pb-1 rounded-pill   "
                    style={{
                      background: "rgba(255, 255, 255, 0.2)",
                      cursor: "pointer",
                      fontSize: 12,
                    }}
                    onClick={() => {
                      props.history.push("/selffix");
                    }}
                  >
                    <i className=" mdi mdi-wallet" /> Fix Funding Issues
                    <b className="blink_me  rounded rounded-pill bg-danger pl-1 pr-1 mr-1 ml-1 pt-0 mt-0 text-small">
                      new
                    </b>
                  </label>
                </p>

                <div
                  className="mt-1 mb-2  "
                  style={{
                    letterSpacing: "2px",
                    display: "inline-block",
                    verticalAlign: "middle",
                    alignItems: "center",
                  }}
                >
                  <label className="p-0 m-0">{user.data.phone_number}</label>
                  {appData.settings.data_referral_bonus * 1 === 0 && (
                    <label
                      className=" ml-2 pl-1 pr-1 mr-0 rounded-pill "
                      style={{
                        background: "rgba(255, 255, 255, 0.2)",
                        cursor: "pointer",
                        fontSize: 12,
                      }}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          "https://" +
                            window.location.host +
                            "/signup/" +
                            user.data.phone_number
                        );
                        formDispatch({
                          type: "SET_FORM_DATA",
                          data: {
                            name: "responseModal",
                            value: {
                              isOpen: true,
                              text:
                                "https://" +
                                window.location.host +
                                "/signup/" +
                                user.data.phone_number,
                              additionalText: " copied!",
                            },
                          },
                        });
                      }}
                    >
                      <i className=" mdi mdi-content-copy" /> Copy Invite Link
                    </label>
                  )}
                </div>
                <p
                  className="pt-0 mt-0 pb-0 mb-0 text-uppercase"
                  style={{ wordSpacing: "5px", letterSpacing: "2px" }}
                >
                  {" "}
                  {user.data &&
                    `${user.data.firstname} ${user.data.othername} ${user.data.lastname}`}{" "}
                  {user.data && user.data.kyc_verified_at && (
                    <span className="inline-flex items-center justify-center w-6 h-6 me-2 text-sm font-semibold text-blue-800 bg-blue-100 rounded-full dark:bg-gray-700 dark:text-blue-400">
                      <svg
                        className="w-3 h-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        style={{
                          height: "3vh",
                          color: appData.business.primary_color,
                        }}
                      >
                        <path
                          fill="currentColor"
                          d="m18.774 8.245-.892-.893a1.5 1.5 0 0 1-.437-1.052V5.036a2.484 2.484 0 0 0-2.48-2.48H13.7a1.5 1.5 0 0 1-1.052-.438l-.893-.892a2.484 2.484 0 0 0-3.51 0l-.893.892a1.5 1.5 0 0 1-1.052.437H5.036a2.484 2.484 0 0 0-2.48 2.481V6.3a1.5 1.5 0 0 1-.438 1.052l-.892.893a2.484 2.484 0 0 0 0 3.51l.892.893a1.5 1.5 0 0 1 .437 1.052v1.264a2.484 2.484 0 0 0 2.481 2.481H6.3a1.5 1.5 0 0 1 1.052.437l.893.892a2.484 2.484 0 0 0 3.51 0l.893-.892a1.5 1.5 0 0 1 1.052-.437h1.264a2.484 2.484 0 0 0 2.481-2.48V13.7a1.5 1.5 0 0 1 .437-1.052l.892-.893a2.484 2.484 0 0 0 0-3.51Z"
                        />
                        <path
                          fill="#fff"
                          d="M8 13a1 1 0 0 1-.707-.293l-2-2a1 1 0 1 1 1.414-1.414l1.42 1.42 5.318-3.545a1 1 0 0 1 1.11 1.664l-6 4A1 1 0 0 1 8 13Z"
                        />
                      </svg>
                    </span>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div style={{ maxWidth: "400px" }}>
          <div className="mb-2">
            <div
              className="p-5 rounded-circle"
              style={{
                width: "30%",
                position: "absolute",
                left: "-1%",
                top: "-45%",
                background: "rgba(255, 255, 255, 0.1)",
              }}
            ></div>
            <div
              className="mini-stat  shadow text-white rounded-lg pt-2"
              style={{ backgroundColor: appData.business.primary_color }}
            >
              <p
                className="text-uppercase mt-1 mb-2 bg-dark p-2  "
                style={{ fontSize: 12, cursor: "pointer" }}
                onClick={fundWallet}
              >
                {" "}
                <i className="mdi mdi-chevron-left " /> Fund Wallet (₦
                {formatCurrency(user.data.wallet_balance)})
              </p>
              <p className="text-uppercase mt-1 mr-2 ml-2  small">
                Select a funding method{" "}
              </p>
              <Row className="pl-3 pr-3 pb-4 ">
                <Col xs="4">
                  <div
                    className="text-uppercase text-center rounded  p-1 text-white"
                    onClick={() => {
                      props.history.push("/fundWalletATM");
                    }}
                    style={{
                      fontSize: 10,
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                      cursor: "pointer",
                    }}
                  >
                    ATM
                    <p>
                      {appData.master_settings.fund_status_atm * 1 === 0 ? (
                        <i className="mdi mdi-18px  mdi-shield-check-outline "></i>
                      ) : (
                        <i className="mdi mdi-18px  mdi-alert-decagram "></i>
                      )}
                    </p>
                  </div>
                </Col>
                {appData.settings.use_auto_agent * 1 === 0 && (
                  <Col xs="4">
                    <div
                      className="text-uppercase text-center   rounded p-1 text-white"
                      onClick={() => {
                        props.history.push("/fundWalletAuto");
                      }}
                      style={{
                        fontSize: 10,
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                        cursor: "pointer",
                      }}
                    >
                      Auto Agent{" "}
                      <p>
                        {appData.master_settings.fund_status_auto * 1 === 0 ? (
                          <i className="mdi mdi-18px  mdi-shield-check-outline "></i>
                        ) : (
                          <i className="mdi mdi-18px  mdi-alert-decagram "></i>
                        )}
                      </p>
                    </div>
                  </Col>
                )}
                <Col xs="4">
                  <div
                    className="text-uppercase text-center small rounded p-1 text-white"
                    onClick={() => {
                      props.history.push("/fundWalletBank");
                    }}
                    style={{
                      fontSize: 10,
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                      cursor: "pointer",
                    }}
                  >
                    Monnify{" "}
                    <p>
                      {appData.master_settings.fund_status_bank * 1 === 0 ? (
                        <i className="mdi mdi-18px  mdi-shield-check-outline "></i>
                      ) : (
                        <i className="mdi mdi-18px  mdi-alert-decagram "></i>
                      )}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </ReactCardFlip>
      <Sliders />

      {user.data && !user.data.phone_verified_at && (
        <div
          className="w-full p-2 mt-2 mb-0 rounded-lg  relative   lg:px-7  dark:text-white lg:p-10"
          style={{
            backgroundColor: `${appData.business.secondary_color}20`,
          }}
          onClick={() => {
            props.history.push("/verifyPhone");
          }}
        >
          <p className=" m-0 p-0" style={{ fontWeight: "bold", fontSize: 13 }}>
            Add more Security{" "}
            <label className="rounded bg-danger text-white pt-0 pb-0 mt-0 mb-0 pl-1 pr-1">
              PHONE NOT VERIFIED
            </label>
          </p>
          <p className="m-0 p-0" style={{ fontSize: 13 }}>
            Verify your phone number to add more security to your account.{" "}
            <label
              className="inline-block mb-0 pb-0"
              style={{
                color: appData.business.primary_color,
                cursor: "pointer",
                fontWeight: "bold",
              }}
            >
              Verify Now ➔
            </label>
          </p>
        </div>
      )}

      {/* {user.data && !user.data.kyc_verified_at && (
        <div
          className="w-full p-2 mt-2 rounded-lg  relative   lg:px-7  dark:text-white lg:p-10"
          style={{
            backgroundColor: `${appData.business.primary_color}20`,
          }}
          onClick={() => {
            props.history.push("/kyc");
          }}
        >
          <p className=" m-0 p-0" style={{ fontWeight: "bold", fontSize: 13 }}>
            Unlock full features{" "}
            <label className="rounded bg-danger text-white pt-0 pb-0 mt-0 mb-0 pl-1 pr-1">
              KYC NOT DONE
            </label>
          </p>
          <p className="m-0 p-0" style={{ fontSize: 13 }}>
            CBN policies mandate us to verify your identity to keep your account
            safe and ensure you enjoy all features on {appData.business.name}.{" "}
            <label
              className="inline-block mb-0 pb-0"
              style={{
                color: appData.business.primary_color,
                cursor: "pointer",
                fontWeight: "bold",
              }}
            >
              Get Started ➔
            </label>
          </p>
        </div>
      )} */}
    </div>
  );
};

export default withRouter(DataPrice);
