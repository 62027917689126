import React, { useContext } from "react";
import { useHistory, Link } from "react-router-dom";

import {
    Row,
    Col,
    Card,   
    CardBody,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import {
    FormContext,
} from "../contexts/Contexts";

//Images
import mtn from "../images/mtn.png";
import glo from "../images/glo.png";
import airtel from "../images/airtel.png";
import etisalat from "../images/etisalat.png";
import { formatCurrency } from "../helpers/utils";

import { AppDataContext } from "../contexts/Contexts";


const DataPlan = (props) => {
    const history = useHistory();
    const { formDispatch } = useContext(FormContext);
    
    const onPlanSelect = (dataPlan, network, image) => {
        formDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'dataPlan', value: dataPlan },
            
        });
        
        
        formDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'network', value: network },
            
        });
        formDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'image', value: image },
        });
        
        history.push('/dataPurchase')
    }
    
    return (
        
      <Row className="text-dark" style={{ cursor: 'pointer' }} onClick={() => { onPlanSelect(props.values, props.network, props.image) }}>
        <Col xl="6" md="6" sm="6" xs="6">
        <p className="text-truncate"> {props.values.name}</p>
        </Col>
        <Col xl="3" md="3" sm="3" xs="3">
        <p > ₦{formatCurrency(props.values.wallet_price)}</p>
        </Col>
        <Col xl="3" md="3" sm="3" xs="3">
        <p> ₦{formatCurrency(props.values.atm_price)}</p>
        </Col>
        </Row>
      
        );
    };
    
    const DataPrice = () => {
        const { appData } = useContext(AppDataContext);
        const { formData,formDispatch } = useContext(FormContext);
        
        const onNetworkSelect = (network) => {
            formDispatch({
                type: "SET_FORM_DATA",
                data: { name: 'network', value: network },
                
            });
            
        let el = document.getElementById('data')
        let elScrollOffset = el.getBoundingClientRect().top
        let scrollOffset = window.pageYOffset || document.documentElement.scrollTop
        let padding = 90
        let target = elScrollOffset + scrollOffset - padding
        window.scrollTo({
          top: target,
          behavior: 'smooth'
        })
        window.onscroll = e => {
          let currentScrollOffset = window.pageYOffset || document.documentElement.scrollTop
          // Scroll reach the target
          if (currentScrollOffset*1 === target*1) {              
        el.focus()
            window.onscroll = null // remove listener
           
          }
        }
         
        }
        const MTNDataPlans = appData.data_plans.MTN.map((dataPlan) => (
            <DataPlan image={mtn} key={dataPlan.id} values={dataPlan} network="MTN" />
            ));
            const GLODataPlans = appData.data_plans.GLO.map((dataPlan) => (
                <DataPlan image={glo} key={dataPlan.id} values={dataPlan} network="GLO" />
                ));
                const AIRTELDataPlans = appData.data_plans.AIRTEL.map((dataPlan) => (
                    <DataPlan image={airtel} key={dataPlan.id} values={dataPlan} network="AIRTEL" />
                    ));
                    const ETISALATDataPlans = appData.data_plans.ETISALAT.map((dataPlan) => (
                        <DataPlan image={etisalat} key={dataPlan.id} values={dataPlan} network="ETISALAT" />
                        ));
                        const networkArray = [];
                        appData.settings.data_master_MTN*1 ===0   && networkArray.push( { dataPlans: MTNDataPlans, image: mtn, network:'MTN' })
                        appData.settings.data_master_GLO*1 ===0  && networkArray.push( { dataPlans: GLODataPlans, image: glo , network:'GLO'})
                        appData.settings.data_master_AIRTEL*1 ===0  && networkArray.push( { dataPlans: AIRTELDataPlans, image: airtel , network:'AIRTEL'})
                        appData.settings.data_master_ETISALAT*1 ===0  && networkArray.push( { dataPlans: ETISALATDataPlans, image: etisalat, network:'ETISALAT' })
                        
                        return (
                            <React.Fragment>
                            <Row id='data' className="m-1" style={{scrollMargin:90, scrollSnapMargin:90}}>
                            <Col xl="10" md="10">
                            <Card className="mini-stat bg-white text-primary shadow pl-3 pr-3">
                            <div className="mb-2">
                            <p className="text-uppercase text-left mt-2  " style={{ color: appData.business.primary_color }}><b>Data Purchase</b></p>
                            <hr className="m-0 mb-1 p-0  " style={{ borderColor: appData.business.primary_color }} />
                            <p className="text-uppercase text-left m-0 p-0 small text-dark " >Select A Network</p>
                            </div>
                            <Row className="justify-content-center">
                            {Object.keys(appData.data_plans.MTN).length>0  && formData.network!=='MTN' &&  
                            (
                                     <Col  style={{cursor:'pointer'}} xl="3" xs="3" className="text-center text-dark  m-0 p-0">
                            {appData.master_settings.MTN_data_master*1===1 && <i  style={{ top:'0%',bottom:'50%', position:'absolute'}} className='mdi mdi-alert-circle-outline  mdi-24px text-danger'/>}
                            <img className="shadow p-2 m-2" src={mtn}   height="52" alt="logo"   onClick={() => {
                              appData.master_settings.MTN_data_master*1===0 &&   
                                onNetworkSelect('MTN') }}>
                                    
                                </img>
                            </Col>
                         )
                        }
                        {Object.keys(appData.data_plans.GLO).length>0  && formData.network!=='GLO' &&
                        (
                             <Col  style={{cursor:'pointer'}} xl="3" xs="3" className="text-center text-dark  m-0 p-0">
                         {appData.master_settings.GLO_data_master*1===1 && <i  style={{ top:'0%',bottom:'50%', position:'absolute'}} className='mdi mdi-alert-circle-outline  mdi-24px text-danger'/>}
                        <img className="shadow p-2 m-2" src={glo}  height="52" alt="logo"   onClick={() => { 
                             appData.master_settings.GLO_data_master*1===0 &&   
                            onNetworkSelect('GLO') }}>
                         </img></Col>
                         )}
                        {Object.keys(appData.data_plans.AIRTEL).length>0  && formData.network!=='AIRTEL' &&   
                        (
                             <Col  style={{cursor:'pointer'}} xl="3" xs="3" className="text-center text-dark  m-0 p-0">
                         {appData.master_settings.AIRTEL_data_master*1===1 && <i  style={{ top:'0%',bottom:'50%', position:'absolute'}} className='mdi mdi-alert-circle-outline  mdi-24px text-danger'/>}
                        <img className="shadow p-2 m-2" src={airtel}  height="52" alt="logo"   onClick={() => {
                             appData.master_settings.AIRTEL_data_master*1===0 &&   
                            onNetworkSelect('AIRTEL') }}></img></Col>
                         )}
                        {Object.keys(appData.data_plans.ETISALAT).length>0  && formData.network!=='ETISALAT' &&
                        (
                             <Col  style={{cursor:'pointer'}} xl="3" xs="3" className="text-center text-dark  m-0 p-0">
                        {appData.master_settings.ETISALAT_data_master*1===1 && <i  style={{ top:'0%',bottom:'50%', position:'absolute'}} className='mdi mdi-alert-circle-outline  mdi-24px text-danger'/>}
                         <img className="shadow p-2 m-2" src={etisalat}  height="52" alt="logo"   onClick={() => { 
                            appData.master_settings.ETISALAT_data_master*1===0 &&   
                             onNetworkSelect('ETISALAT') }}></img>
                        </Col>
                         )}
                        </Row>
                        
                        <Row  className="justify-content-center mt-3">
                        {networkArray.map((array) => {
                            return (
                                formData.network===array.network ?
                                (<Col key={array.network} xl="6" md="6" className="text-left text-truncate " style={{ fontSize: '12px' }}>
                               
                                <div className="mb-2 text-center" >
                                
                                <img src={array.image} alt="logo" height="44" style={{cursor:'pointer'}} onClick={() => { onNetworkSelect('') }}></img>
                                </div>
                                <Row className="pb-0 mb-0 ">
                                <Col style={{ color: appData.business.primary_color }} xl="6" md="6" sm="6" xs="6">
                                DATA PLAN
                                </Col>
                                <Col style={{ color: appData.business.primary_color }} xl="3" md="3" sm="3" xs="3">
                                WALLET
                                </Col>
                                <Col style={{ color: appData.business.primary_color }} xl="3" md="3" sm="3" xs="3">
                                ATM
                                </Col>
                                </Row>
                                <hr className="mt-0" />
                                {array.dataPlans}
                               
                                </Col>)
                                :
                                (<div key={array.network}></div>)
                                );
                            })}
                            </Row>
                            </Card>
                            </Col>
                            </Row>
                            </React.Fragment>
                            );
                        };
                        
                        export default withRouter(DataPrice);
                        