import React, { useContext, useEffect } from "react";
import { Container, Row, Col, Card, CardBody, Badge } from "reactstrap";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

import { UserContext, AppDataContext, FormContext } from "../contexts/Contexts";
import BankAccountCard from "./BankAccountCard";
import { Modal } from "reactstrap";

const DashboardMenu1 = (props) => {
  const { user, userDispatch } = useContext(UserContext);
  const { appData } = useContext(AppDataContext);
  const { formData, formDispatch } = useContext(FormContext);
  document.title = appData.business.name;

  useEffect(() => {
    !formData.hasFetechedExtraHTML && fetchExtraHTML();
  }, []);

  const fetchExtraHTML = () => {
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: "hasFetechedExtraHTML", value: true },
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    // myHeaders.append("Authorization", "Bearer " + user.token);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      //redirect: "follow",
    };
    fetch(localStorage.getItem("apiURL") + "html", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        try {
          var data = JSON.parse(response);
          return;
        } catch (e) {}
        formDispatch({
          type: "SET_FORM_DATA",
          data: { name: "extraHTML", value: response },
        });
      });
  };

  return (
    <Container className="font-montserrat ">
      <Modal
        isOpen={
          appData.settings &&
          appData.settings.notification &&
          appData.settings.notification !== user.notification
        }
        centered={true}
        toggle={() => {
          userDispatch({
            type: "UPDATE_USER_NOTIFICATION",
            action: appData.settings.notification,
          });
        }}
      >
        <h1 className="text-center pt-5">
          <i
            className="mdi mdi-email-alert   text-warning"
            style={{ fontSize: 120 }}
          ></i>
        </h1>
        <h6 className="text-center text-dark  mb-3">
          {appData.settings.notification}
        </h6>
        <Row className="justify-content-center">
          <Col xs="4">
            <div
              className="rounded text-center p-2 mb-3"
              onClick={() => {
                userDispatch({
                  type: "UPDATE_USER_NOTIFICATION",
                  action: appData.settings.notification,
                });
              }}
              style={{
                cursor: "pointer",
                backgroundColor: appData.business.primary_color,
              }}
            >
              <p className="mb-0 text-white">GOT IT</p>
            </div>
          </Col>
        </Row>
      </Modal>

      {appData.settings && appData.settings.notification && (
        <marquee>
          <i className="mdi mdi-18px mmdi mdi-alert-octagram mdi-spin  text-warning mdi "></i>
          <b style={{ color: appData.business.primary_color, fontSize: 14 }}>
            {" "}
            {appData.settings.notification}
          </b>
        </marquee>
      )}

      {user.data ? (
        <BankAccountCard />
      ) : (
        <Row>
          <Col md="10">
            <Card
              className="mini-stat  shadow text-white"
              style={{ backgroundColor: appData.business.primary_color }}
            >
              <CardBody>
                <h1 className="mt-0 mb-0">
                  <i className="mdi mdi-cash-100"></i>
                </h1>
                <Link to="signin" className="text-white">
                  <h6>
                    <u>Sign In</u>, fund your wallet {"&"} enjoy more discounts!
                  </h6>
                </Link>
                <Link to="signup" className="text-white">
                  <p className="small mb-0 mt-0 pt-0 pb-0">
                    Don't have an account? <u>Sign Up</u>
                  </p>
                </Link>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}

      <div dangerouslySetInnerHTML={{ __html: formData.extraHTML }} />
      <Row>
        <Col lg="8" md="8" xs="12">
          <Row className="p-1 rounded-top">
            <Col xs="3" lg="2" style={{ padding: "5px" }}>
              <div
                className="text-center rounded pl-0 pr-0 pt-3 pb-3 text-white shadow"
                onClick={() => {
                  props.history.push("/balanceCodes");
                }}
                style={{ height: 80, fontSize: 10, cursor: "pointer" }}
              >
                {" "}
                <i
                  className="mdi mdi-18px  mdi mdi-cellphone-basic  "
                  style={{ color: appData.business.primary_color }}
                ></i>{" "}
                <p className="m-0 p-0 text-dark">Balance Codes</p>
              </div>
            </Col>

            <Col xs="3" lg="2" style={{ padding: "5px" }}>
              <div
                className="text-center rounded pl-0 pr-0 pt-3 pb-3 text-white shadow"
                onClick={() => {
                  props.history.push("/dataPricing");
                }}
                style={{ height: 80, fontSize: 10, cursor: "pointer" }}
              >
                {" "}
                <i
                  className="mdi mdi-18px  mdi-cellphone-iphone  "
                  style={{ color: appData.business.primary_color }}
                ></i>{" "}
                <p className="m-0 p-0 text-dark">Data Purchase</p>
              </div>
            </Col>
            <Col xs="3" lg="2" style={{ padding: "5px" }}>
              <div
                className="text-center rounded pl-0 pr-0 pt-3 pb-3 text-white shadow"
                onClick={() => {
                  props.history.push("/airtimePricing");
                }}
                style={{ height: 80, fontSize: 10, cursor: "pointer" }}
              >
                {" "}
                <i
                  className="mdi mdi-18px  mdi-phone-in-talk "
                  style={{ color: appData.business.primary_color }}
                ></i>{" "}
                <p className="m-0 p-0 text-dark">Airtime Recharge</p>
              </div>
            </Col>
            <Col xs="3" lg="2" style={{ padding: "5px" }}>
              <div
                className="text-center rounded pl-0 pr-0 pt-3 pb-3 text-white shadow"
                onClick={() => {
                  props.history.push("/cablePricing");
                }}
                style={{ height: 80, fontSize: 10, cursor: "pointer" }}
              >
                {" "}
                <i
                  className="mdi mdi-18px  mdi-television-classic  "
                  style={{ color: appData.business.primary_color }}
                ></i>{" "}
                <p className="m-0 p-0 text-dark">Cable TV Payment</p>
              </div>
            </Col>
            <Col xs="3" lg="2" style={{ padding: "5px" }}>
              <div
                className="text-center rounded pl-0 pr-0 pt-3 pb-3 text-white shadow"
                onClick={() => {
                  props.history.push("/electricPricing");
                }}
                style={{ height: 80, fontSize: 10, cursor: "pointer" }}
              >
                {" "}
                <i
                  className="mdi mdi-18px mdi-lightbulb-on "
                  style={{ color: appData.business.primary_color }}
                ></i>{" "}
                <p className="m-0 p-0 text-dark">Electiricity Payment</p>
              </div>
            </Col>

            <Col xs="3" lg="2" style={{ padding: "5px" }}>
              <div
                className="text-center rounded pl-0 pr-0 pt-3 pb-3 text-white shadow"
                onClick={() => {
                  props.history.push("/transferFund");
                }}
                style={{ height: 80, fontSize: 10, cursor: "pointer" }}
              >
                {" "}
                <i
                  className="mdi mdi-18px mdi-bank-transfer "
                  style={{ color: appData.business.primary_color }}
                ></i>{" "}
                <p className="m-0 p-0 text-dark">User to User Transfer</p>
              </div>
            </Col>

            <Col xs="3" lg="2" style={{ padding: "5px" }}>
              <div
                className="text-center rounded pl-0 pr-0 pt-3 pb-3 text-white shadow "
                onClick={() => {
                  props.history.push("/faq");
                }}
                style={{ height: 80, fontSize: 10, cursor: "pointer" }}
              >
                {" "}
                <i
                  className="mdi mdi-18px mdi-comment-question-outline "
                  style={{ color: appData.business.primary_color }}
                ></i>{" "}
                <p className="m-0 p-0 text-dark">Frequently Asked Questions</p>
              </div>
            </Col>
            <Col xs="3" lg="2" style={{ padding: "5px" }}>
              <div
                className="text-center rounded pl-0 pr-0 pt-3 pb-3 text-white shadow"
                onClick={() => {
                  props.history.push("/contact");
                }}
                style={{ height: 80, fontSize: 10, cursor: "pointer" }}
              >
                {" "}
                <i
                  className="mdi mdi-18px  mdi-headset  "
                  style={{ color: appData.business.primary_color }}
                ></i>{" "}
                <p className="m-0 p-0 text-dark">Contact US</p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(DashboardMenu1);
