import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { UserContext, AppDataContext } from "../../contexts/Contexts";
import { getOS } from "../../helpers/utils";

const Topbar = () => {
  const { appData } = useContext(AppDataContext);
  const { user } = useContext(UserContext);
  const width = window.innerWidth;

  const sidebarToggle = () => {
    document.body.classList.toggle("enlarged");
  };
  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <div
        className="topbar"
        style={{
          paddingTop:
            getOS() == "IOS" && window.localStorage.getItem("isMobileApp")
              ? "20px"
              : "0px",
          zIndex: 99999,
        }}
      >
        <div
          className="topbar-left  d-none d-md-block"
          onClick={sidebarToggle}
          style={{ backgroundColor: appData.business.secondary_color }}
        >
          <Link to="#" className="logo">
            <span>
              <img
                src={appData.business.website + "/logo.png"}
                alt="logo"
                height="36"
              />
            </span>
            <i className="bg-white">
              <img
                src={appData.business.website + "/logo.png"}
                alt="logo"
                height="36"
              />
            </i>
          </Link>
        </div>

        <nav className="navbar-custom bg-white">
          <ul className="navbar-right list-inline float-right mb-0">
            <li className="mt-3 mb-3 mr-3">
              {!user.token && (
                <Link to="signin">
                  <b className=" mr-2 ml-2">SIGN IN</b>
                </Link>
              )}
            </li>
          </ul>
          <ul className="list-inline menu-left mb-0  ">
            {appData.business.template * 1 === 0 && (
              <li className="float-left  bg-primary">
                <button
                  onClick={sidebarToggle}
                  className="button-menu-mobile open-left waves-effect bg-white"
                >
                  <i className="mdi mdi-menu"></i>
                </button>
              </li>
            )}

            <li
              className={width < 768 ? "float-left pt-2 " : "float-left pt-3 "}
            >
              <Link to="/">
                <h6 className="text-truncate text-dark ml-2 font-montserrat ">
                  {width < 768 && (
                    <span>
                      <img
                        src={appData.business.website + "/logo.png"}
                        alt="logo"
                        height="32"
                      />
                    </span>
                  )}{" "}
                  {appData.business.name}
                </h6>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </React.Fragment>
  );
};

export default Topbar;
