import React, { useContext, useEffect, useState } from "react";
import wrong from "../../icons/wrong.svg";
import {
  UserContext,
  ModalContext,
  FormContext,
  AppDataContext,
} from "../../contexts/Contexts";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { getOS } from "../../helpers/utils";

function AccountDeleted() {
  const { user, userDispatch } = useContext(UserContext);
  const { appData } = useContext(AppDataContext);
  const [remainingDate, setRemainingDate] = useState(0);

  const history = useHistory();
  const logout = () => {
    localStorage.removeItem("biometricEnrolled");
    userDispatch({ type: "SIGNOUT" });
    history.push("/");
  };

  useEffect(() => {
    let startDate = new Date(user.data.deletion_date);
    let endDate = new Date();
    let difference = endDate.getTime() - startDate.getTime();
    let TotalDiffDays = Math.ceil(difference / (1000 * 3600 * 24));
    setRemainingDate(90 - TotalDiffDays);
  }, []);

  return (
    <div className="text-center  ml-4 mr-4 mt-5  items-center   ">
      <img src={wrong} className=" mt-5" alt="img" />
      <p className=" font-medium text-sm md:text-xl rounded bg-red-600  p-1   text-center text-xs mt-10">
        Your account has been deleted!
      </p>
      <p>Data Retention Time: {remainingDate} days Remaining</p>{" "}
      <p className="font-medium text-sm md:text-xl text-center mt-10 ">
        You no longer have access to your account. You can choose to restore
        your account by contacting our customer care rep.{" "}
        <label className="cursor-pointer text-sm font-bold inline-block text-red ">
          <a
            style={{ color: appData.business.primary_color }}
            className="text-red"
            target="_"
            href={
              getOS() === "WEB"
                ? "https://web.whatsapp.com/send?phone=234" +
                  appData.settings.contact_whatsapp_number +
                  "&text=Hello! I'd like to restore my account"
                : "https://api.whatsapp.com/send?phone=234" +
                  appData.settings.contact_whatsapp_number +
                  "&text=Hello! I'd like to restore my account"
            }
          >
            Contact customer care ➔
          </a>
        </label>
      </p>
      <p
        className=" text-center mt-3"
        style={{ color: appData.business.primary_color, cursor: "pointer" }}
        onClick={() => logout()}
      >
        <b> Go to Menu</b>
      </p>
    </div>
  );
}

export default AccountDeleted;
