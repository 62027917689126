import React, { useContext, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";

//Metismenu for menu
import MetisMenu from "metismenujs";

// Redux Call
import Sidenav from "./SideNav";

import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import {
  UserContext,
  ModalContext,
  FormContext,
  AppDataContext,
} from "../../contexts/Contexts";

import { Row, Col, Card, CardBody } from "reactstrap";
import { getOS } from "../../helpers/utils";

const FootBar = (props) => {
  const { appData } = useContext(AppDataContext);
  const { formData, formDispatch } = useContext(FormContext);

  return (
    <div
      className="bg-white d-md-none font-montserrat  "
      style={{
        width: "100%",
        bottom: "0",
        position: "fixed",
        paddingBottom:
          getOS() == "IOS" && window.localStorage.getItem("isMobileApp")
            ? "20px"
            : "0px",
        zIndex: 99999,
      }}
    >
      <hr className="mt-0 mb-0" />
      <Row className="pt-1">
        <Col xs="3" className="text-center ">
          <Link
            to="/dashboard"
            className={
              window.location.pathname !== "/" &&
              window.location.pathname !== "/dashboard" &&
              "text-dark "
            }
            style={
              window.location.pathname === "/" ||
              window.location.pathname === "/dashboard"
                ? { color: appData.business.primary_color }
                : {}
            }
          >
            <div
              style={{ fontSize: "large" }}
              onClick={() => {
                formDispatch({
                  type: "SET_FORM_DATA",
                  data: { name: "isUserRefreshed", value: false },
                });
              }}
            >
              <i className="ti-home"></i>
            </div>
            <label className=" small">Home</label>
          </Link>
        </Col>

        <Col xs="3" className="text-center ">
          <Link
            to="/transactions"
            className={
              window.location.pathname !== "/transactions" && "text-dark "
            }
            style={
              window.location.pathname === "/transactions"
                ? { color: appData.business.primary_color }
                : {}
            }
          >
            <div style={{ fontSize: "large" }}>
              <i className="mdi mdi-view-sequential"></i>
            </div>
            <label className=" small">Transactions</label>
          </Link>
        </Col>

        <Col xs="3" className="text-center ">
          <Link
            to="/beneficiaries"
            className={
              window.location.pathname !== "/beneficiaries" && "text-dark "
            }
            style={
              window.location.pathname === "/beneficiaries"
                ? { color: appData.business.primary_color }
                : {}
            }
          >
            <div style={{ fontSize: "large" }}>
              <i className="mdi mdi-human-male-female"></i>
            </div>
            <label className=" small">Beneficiary</label>
          </Link>
        </Col>

        <Col xs="3" className="text-center ">
          <Link
            to="/profile"
            className={window.location.pathname !== "/profile" && "text-dark "}
            style={
              window.location.pathname === "/profile"
                ? { color: appData.business.primary_color }
                : {}
            }
          >
            <div style={{ fontSize: "large" }}>
              <i className="mdi mdi-account-circle"></i>
            </div>
            <label className=" small">Profile</label>
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(FootBar);
