import React, { useContext, useEffect, useState } from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { Link, withRouter, useHistory } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import Api from "../../helpers/Api";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  UserContext,
  ModalContext,
  FormContext,
  AppDataContext,
} from "../../contexts/Contexts";
import ResponseModal from "../../components/ResponseModal";
import PasswordInput from "../../components/PasswordInput";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { formData, formDispatch } = useContext(FormContext);
  const { user, userDispatch } = useContext(UserContext);
  const { appData } = useContext(AppDataContext);
  document.title = "Payments-" + appData.business.name;
  const history = useHistory();
  const formOnChange = (e) => {
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  useEffect(() => {
    if (!formData.ref) {
      formDispatch({
        type: "SET_FORM_DATA",
        data: { name: "ref", value: Math.random().toString(36).slice(2) },
      });

      formDispatch({
        type: "SET_FORM_DATA",
        data: { name: "firstname", value: user.data.firstname },
      });

      formDispatch({
        type: "SET_FORM_DATA",
        data: { name: "othername", value: user.data.othername },
      });

      formDispatch({
        type: "SET_FORM_DATA",
        data: { name: "lastname", value: user.data.lastname },
      });
      formDispatch({
        type: "SET_FORM_DATA",
        data: { name: "email", value: user.data.email },
      });
      formDispatch({
        type: "SET_FORM_DATA",
        data: { name: "phone_number", value: user.data.phone_number },
      });

      window.scrollTo(0, 0);
    }
  }, []);

  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: "modalOpen", value: false },
    });
    fetchTransactions();
  };
  const refreshUser = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + user.token);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    fetch(
      localStorage
        .getItem("apiURL")
        .substr(0, localStorage.getItem("apiURL").length - 1),
      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        if (data.status === "success") {
          userDispatch({ type: "UPDATE_USER", action: data });
        } else if (
          data.message === "Token Expired" ||
          data.message === "User Not Found"
        ) {
        }
      });
  };

  const deleteAccount = (e) => {
    e.preventDefault();
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "deleting account...", isLoading: true },
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + user.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("password", String(formData.password));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      //redirect: "follow",
    };
    fetch(localStorage.getItem("apiURL") + "delete_account", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          refreshUser();
        } else if (
          data.message === "Token Expired" ||
          data.message === "User Not Found"
        ) {
          props.history.push("/signout");
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
          formDispatch({
            type: "SET_FORM_DATA",
            data: { name: "ref", value: Math.random().toString(36).slice(2) },
          });
        } else {
          formDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
          formDispatch({
            type: "SET_FORM_DATA",
            data: { name: "ref", value: Math.random().toString(36).slice(2) },
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  const fetchTransactions = () => {
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Saving Changes...", isLoading: true },
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + user.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("firstname", String(formData.firstname));
    urlencoded.append("othername", String(formData.othername));
    urlencoded.append("lastname", String(formData.lastname));
    urlencoded.append("email", String(formData.email));
    urlencoded.append("phone_number", String(formData.phone_number));
    formData.password &&
      urlencoded.append("password", String(formData.password));
    formData.cpassword &&
      urlencoded.append("cpassword", String(formData.cpassword));
    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      //redirect: "follow",
      body: urlencoded,
    };
    fetch(
      localStorage
        .getItem("apiURL")
        .substr(0, localStorage.getItem("apiURL").length - 1),
      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          formDispatch({
            type: "SET_ERROR",
            data: "",
          });
          formDispatch({
            type: "SET_FORM_DATA",
            data: {
              name: "responseModal",
              value: { isOpen: true, text: data.message },
            },
          });
        } else if (
          data.message === "Token Expired" ||
          data.message === "User Not Found"
        ) {
          props.history.push("/signout");
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
          formDispatch({
            type: "SET_FORM_DATA",
            data: { name: "modalOpen", value: true },
          });
        } else {
          formDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
          formDispatch({
            type: "SET_FORM_DATA",
            data: { name: "modalOpen", value: true },
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  return (
    <React.Fragment>
      <Container fluid style={{ minHeight: "100vh" }}>
        <br />
        <ResponseModal isOpen={true} />

        <Row>
          <Col xl="10" ml="10" sm="12">
            <Card className="mini-stat text-dark shadow">
              <CardBody>
                <div className="mb-2">
                  <p
                    className="text-uppercase text-left mb-0 "
                    style={{ color: appData.business.primary_color }}
                  >
                    <b>Profile</b>
                  </p>
                  <hr style={{ borderColor: appData.business.primary_color }} />
                </div>
                <div className=" mt-8 text-center justify-items-center">
                  {user.data && user.data.kyc_verified_at && (
                    <span class="inline-flex items-center justify-center  text-sm text-primary bg-white rounded-full">
                      <svg
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        style={{ width: "36px" }}
                      >
                        <path
                          fill="currentColor"
                          d="m18.774 8.245-.892-.893a1.5 1.5 0 0 1-.437-1.052V5.036a2.484 2.484 0 0 0-2.48-2.48H13.7a1.5 1.5 0 0 1-1.052-.438l-.893-.892a2.484 2.484 0 0 0-3.51 0l-.893.892a1.5 1.5 0 0 1-1.052.437H5.036a2.484 2.484 0 0 0-2.48 2.481V6.3a1.5 1.5 0 0 1-.438 1.052l-.892.893a2.484 2.484 0 0 0 0 3.51l.892.893a1.5 1.5 0 0 1 .437 1.052v1.264a2.484 2.484 0 0 0 2.481 2.481H6.3a1.5 1.5 0 0 1 1.052.437l.893.892a2.484 2.484 0 0 0 3.51 0l.893-.892a1.5 1.5 0 0 1 1.052-.437h1.264a2.484 2.484 0 0 0 2.481-2.48V13.7a1.5 1.5 0 0 1 .437-1.052l.892-.893a2.484 2.484 0 0 0 0-3.51Z"
                        />
                        <path
                          fill="#fff"
                          d="M8 13a1 1 0 0 1-.707-.293l-2-2a1 1 0 1 1 1.414-1.414l1.42 1.42 5.318-3.545a1 1 0 0 1 1.11 1.664l-6 4A1 1 0 0 1 8 13Z"
                        />
                      </svg>
                    </span>
                  )}
                </div>
                <div className="mb-2 mt-2  ">
                  <Row style={{ fontSize: 12 }}>
                    <Col xs="5">
                      <b>FIRSTNAME</b>
                    </Col>
                    <Col xs="7">
                      <p className="m-0 p-0">{user.data.firstname}</p>
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-3" style={{ fontSize: 12 }}>
                    <Col xs="5">
                      <b>OTHERNAME</b>
                    </Col>
                    <Col xs="7">
                      <p className="m-0 p-0">{user.data.othername}</p>
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-3" style={{ fontSize: 12 }}>
                    <Col xs="5">
                      <b>LASTNAME</b>
                    </Col>
                    <Col xs="7">
                      <p className="m-0 p-0">{user.data.lastname}</p>
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-3" style={{ fontSize: 12 }}>
                    <Col xs="5">
                      <b>EMAIL</b>
                    </Col>
                    <Col xs="7">
                      <p className="m-0 p-0">{user.data.email}</p>
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-3" style={{ fontSize: 12 }}>
                    <Col xs="5">
                      <b>PHONE NUMBER</b>
                    </Col>
                    <Col xs="7">
                      <p className="m-0 p-0">{user.data.phone_number}</p>
                    </Col>
                  </Row>
                </div>

                <hr />
                <div className="text-center">
                  {" "}
                  <p
                    onClick={() => {
                      formDispatch({
                        type: "SET_FORM_DATA",
                        data: { name: "modalOpen", value: true },
                      });
                    }}
                    style={{ cursor: "pointer" }}
                    className="text-uppercase   mb-2 text-primary "
                  >
                    <b>Edit Account</b>
                  </p>
                  <Link to="signout">
                    <p className="text-uppercase   text-danger">
                      <b>Sign Out</b>
                    </p>
                  </Link>
                </div>
              </CardBody>
            </Card>
            <div className="text-center">
              <Button
                type="submit"
                onClick={() => {
                  formDispatch({
                    type: "SET_FORM_DATA",
                    data: { name: "deleteForm", value: true },
                  });
                }}
                style={{
                  backgroundColor: "red",
                }}
                className="w-md waves-effect waves-light"
              >
                Delete Account
              </Button>
            </div>
            {formData.deleteForm && (
              <Modal isOpen={true} centered={true}>
                <ModalBody>
                  <div className="mb-2 text-dark">
                    <div className="text-right">
                      <Button
                        onClick={() => {
                          formDispatch({
                            type: "SET_FORM_DATA",
                            data: { name: "deleteForm", value: false },
                          });
                        }}
                      >
                        X{" "}
                      </Button>
                    </div>
                    <div className="account-card-content">
                      <div className="p-3">
                        {formData.error && (
                          <Alert color="danger">{formData.error}</Alert>
                        )}
                        <p
                          className="text-sm  lg:text-lg font-weight-bold"
                          style={{
                            color: "red",
                          }}
                        >
                          Deleting your account will remove all your information
                          from our database. It will also cause you to lose
                          funds that are not utilized! Kindly spend all the
                          funds in your wallet as the wallet will be deleted!
                        </p>
                        <p className="text-sm mt-2 lg:text-lg font-weight-bold">
                          Due to CBN policies, your account will be deleted in
                          90 days as we need to retain your transactions for 90
                          days before deletion.
                        </p>
                        <div
                          class="p-2 rounded-lg mb-2 text-black"
                          style={{
                            backgroundColor: "#FFCCCB",
                          }}
                        >
                          You can restore your account during the 90 days
                          retention period by reaching out to our Customer Care
                        </div>

                        <>
                          <AvForm
                            className="form-horizontal m-t-30"
                            autoComplete="off"
                            onValidSubmit={deleteAccount}
                          >
                            <div
                              className="overflow-y-auto"
                              style={{ maxHeight: "60vh" }}
                            >
                              <AvField
                                name="password"
                                label="Enter Your Password"
                                value={formData.password}
                                onChange={(e) => {
                                  formOnChange(e);
                                }}
                                placeholder="Enter Your Password"
                                type="password"
                                required
                              />
                            </div>
                            <div className="text-center">
                              <Button
                                type="submit"
                                className="w-md waves-effect waves-light"
                                style={{
                                  backgroundColor: "red",
                                }}
                              >
                                Delete Account
                              </Button>
                            </div>
                          </AvForm>
                        </>
                      </div>
                    </div>
                  </div>
                </ModalBody>
              </Modal>
            )}
          </Col>
        </Row>
      </Container>

      {formData.modalOpen && (
        <Modal isOpen={true} centered={true} className="mr-3 ml-3 mt-4 mb-4">
          <ModalBody>
            <div className="mb-2 text-dark">
              <button
                type="button"
                className="close"
                onClick={() => {
                  formDispatch({
                    type: "SET_FORM_DATA",
                    data: { name: "modalOpen", value: false },
                  });
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p
                className="text-uppercase text-left mb-0 "
                style={{ color: appData.business.primary_color }}
              >
                <b>Edit Profile</b>
              </p>
              <hr style={{ borderColor: appData.business.primary_color }} />
              <div className="account-card-content">
                {formData.error && (
                  <Alert color="danger">{formData.error}</Alert>
                )}
                <AvForm
                  className="form-horizontal m-t-30"
                  onValidSubmit={handleSubmit}
                >
                  <AvField
                    name="email"
                    label="Email"
                    value={formData.email}
                    onChange={(e) => {
                      formOnChange(e);
                    }}
                    placeholder="Enter Email"
                    type="email"
                    required
                  />

                  {!user.data.kyc_verified_at && (
                    <>
                      <AvField
                        name="phone_number"
                        label="Phone Number"
                        value={formData.phone_number}
                        onChange={(e) => {
                          formOnChange(e);
                        }}
                        placeholder="Enter your phone number"
                        type="number"
                        required
                        validate={{
                          pattern: {
                            value: "^[0-9]+$",
                            errorMessage: "Enter valid phone number",
                          },
                        }}
                      />

                      <AvField
                        name="firstname"
                        label="Firstname"
                        value={formData.firstname}
                        onChange={(e) => {
                          formOnChange(e);
                        }}
                        placeholder="Enter your firstname"
                        type="text"
                        required
                      />

                      <AvField
                        name="othername"
                        label="Othername"
                        value={formData.othername}
                        onChange={(e) => {
                          formOnChange(e);
                        }}
                        placeholder="Enter your othername"
                        type="text"
                      />

                      <AvField
                        name="lastname"
                        label="Lastname"
                        value={formData.lastname}
                        onChange={(e) => {
                          formOnChange(e);
                        }}
                        placeholder="Enter your lastname"
                        type="text"
                        required
                      />
                    </>
                  )}
                  <AvField
                    name="cpassword"
                    label="Password"
                    value={formData.cpassword}
                    onChange={(e) => {
                      formOnChange(e);
                    }}
                    placeholder="Enter password"
                    type="password"
                  />

                  {!formData.changePassword && (
                    <p>
                      <button
                        className="text-uppercase btn text-left mb-0 mt-2 small text-white rounded-lg p-0 pl-1 pr-1"
                        style={{
                          background: appData.business.secondary_color,
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          formDispatch({
                            type: "SET_FORM_DATA",
                            data: { name: "changePassword", value: true },
                          });
                        }}
                      >
                        <b>Change Password</b>
                      </button>
                    </p>
                  )}
                  {formData.changePassword && (
                    <React.Fragment>
                      {" "}
                      <AvField
                        name="password"
                        label="New Password"
                        value={formData.password}
                        onChange={(e) => {
                          formOnChange(e);
                        }}
                        placeholder="Enter new password"
                        type="password"
                      />
                      <AvField
                        name="password1"
                        type="password"
                        placeholder="Re-type new password"
                        errorMessage="Passwords do not match"
                        validate={{
                          required: { value: true },
                          match: { value: "password" },
                        }}
                      />
                    </React.Fragment>
                  )}

                  <Button
                    style={{ backgroundColor: appData.business.primary_color }}
                    className="w-md waves-effect waves-light"
                    type="submit"
                  >
                    Save Changes
                  </Button>
                </AvForm>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default withRouter(Payments);
